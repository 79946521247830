import gql from "graphql-tag";

// ===============
// Queries
// ===============
export const GET_ALL_PLAN_DETAILS = gql`
  query retrieveChargebeePlan(
    $planPeriodUnit: String!
    $subType: String!
    $currencyCode: String!
    $planPeriod: Int!
  ) {
    retrieveChargebeePlan(
      planPeriodUnit: $planPeriodUnit
      subType: $subType
      currencyCode: $currencyCode
      planPeriod: $planPeriod
    ) {
      errorCode
      message
      planDetails
      subscriptionStatus
    }
  }
`;

export const NEW_SUBSCRIPTION_HOSTED_PAGE_DETAILS = gql`
  query getNewSubscriptionHostedPageDetails(
    $planId: String!
    $planEmployeeCount: Int!
  ) {
    getNewSubscriptionHostedPageDetails(
      planId: $planId
      planEmployeeCount: $planEmployeeCount
    ) {
      errorCode
      message
      hostedPageDetails
    }
  }
`;

export const CHANGE_SUBSCRIPTION_HOSTED_PAGE_DETAILS = gql`
  query getChangeSubscriptionHostedPageDetails(
    $subscriptionId: String!
    $planId: String!
    $planEmployeeCount: Int!
    $source: String!
  ) {
    getChangeSubscriptionHostedPageDetails(
      subscriptionId: $subscriptionId
      planId: $planId
      planEmployeeCount: $planEmployeeCount
      source: $source
    ) {
      errorCode
      message
      hostedPageDetails
    }
  }
`;

export const GET_SUBSCRIPTION_DETAILS = gql`
  query retrieveSubscriptionDetails {
    retrieveSubscriptionDetails {
      errorCode
      message
      subscriptionDetails
      subscriptionStatus
    }
  }
`;

export const GET_ORGANIZATION_SUBSCRIPTION_DETAILS = gql`
  query getOrganizationBillingDetails {
    getOrganizationBillingDetails {
      errorCode
      message
      autoBilling
      manualBillingDetails
      autoBillingDetails
    }
  }
`;

export const CHANGE_CARD_DETAILS = gql`
  query getManagePaymentSourcesHostedPageDetails {
    getManagePaymentSourcesHostedPageDetails {
      errorCode
      message
      hostedPageDetails
    }
  }
`;

export const GET_MEMBERS_AND_SUBSCRIBED_COUNT = gql`
  query getMembersAndSubscribedCount($source: String!) {
    getMembersAndSubscribedCount(source: $source) {
      errorCode
      message
      activeMembers
      inactiveMembers
      invitedMembers
      subscribedQuantity
    }
  }
`;

export const GET_STATES = gql`
  query listStates($countryCode: String!) {
    listStates(countryCode: $countryCode) {
      errorCode
      message
      listStateDetails {
        stateCode
        stateName
      }
    }
  }
`;

export const RETRIEVE_COUPON_DETAILS = gql`
  query retrieveCouponDetails {
    retrieveCouponDetails {
      errorCode
      message
      couponDetails {
        Coupon_Code
        Discount_Percentage
      }
    }
  }
`;

export const GET_AUTHENTICATION_METHODS = gql`
  query getAuthenticationMethods {
    getAuthenticationMethods {
      errorCode
      message
      authenticationMethods {
        Authentication_Method_Id
        Authentication_Method
        Description
      }
    }
  }
`;

// ===============
// Mutation
// ===============
export const CREATE_DEFAULT_SUBSCRIPTION = gql`
  mutation createDefaultChargebeeSubscription {
    createDefaultChargebeeSubscription {
      errorCode
      message
    }
  }
`;

export const UPDATE_SUBSCRIPTION_STATUS = gql`
  mutation updateSubscriptionStatus {
    updateSubscriptionStatus {
      errorCode
      message
      subscriptionStatus
    }
  }
`;

export const ADD_MORE_SUBSCRIPTION_QUANTITY = gql`
  query addSubscriptionQuantity($planEmployeeCount: Int!) {
    addSubscriptionQuantity(planEmployeeCount: $planEmployeeCount) {
      errorCode
      message
      hostedPageDetails
    }
  }
`;

export const UPDATE_AUTO_BILLING_TAX_SETTINGS = gql`
  mutation updateTaxSettings(
    $businessName: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
    $addressLine1: String!
    $addressLine2: String
    $city: String!
    $pincode: String!
    $state: String!
    $countryCode: String!
    $stateCode: String!
    $taxRegistrationNumber: String
  ) {
    updateTaxSettings(
      businessName: $businessName
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      city: $city
      pincode: $pincode
      state: $state
      countryCode: $countryCode
      stateCode: $stateCode
      taxRegistrationNumber: $taxRegistrationNumber
    ) {
      errorCode
      message
    }
  }
`;
