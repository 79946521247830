<template>
  <v-pagination
    v-if="originalData.length > 0"
    v-model="page"
    :length="paginationLength"
    :total-visible="totalVisiblePages"
    circle
  ></v-pagination>
</template>
<script>
export default {
  name: "AppPagination",
  props: {
    totalVisiblePages: {
      type: Number,
      default: 0,
    },
    originalData: {
      type: Array,
      required: true,
    },
    limitPerPage: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      lastIndex: 0,
      page: 1,
      currentPage: 1,
      previousPage: 1,
      paginationLength: 0,
      displayData: [],
    };
  },

  watch: {
    page(val) {
      // change pagination
      this.formDisplayData(val);
    },
    originalData() {
      this.resetPagination();
      // change pagination
      this.formDisplayData(1);
    },
  },
  mounted() {
    // check if the data exits and do pagination
    if (this.originalData.length > 0) {
      this.resetPagination();
      // change pagination
      this.formDisplayData(1);
    }
  },
  methods: {
    resetPagination() {
      this.lastIndex = 0;
      this.page = 1;
      this.currentPage = 1;
      this.previousPage = 1;
      this.paginationLength = 0;
    },
    // pagination
    formDisplayData(currentPage) {
      this.paginationLength = Math.ceil(
        this.originalData.length / this.limitPerPage
      );

      var indexToAdd = 0; // limit up to which the current page should show

      // check if the previous page is greater than current page
      if (this.previousPage < currentPage) {
        // add the limit to the previous limit
        indexToAdd = currentPage - this.previousPage;
        this.lastIndex = this.lastIndex + this.limitPerPage * indexToAdd;
      } else if (this.previousPage > currentPage) {
        // remove the limit to the previous limit
        var last_index = this.lastIndex;
        indexToAdd = this.previousPage - currentPage;
        this.lastIndex = last_index - this.limitPerPage * indexToAdd;
      } else {
        this.lastIndex = last_index;
      }

      // check if the action is search
      var originalData = this.originalData;

      var nextLimit = parseInt(this.lastIndex) + parseInt(this.limitPerPage);
      this.displayData = [];
      // loop through the data to show based on pagination
      for (var i = this.lastIndex; i < originalData.length; i++) {
        if (i < nextLimit) {
          this.displayData.push(originalData[i]);
        }
      }

      this.previousPage = currentPage;
      this.currentPage = currentPage;

      this.$emit("apply-pagination", this.displayData);
    },
  },
};
</script>
