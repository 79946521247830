<template>
  <v-dialog
    v-model="openWarningModal"
    width="550px"
    @click:outside="$emit('close-warning-modal')"
  >
    <v-card class="card-radius" :min-height="windowWidth > 700 ? 350 : ''">
      <div class="d-flex justify-end">
        <v-icon
          color="secondary"
          class="pr-4 pt-4 font-weight-bold"
          @click="$emit('close-warning-modal')"
          >close</v-icon
        >
      </div>
      <v-card-title v-if="imageName || iconName">
        <v-row v-if="!imageName && !showIcon" class="d-flex justify-center">
          <v-avatar :color="avatarColor" size="90">
            <v-icon
              color="white"
              style="font-size: 40px"
              class="font-weight-bold"
              >{{ iconName }}</v-icon
            >
          </v-avatar>
        </v-row>
        <v-row v-else-if="showIcon" justify="center">
          <v-icon size="100" :color="avatarColor">{{ iconName }}</v-icon>
        </v-row>
        <v-row v-else class="justify-center">
          <img width="120px" height="100%" :src="getImageUrl" alt="delete" />
        </v-row>
      </v-card-title>
      <v-card-text class="justify-center">
        <v-row
          v-if="confirmationContent"
          class="justify-center font-weight-bold modal-heading primary--text text-center"
        >
          {{ confirmationContent }}
          <br />
        </v-row>
        <div
          v-if="confirmationText"
          class="justify-center body-1 primary--text text-center px-6 pt-2"
        >
          {{ confirmationText }}
          <br />
        </div>
        <!-- extra message to display contents in popup -->
        <div
          v-if="confirmationSubContent"
          class="justify-center body-1 grey--text text-center px-6 py-2"
        >
          {{ confirmationSubContent }}
        </div>
        <div v-if="notes" class="grey--text text-center px-6 py-1">
          {{ notes }}
        </div>
        <slot name="warningModalContent"></slot>
        <v-form v-show="showInput" ref="commentForm" v-model="valid">
          <v-row class="body-1 mt-4">
            <v-text-field
              v-model="inputFieldContent"
              label="Reason for return"
              outlined
              :rules="commentRules"
              :maxlength="600"
            ></v-text-field>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions v-if="showActions" class="justify-center pb-10">
        <v-btn
          v-if="closeButtonText"
          elevation="2"
          @click="$emit('close-warning-modal')"
        >
          {{ closeButtonText }}
        </v-btn>
        <v-btn
          v-if="acceptButtonText"
          color="secondary"
          class="ma-2 white--text"
          :disabled="showInput && !valid"
          @click="submitClick()"
        >
          {{ acceptButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { replaceSentanceWithoutExtraSapceAndNewline } from "@/helper";

export default {
  name: "AppWarningModel",
  props: {
    openDeleteConfirmation: {
      type: Boolean,
      required: true,
    },
    confirmationContent: {
      type: String,
      default: "Are you sure to delete the selected record(s)?",
    },
    imageName: {
      type: String,
      default: "",
    },
    avatarColor: {
      type: String,
      default: "red lighten-1",
    },
    iconName: {
      type: String,
      default: "",
    },
    showInput: {
      type: Boolean,
      default: false,
    },
    confirmationSubContent: {
      type: String,
      default: "",
    },
    notes: {
      type: String,
      default: "",
    },
    confirmationText: {
      type: String,
      default: "",
    },
    showActions: {
      type: Boolean,
      default: true,
    },
    closeButtonText: {
      type: String,
      default: "No",
    },
    acceptButtonText: {
      type: String,
      default: "Yes",
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isMounted: false,
      inputFieldContent: "",
      commentRules: [
        (v) => v !== "" || "Comment field is required",
        (v) =>
          (v && v.length >= 5) ||
          "Comment length should greater than or equal to five characters.",
        (v) =>
          /^[\w\.\,\#\+\&\/\-\(\)\:\'\ ]*$/.test(v) ||
          "Only alphanumeric, spaces and symbols(+ , / . # & : () ' - ) are allowed.",
      ],
      valid: false,
      openWarningModal: false,
    };
  },
  computed: {
    // current window size
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    //to know whether browser support webp or not
    isBrowserSupportWebp() {
      return this.$store.state.isWebpSupport;
    },
    //Load webp if browser support webp image format or load png
    getImageUrl() {
      if (this.isBrowserSupportWebp)
        return require("@/assets/images/" + this.imageName + ".webp");
      else return require("@/assets/images/" + this.imageName + ".png");
    },
  },
  mounted() {
    this.openWarningModal = this.openDeleteConfirmation;
  },
  methods: {
    // function called when click yes
    submitClick() {
      // replace new line, multi space in the comment text-area, to single space using regex
      let comment = replaceSentanceWithoutExtraSapceAndNewline(
        this.inputFieldContent
      );
      if (this.showInput) {
        if (this.$refs.commentForm.validate()) {
          this.$emit("accept-modal", comment);
        }
      } else {
        this.$emit("accept-modal", comment);
      }
    },
    // function to reset input values
    fnResetInputValues() {
      if (this.$refs.commentForm) {
        this.$refs.commentForm.reset();
      }
      this.inputFieldContent = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.v-text-field--outlined fieldset {
  top: -25px !important;
}
.vqb-group-heading {
  color: var(--v-secondary-base) !important;
  font-size: 18px !important;
}
.modal-heading {
  font-size: 1.3em;
}
</style>
