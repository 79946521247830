<template>
  <div
    class="d-flex flex-column justify-center align-center fetch-error-screen-height"
  >
    <v-row class="set-layout">
      <v-col cols="12" class="d-flex flex-column justify-center align-center">
        <div class="d-flex justify-center mb-5">
          <img
            :width="isSmallImage ? '50%' : '100%'"
            height="auto"
            :src="getImgUrl"
            alt="No Records"
          />
        </div>
        <div
          v-if="mainTitle"
          class="main-content-title mb-5"
          :class="mainTitleColor + '--text'"
        >
          {{ mainTitle }}
        </div>
        <span
          class="mb-5 sub-content"
          :class="mainTitle ? '' : 'font-weight-bold'"
          >{{ content }}</span
        >
        <slot name="contentSlot"></slot>
        <div v-if="buttonText" class="mb-4">
          <v-btn
            v-if="!disableButton"
            color="primary"
            :dense="isSmallImage"
            :small="isSmallImage"
            @click="disableButton ? {} : $emit('button-click')"
          >
            <v-icon v-if="icon" class="add-icon mr-1">
              {{ iconName }}
            </v-icon>
            {{ buttonText }}
          </v-btn>
          <v-btn
            v-else
            v-tooltip="{
              content: tooltipData,
              trigger: 'hover',
              placement: 'top',
            }"
            color="grey lighten-3"
            class="tab-action-btn-disabled"
            :dense="isSmallImage"
            :small="isSmallImage"
          >
            <v-icon v-if="icon" class="add-icon mr-1">
              {{ iconName }}
            </v-icon>
            {{ buttonText }}
          </v-btn>
          <slot name="nextToButton"></slot>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "AppFetchErrorScreen",
  props: {
    buttonText: {
      type: String,
      default: "",
    },
    mainTitle: {
      type: String,
      default: "",
    },
    mainTitleColor: {
      type: String,
      default: "primary",
    },
    content: {
      type: String,
      default: "",
    },
    imageName: {
      type: String,
      required: true,
    },
    icon: {
      type: Boolean,
      default: true,
    },
    iconName: {
      type: String,
      default: "refresh",
    },
    disableButton: {
      type: Boolean,
      default: false,
    },
    tooltipData: {
      type: String,
      default: "",
    },
    isSmallImage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    //to know whether browser support webp or not
    isBrowserSupportWebp() {
      return this.$store.state.isWebpSupport;
    },
    //render image based on webp support
    getImgUrl() {
      if (this.isBrowserSupportWebp)
        return require("@/assets/images/" + this.imageName + ".webp");
      else return require("@/assets/images/" + this.imageName + ".png");
    },
  },
};
</script>

<style lang="scss" scoped>
.add-icon {
  font-weight: bold !important;
  font-size: 1.2em !important;
  color: white !important;
}
.set-layout {
  margin-top: 7vh !important;
  width: 100%;
}
.main-content-title {
  text-align: center;
  font-weight: bold;
  font-size: 2em;
}
.sub-content {
  font-size: 1.5em;
  width: 85%;
  text-align: center;
  color: black;
}
.fetch-error-screen-height {
  min-height: 350px;
}

.tab-action-btn-disabled {
  cursor: not-allowed !important;
  color: var(--v-grey-lighten1) !important;
}

@media screen and (max-width: 768px) {
  .sub-content {
    font-size: 1em;
    width: 100%;
    text-align: center;
  }
}
</style>
