import gql from "graphql-tag";

// ===============
// Queries
// ===============
export const LIST_CUSTOM_GROUP = gql`
  query listCustomGroups($isDropDownCall: Int!, $employeeId: Int!) {
    listCustomGroups(isDropDownCall: $isDropDownCall, employeeId: $employeeId) {
      errorCode
      message
      listCustomGroups
    }
  }
`;

export const RETRIEVE_CUSTOM_GROUP = gql`
  query retrieveCustomGroup(
    $groupId: Int!
    $action: String!
    $designation: [Int]
    $department: [Int]
    $employeeType: [Int]
    $workSchedule: [Int]
    $location: [Int]
    $serviceProvider: [Int]
    $state: [Int]
    $employeeId: Int!
    $employeeStatus: [String]
  ) {
    retrieveCustomGroup(
      groupId: $groupId
      action: $action
      designation: $designation
      department: $department
      employeeType: $employeeType
      workSchedule: $workSchedule
      location: $location
      serviceProvider: $serviceProvider
      state: $state
      employeeId: $employeeId
      employeeStatus: $employeeStatus
    ) {
      errorCode
      message
      customGroupData
    }
  }
`;

export const LIST_CUSTOM_GROUP_ENABLED_FORMS = gql`
  query listModuleAndForms {
    listModuleAndForms {
      errorCode
      message
      modulesAndForms {
        Module_Id
        Module_Name
        Forms {
          Form_Id
          Form_Name
        }
      }
    }
  }
`;

export const LIST_CUSTOM_GROUP_FILTERS = gql`
  query listCustomGroupFilters {
    listCustomGroupFilters {
      errorCode
      message
      filterData {
        Filter_Id
        Filter_Name
        Display_Name
        Filter_Table_Name
      }
    }
  }
`;

export const LIST_GROUP_EMPLOYEES_BASED_ON_TYPE = gql`
  query listTypesOfEmployees(
    $type: String!
    $designation: [Int]
    $department: [Int]
    $employeeType: [Int]
    $workSchedule: [Int]
    $location: [Int]
    $groupId: Int!
    $serviceProvider: [Int]
    $state: [Int]
  ) {
    listTypesOfEmployees(
      type: $type
      designation: $designation
      department: $department
      employeeType: $employeeType
      workSchedule: $workSchedule
      location: $location
      groupId: $groupId
      serviceProvider: $serviceProvider
      state: $state
    ) {
      errorCode
      message
      employees
    }
  }
`;
// ===============
// Mutations
// ===============
export const ADD_CUSTOM_GROUP = gql`
  mutation CommentQuery(
    $groupName: String!
    $forms: [Int!]
    $notes: String!
    $jsonQuery: String
    $employeeId: Int!
    $additionalInclusionArray: [Int]
  ) {
    createCustomGroup(
      groupName: $groupName
      forms: $forms
      notes: $notes
      jsonQuery: $jsonQuery
      employeeId: $employeeId
      additionalInclusionArray: $additionalInclusionArray
    ) {
      errorCode
      message
      validationError
      data
    }
  }
`;

export const UPDATE_CUSTOM_GROUP = gql`
  mutation CommentQuery(
    $groupId: Int!
    $groupName: String!
    $forms: [Int!]
    $notes: String
    $jsonQuery: String
    $isEdit: Int!
    $employeeId: Int!
  ) {
    editCustomGroup(
      groupId: $groupId
      groupName: $groupName
      forms: $forms
      notes: $notes
      jsonQuery: $jsonQuery
      isEdit: $isEdit
      employeeId: $employeeId
    ) {
      errorCode
      message
      validationError
    }
  }
`;

export const DELETE_CUSTOM_GROUP = gql`
  mutation CommentQuery($groupId: Int!, $logInEmpId: Int!) {
    deleteCustomGroup(groupId: $groupId, logInEmpId: $logInEmpId) {
      errorCode
      message
    }
  }
`;

export const INCLUDE_EXCLUDE_EMPLOYEES = gql`
  mutation CommentQuery(
    $typeOfAction: String!
    $groupId: Int!
    $employeeId: [Int!]
    $logInEmpId: Int!
  ) {
    includeExcludeEmployees(
      typeOfAction: $typeOfAction
      groupId: $groupId
      employeeId: $employeeId
      logInEmpId: $logInEmpId
    ) {
      errorCode
      message
      validationError
    }
  }
`;
