import gql from "graphql-tag";
// ===============
// Queries
// ===============
export const LIST_MEMBERS_ADMINS_INVITES = gql`
  query listTeamAdminAndInvitedMembers(
    $source: String!
    $designationId: [Int]
    $departmentId: [Int]
    $empTypeId: [Int]
    $workScheduleId: [Int]
    $locationId: [Int]
  ) {
    listTeamAdminAndInvitedMembers(
      source: $source
      designationId: $designationId
      departmentId: $departmentId
      empTypeId: $empTypeId
      workScheduleId: $workScheduleId
      locationId: $locationId
    ) {
      errorCode
      message
      memberDetails {
        memberId
        employeeId
        assetId
        firstName
        lastName
        employeeName
        employeeEmail
        designation
        department
        photoPath
        memberStatus
        macAddress
        serialNumber
        operatingSystem
        agentVersion
        computerName
        invitedDate
        memberRole
        userEmployeeId
        managerUserDefinedEmpId
        managerName
        managerId
        managerEmail
      }
    }
  }
`;

export const LIST_HRMS_EMPLOYEES = gql`
  query listHRMSActiveEmployees(
    $designationId: [Int]
    $departmentId: [Int]
    $empTypeId: [Int]
    $workScheduleId: [Int]
    $locationId: [Int]
  ) {
    listHRMSActiveEmployees(
      designationId: $designationId
      departmentId: $departmentId
      empTypeId: $empTypeId
      workScheduleId: $workScheduleId
      locationId: $locationId
    ) {
      errorCode
      message
      employeeDetails {
        employee_name
        employee_id
        department_name
        designation_name
        user_defined_empid
        photo_path
        manager_id
        manager_name
        manager_user_defined_id
      }
    }
  }
`;

export const LIST_HRMS_ADMIN_MEMBERS = gql`
  query listAdminAndManagerMembers(
    $designationId: [Int]
    $departmentId: [Int]
    $empTypeId: [Int]
    $workScheduleId: [Int]
    $locationId: [Int]
  ) {
    listAdminAndManagerMembers(
      designationId: $designationId
      departmentId: $departmentId
      empTypeId: $empTypeId
      workScheduleId: $workScheduleId
      locationId: $locationId
    ) {
      errorCode
      message
      adminMembers {
        user_defined_empid
        employee_id
        first_name
        last_name
        employee_name
        designation_name
        department_name
        photo_path
        role
      }
    }
  }
`;

export const RETRIEVE_ACTIVITY_TRACKER_MEMBERS = gql`
  query listTeamMembers(
    $type: String!
    $designation: [Int]
    $department: [Int]
    $employeeType: [Int]
    $workSchedule: [Int]
    $location: [Int]
  ) {
    listTeamMembers(
      type: $type
      designation: $designation
      department: $department
      employeeType: $employeeType
      workSchedule: $workSchedule
      location: $location
    ) {
      errorCode
      message
      teamMembers
    }
  }
`;

export const LIST_EMP_ONBOARDING_ROLES = gql`
  query listEmpMonitoringRoles {
    listEmpMonitoringRoles {
      errorCode
      message
      rolesData {
        Role_Id
        Role_Name
      }
    }
  }
`;

export const LIST_EMPLOYEE_MANAGERS = gql`
  query listManagerDetails {
    listManagerDetails {
      errorCode
      message
      managerData {
        employee_name
        employee_id
        user_defined_empid
        photo_path
        emp_email
      }
    }
  }
`;

export const RETRIEVE_STEALTH_MODE_APP_UPDATES = gql`
  query retrieveStealthAppUpdates($type: String) {
    retrieveStealthAppUpdates(type: $type) {
      errorCode
      message
      appUpdates
      windowsUpgrade
      linuxUpgrade
      macUpgrade
    }
  }
`;
// ===============
// Mutation
// ===============
export const UPDATE_TRACKING_MODE = gql`
  mutation updateMonitoringMode($empMonitoringType: String!) {
    updateMonitoringMode(empMonitoringType: $empMonitoringType) {
      errorCode
      message
    }
  }
`;

export const ONBOARD_TEAM_MEMBERS_HRMS = gql`
  mutation onboardHRMSMembers($memberDetailsArray: [memberDetailsArray]!) {
    onboardHRMSMembers(memberDetailsArray: $memberDetailsArray) {
      errorCode
      message
      response
    }
  }
`;

export const ONBOARD_TEAM_MEMBERS_EMPLOYEEMONITORING = gql`
  mutation onboardEmployeeMonitorMembers(
    $empMonitorMemberArray: [empMonitorMemberArray]!
  ) {
    onboardEmployeeMonitorMembers(
      empMonitorMemberArray: $empMonitorMemberArray
    ) {
      errorCode
      message
    }
  }
`;

export const UPDATE_EMPMONITOR_STEALTH_MEMBER_DETAILS = gql`
  mutation updateMemberDetailsInEmpMonitorStealth(
    $status: String!
    $memberId: Int!
    $firstName: String!
    $lastName: String
    $assetId: Int!
    $managerId: Int
    $roleId: Int
    $emailAddress: String
    $userDefinedEmployeeId: String
  ) {
    updateMemberDetailsInEmpMonitorStealth(
      status: $status
      memberId: $memberId
      firstName: $firstName
      lastName: $lastName
      assetId: $assetId
      managerId: $managerId
      roleId: $roleId
      emailAddress: $emailAddress
      userDefinedEmployeeId: $userDefinedEmployeeId
    ) {
      errorCode
      message
    }
  }
`;

export const UPDATE_HRMS_STEALTH_MEMBER_DETAILS = gql`
  mutation updateHRMSMemberDetails(
    $status: String!
    $memberId: Int!
    $assetId: Int!
  ) {
    updateHRMSMemberDetails(
      status: $status
      memberId: $memberId
      assetId: $assetId
    ) {
      errorCode
      message
    }
  }
`;

export const BULK_IMPORT_TEAM_MEMBERS_EMPLOYEEMONITORING = gql`
  mutation bulkOnboardStealthMembers(
    $empMonitorMemberArray: [empMonitorMemberArray]!
  ) {
    bulkOnboardStealthMembers(empMonitorMemberArray: $empMonitorMemberArray) {
      errorCode
      message
      response
    }
  }
`;

// hrms plan: invite through employees list card
export const SEND_INVITATION_TO_EMPLOYEES = gql`
  mutation inviteTeamMembers($membersId: [Int]!, $subscribedPlan: String!) {
    inviteTeamMembers(membersId: $membersId, subscribedPlan: $subscribedPlan) {
      errorCode
      message
      validationError
    }
  }
`;

export const UPDATE_HRMS_MEMBER_STATUS = gql`
  mutation updateHRMSMemberStatus($memberId: Int!, $memberStatus: String!) {
    updateHRMSMemberStatus(memberId: $memberId, memberStatus: $memberStatus) {
      errorCode
      message
    }
  }
`;

export const UPDATE_EMPLOYEE_MONITORING_MEMBER_STATUS = gql`
  mutation updateMemberStatus($memberId: Int!, $memberStatus: String!) {
    updateMemberStatus(memberId: $memberId, memberStatus: $memberStatus) {
      errorCode
      message
    }
  }
`;

export const DELETE_INVITED_MEMBERS = gql`
  mutation deleteInvitedMember($memberId: Int!) {
    deleteInvitedMember(memberId: $memberId) {
      errorCode
      message
    }
  }
`;

// employeemonitoring(concent & stealth) resend invitation for both single and bulk invitation
export const BULK_RESEND_INVITE = gql`
  mutation resendFirebaseInvite($memberIdArray: [Int]!) {
    resendFirebaseInvite(memberIdArray: $memberIdArray) {
      errorCode
      message
    }
  }
`;

export const UPDATE_INVITEES_EMAIL = gql`
  mutation updateEmailAddress($memberId: Int!, $emailAddress: String!) {
    updateEmailAddress(memberId: $memberId, emailAddress: $emailAddress) {
      errorCode
      message
    }
  }
`;

// employeemonitoring(concent and stealth) send invitation
export const SEND_BULK_INVITATION = gql`
  mutation sendFirebaseInviteToTeamMembers(
    $teamMemberData: [teamMemberDetailsArray]!
  ) {
    sendFirebaseInviteToTeamMembers(teamMemberData: $teamMemberData) {
      errorCode
      message
    }
  }
`;

// hrms(consent & stealth) resend invitation
export const RESEND_INVITATION = gql`
  mutation resendInvite($memberId: Int!) {
    resendInvite(memberId: $memberId) {
      errorCode
      message
    }
  }
`;

export const UPDATE_EMPMONITOR_CONSENT_TEAM_MEMBER_DETAILS = gql`
  mutation updateMemberDetailsInEmpMonitorConsent(
    $memberId: Int!
    $roleId: Int!
    $managerId: Int
    $memberStatus: String!
    $firstName: String!
    $lastName: String
  ) {
    updateMemberDetailsInEmpMonitorConsent(
      memberId: $memberId
      roleId: $roleId
      managerId: $managerId
      memberStatus: $memberStatus
      firstName: $firstName
      lastName: $lastName
    ) {
      errorCode
      message
    }
  }
`;

export const CHANGE_REPORTEE_MANAGER = gql`
  mutation associateManagerToReportees(
    $newManagerId: Int!
    $previousManagerId: Int!
  ) {
    associateManagerToReportees(
      newManagerId: $newManagerId
      previousManagerId: $previousManagerId
    ) {
      errorCode
      message
    }
  }
`;

export const Check_User_Defined_Employee_Exit = gql`
  query checkUserDefinedEmployeeExit(
    $userDefinedEmpId: String!
    $employeeId: Int
  ) {
    checkUserDefinedEmployeeExit(
      userDefinedEmpId: $userDefinedEmpId
      employeeId: $employeeId
    ) {
      errorCode
      message
    }
  }
`;
