// NOTE
// Please use your own firebase details below.

import firebase from "firebase/app";
import config from "../config";

let partnerid = window.$cookies.get("partnerid");
let firebaseCredentials =
  partnerid === "trulead" && config.tl_firebase_credentials
    ? config.tl_firebase_credentials
    : config.firebase_credentials;

// Initialize Firebase
var firebaseConfig = {
  apiKey: firebaseCredentials.apiKey,
  authDomain: firebaseCredentials.authDomain,
  databaseURL: firebaseCredentials.databaseURL,
  projectId: firebaseCredentials.projectId,
  storageBucket: firebaseCredentials.storageBucket,
  messagingSenderId: firebaseCredentials.messagingSenderId,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export default firebaseApp;
