import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink } from "apollo-link";
import VueApollo from "vue-apollo";
import Vue from "vue";
import store from "./store";
import config from "./config";

// Error Handling
const errorHandler = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Path: ${path}`)
    );
  } else if (networkError) {
    const context = operation.getContext();
    if (context && context.response) {
      const { status } = context.response;
      // on Un-authorization clear user session and redirect to auth
      if (status === 401) {
        store.dispatch("clearUserLock");
      }
    }
  }
});
//configure the auth token
const authHeader = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them
  const token = window.$cookies.get("accessToken");
  const refreshToken = window.$cookies.get("refreshToken");
  const org_code = localStorage.getItem("orgCode");
  const user_ip = store.state.userIpAddress;
  const partnerid = window.$cookies.get("partnerid");
  const d_code = window.$cookies.get("d_code");
  const b_code = window.$cookies.get("b_code");
  const emp_uid = window.$cookies.get("empUid");
  return {
    headers: {
      ...headers,
      org_code: org_code ? org_code : store.getters.orgCode, //on empty orgcode receiving from localstorage assign orgcode from the current url
      user_ip: user_ip,
      Authorization: token ? token : null,
      refresh_token: refreshToken ? refreshToken : null,
      partnerid: partnerid ? partnerid : "-",
      additional_headers: JSON.stringify({
        d_code: d_code,
        b_code: b_code,
        org_code: org_code ? org_code : store.getters.orgCode, //on empty orgcode receiving from localstorage assign orgcode from the current url
        user_ip: user_ip,
        Authorization: token ? token : null,
        refresh_token: refreshToken ? refreshToken : null,
        partnerid: partnerid ? partnerid : "-",
        emp_uid: emp_uid ? emp_uid : null,
      }),
    },
  };
});

const authHeaderWithUserId = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them
  const token = window.$cookies.get("accessToken");
  const refreshToken = window.$cookies.get("refreshToken");
  const org_code = localStorage.getItem("orgCode");
  const user_ip = store.state.userIpAddress;
  const partnerid = window.$cookies.get("partnerid");
  const d_code = window.$cookies.get("d_code");
  const b_code = window.$cookies.get("b_code");
  const emp_uid = window.$cookies.get("empUid");
  return {
    headers: {
      ...headers,
      org_code: org_code ? org_code : store.getters.orgCode, //on empty orgcode receiving from localstorage assign orgcode from the current url
      user_ip: user_ip,
      Authorization: token ? token : null,
      refresh_token: refreshToken ? refreshToken : null,
      partnerid: partnerid ? partnerid : "-",
      emp_uid: emp_uid ? emp_uid : null,
      additional_headers: JSON.stringify({
        d_code: d_code,
        b_code: b_code,
        org_code: org_code ? org_code : store.getters.orgCode, //on empty orgcode receiving from localstorage assign orgcode from the current url
        user_ip: user_ip,
        Authorization: token ? token : null,
        refresh_token: refreshToken ? refreshToken : null,
        partnerid: partnerid ? partnerid : "-",
        emp_uid: emp_uid ? emp_uid : null,
      }),
    },
  };
});

// configure header for unauthorized APIs
const noAuthHeader = setContext((_, { headers }) => {
  const org_code = localStorage.getItem("orgCode");
  const d_code = window.$cookies.get("d_code");
  const b_code = window.$cookies.get("b_code");
  const partnerid = window.$cookies.get("partnerid");
  return {
    headers: {
      ...headers,
      org_code: org_code ? org_code : store.getters.orgCode, //on empty orgcode receiving from localstorage assign orgcode from the current url
      partnerid: partnerid ? partnerid : "-",
      additional_headers: JSON.stringify({
        d_code: d_code,
        b_code: b_code,
        partnerid: partnerid ? partnerid : "-",
        org_code: org_code ? org_code : store.getters.orgCode, //on empty orgcode receiving from localstorage assign orgcode from the current url
      }),
    },
  };
});

//After the backend responds, we take the access token and refreshToken from headers if it exists, and save it in the cookie.
const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const req_response = response;
    if (req_response.identityToken) {
      const token = req_response.identityToken.idToken;

      //set tokens as received from the backend with expire time
      if (token && window.$cookies.get("accessToken") !== token) {
        let partnerid = window.$cookies.get("partnerid");
        if (partnerid && partnerid.toLowerCase() === "entomo") {
          var date = new Date();
          date.setTime(date.getTime() + 90 * 24 * 60 * 60 * 1000); // Convert 90 days to milliseconds
          window.$cookies.set("accessToken", token, date.toUTCString());
        } else {
          // default expiry 59 mins
          window.$cookies.set("accessToken", token, "59MIN");
        }
      }
    }

    return response;
  });
});

const httpLinkA = new HttpLink({
  uri: config.graphql_endpoint.ats,
});
const httpLinkB = new HttpLink({
  uri: config.graphql_endpoint.settings,
});

const httpLinkC = new HttpLink({
  uri: config.graphql_endpoint.hrappBE,
});

const httpLinkE = new HttpLink({
  uri: config.graphql_endpoint.employeeMonitoring,
});

const httpLinkF = new HttpLink({
  uri: config.graphql_endpoint.payMaster,
});

const httpLinkG = new HttpLink({
  uri: config.graphql_endpoint.billing,
});

const httpLinkH = new HttpLink({
  uri: config.graphql_endpoint.atsSignIn,
});

const httpLinkI = new HttpLink({
  uri: config.graphql_endpoint.coreHrRead,
});

const httpLinkJ = new HttpLink({
  uri: config.graphql_endpoint.coreHrWrite,
});

const httpLinkK = new HttpLink({
  uri: config.graphql_endpoint.empMonitorRead,
});

const httpLinkL = new HttpLink({
  uri: config.graphql_endpoint.hrappBERead,
});

const httpLinkM = new HttpLink({
  uri: config.graphql_endpoint.hrappBEWrite,
});

const httpLinkN = new HttpLink({
  uri: config.graphql_endpoint.coreHrNoAuth,
});

const httpLinkO = new HttpLink({
  uri: config.graphql_endpoint.docusignRead,
});

const httpLinkP = new HttpLink({
  uri: config.graphql_endpoint.docusignWrite,
});

const httpLinkQ = new HttpLink({
  uri: config.graphql_endpoint.docusignNoAuthRead,
});

const httpLinkR = new HttpLink({
  uri: config.graphql_endpoint.empMonitorWrite,
});

const httpLinkS = new HttpLink({
  uri: config.graphql_endpoint.docusignNoAuthWrite,
});

const httpLinkT = new HttpLink({
  uri: config.graphql_endpoint.attendanceRead,
});

const httpLinkU = new HttpLink({
  uri: config.graphql_endpoint.attendanceWrite,
});

const httpLinkV = new HttpLink({
  uri: config.graphql_endpoint.onboardingRead,
});

const httpLinkW = new HttpLink({
  uri: config.graphql_endpoint.onboardingWrite,
});

const httpLinkX = new HttpLink({
  uri: config.graphql_endpoint.onboardingReadWrite,
});

const httpLinkY = new HttpLink({
  uri: config.graphql_endpoint.stepFunction,
});

const httpLinkZ = new HttpLink({
  uri: config.graphql_endpoint.integrationRead,
});
const httpLinkAA = new HttpLink({
  uri: config.graphql_endpoint.empMonitorBatchRead,
});
const httpLinkAB = new HttpLink({
  uri: config.graphql_endpoint.taxAndStatutoryRead,
});
const httpLinkAC = new HttpLink({
  uri: config.graphql_endpoint.atsExternal,
});
const httpLinkAD = new HttpLink({
  uri: config.graphql_endpoint.coreHrExternal,
});
const httpLinkAE = new HttpLink({
  uri: config.graphql_endpoint.orgDataWrite,
});
const httpLinkAF = new HttpLink({
  uri: config.graphql_endpoint.orgDataRead,
});

const authLink = authHeader;
const authUserLink = authHeaderWithUserId;
const noAuthLink = noAuthHeader;
const errorLink = errorHandler;

//composing all configuration in link using apollolink
const linkA = ApolloLink.from([authLink, afterwareLink, errorLink, httpLinkA]);
const linkB = ApolloLink.from([authLink, afterwareLink, errorLink, httpLinkB]);
const linkC = ApolloLink.from([authLink, afterwareLink, errorLink, httpLinkC]);
const linkE = ApolloLink.from([authLink, afterwareLink, errorLink, httpLinkE]);
const linkF = ApolloLink.from([authLink, afterwareLink, errorLink, httpLinkF]);
const linkG = ApolloLink.from([authLink, afterwareLink, errorLink, httpLinkG]);
const linkH = ApolloLink.from([noAuthLink, errorLink, httpLinkH]);
const linkI = ApolloLink.from([authLink, afterwareLink, errorLink, httpLinkI]);
const linkJ = ApolloLink.from([authLink, afterwareLink, errorLink, httpLinkJ]);
const linkK = ApolloLink.from([authLink, afterwareLink, errorLink, httpLinkK]);
const linkL = ApolloLink.from([authLink, afterwareLink, errorLink, httpLinkL]);
const linkM = ApolloLink.from([authLink, afterwareLink, errorLink, httpLinkM]);
const linkN = ApolloLink.from([noAuthLink, errorLink, httpLinkN]);
const linkO = ApolloLink.from([authLink, afterwareLink, errorLink, httpLinkO]);
const linkP = ApolloLink.from([authLink, afterwareLink, errorLink, httpLinkP]);
const linkQ = ApolloLink.from([noAuthLink, errorLink, httpLinkQ]);
const linkR = ApolloLink.from([authLink, afterwareLink, errorLink, httpLinkR]);
const linkS = ApolloLink.from([noAuthLink, errorLink, httpLinkS]);
const linkT = ApolloLink.from([authLink, afterwareLink, errorLink, httpLinkT]);
const linkU = ApolloLink.from([authLink, afterwareLink, errorLink, httpLinkU]);
const linkV = ApolloLink.from([authLink, afterwareLink, errorLink, httpLinkV]);
const linkW = ApolloLink.from([authLink, afterwareLink, errorLink, httpLinkW]);
const linkX = ApolloLink.from([afterwareLink, errorLink, httpLinkX]);
const linkY = ApolloLink.from([authLink, afterwareLink, errorLink, httpLinkY]);
const linkZ = ApolloLink.from([authLink, afterwareLink, errorLink, httpLinkZ]);
const linkAA = ApolloLink.from([
  authLink,
  afterwareLink,
  errorLink,
  httpLinkAA,
]);
const linkAB = ApolloLink.from([
  authLink,
  afterwareLink,
  errorLink,
  httpLinkAB,
]);
const linkAC = ApolloLink.from([
  noAuthLink,
  afterwareLink,
  errorLink,
  httpLinkAC,
]);
const linkAD = ApolloLink.from([
  noAuthLink,
  afterwareLink,
  errorLink,
  httpLinkAD,
]);
const linkAE = ApolloLink.from([
  authUserLink,
  afterwareLink,
  errorLink,
  httpLinkAE,
]);
const linkAF = ApolloLink.from([
  authLink,
  afterwareLink,
  errorLink,
  httpLinkAF,
]);
// Create the two apollo client instance
const apolloClientA = new ApolloClient({
  link: linkA,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production", //enable dev tool only on development
});
const apolloClientB = new ApolloClient({
  link: linkB,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientC = new ApolloClient({
  link: linkC,
  cache: new InMemoryCache({ addTypename: false }), //to avoid getting typename in the response
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientE = new ApolloClient({
  link: linkE,
  cache: new InMemoryCache({ addTypename: false }), //to avoid getting typename in the response
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientF = new ApolloClient({
  link: linkF,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientG = new ApolloClient({
  link: linkG,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientH = new ApolloClient({
  link: linkH,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientI = new ApolloClient({
  link: linkI,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientJ = new ApolloClient({
  link: linkJ,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientK = new ApolloClient({
  link: linkK,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientL = new ApolloClient({
  link: linkL,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientM = new ApolloClient({
  link: linkM,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientN = new ApolloClient({
  link: linkN,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientO = new ApolloClient({
  link: linkO,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientP = new ApolloClient({
  link: linkP,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientQ = new ApolloClient({
  link: linkQ,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientR = new ApolloClient({
  link: linkR,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientS = new ApolloClient({
  link: linkS,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientT = new ApolloClient({
  link: linkT,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientU = new ApolloClient({
  link: linkU,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientV = new ApolloClient({
  link: linkV,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientW = new ApolloClient({
  link: linkW,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientX = new ApolloClient({
  link: linkX,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientY = new ApolloClient({
  link: linkY,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientZ = new ApolloClient({
  link: linkZ,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientAA = new ApolloClient({
  link: linkAA,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientAB = new ApolloClient({
  link: linkAB,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientAC = new ApolloClient({
  link: linkAC,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientAD = new ApolloClient({
  link: linkAD,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientAE = new ApolloClient({
  link: linkAE,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});
const apolloClientAF = new ApolloClient({
  link: linkAF,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});
function apolloConfig() {
  return () => {
    // Install the Vue plugin
    Vue.use(VueApollo);
    // Create vue apollo provider
    const apolloProvider = new VueApollo({
      clients: {
        apolloClientA,
        apolloClientB,
        apolloClientC,
        apolloClientE,
        apolloClientF,
        apolloClientG,
        apolloClientH,
        apolloClientI,
        apolloClientJ,
        apolloClientK,
        apolloClientL,
        apolloClientM,
        apolloClientN,
        apolloClientO,
        apolloClientP,
        apolloClientQ,
        apolloClientR,
        apolloClientS,
        apolloClientT,
        apolloClientU,
        apolloClientV,
        apolloClientW,
        apolloClientX,
        apolloClientY,
        apolloClientZ,
        apolloClientAA,
        apolloClientAB,
        apolloClientAC,
        apolloClientAD,
        apolloClientAE,
        apolloClientAF,
      },
      defaultClient: apolloClientA,
    });
    return apolloProvider;
  };
}

export const createProvider = apolloConfig();
