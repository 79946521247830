import gql from "graphql-tag";
// ===============
// Queries
// ===============
export const RETRIEVE_MY_ACTIVITY_SCREENSHOTS = gql`
  query listMyActivity(
    $employeeId: Int!
    $date: Date!
    $callFromMyTeamActivityForm: Int
    $timeZone: String!
    $workScheduleId: Int!
  ) {
    listMyActivity(
      employeeId: $employeeId
      date: $date
      callFromMyTeamActivityForm: $callFromMyTeamActivityForm
      timeZone: $timeZone
      workScheduleId: $workScheduleId
    ) {
      errorCode
      message
      activity
      validationError
    }
  }
`;

export const LIST_EMPLOYEES = gql`
  query listEmployees(
    $isCallFromReportForm: Int!
    $designation: [Int]
    $department: [Int]
    $employeeType: [Int]
    $location: [Int]
    $subscribedPlan: String!
    $workScheduleId: Int
  ) {
    listEmployees(
      isCallFromReportForm: $isCallFromReportForm
      designation: $designation
      department: $department
      employeeType: $employeeType
      location: $location
      subscribedPlan: $subscribedPlan
      workScheduleId: $workScheduleId
    ) {
      errorCode
      message
      listEmployees
    }
  }
`;

export const RETRIEVE_CONSENT_APP_DOWNLOAD_URL = gql`
  query getTrackerReleaseUpdates(
    $currentAppVersion: String
    $operatingSystem: String
    $downloadFrom: String
    $type: String
  ) {
    getTrackerReleaseUpdates(
      currentAppVersion: $currentAppVersion
      operatingSystem: $operatingSystem
      downloadFrom: $downloadFrom
      type: $type
    ) {
      errorCode
      message
      appUpdates
    }
  }
`;

export const GET_TEAM_MEMBERS = gql`
  query getTeamMembers($formName: String) {
    getTeamMembers(formName: $formName) {
      errorCode
      message
      teamMembers
    }
  }
`;

export const GET_ALL_TIME_ZONES = gql`
  query retrieveAllTimeZoneDetails {
    retrieveAllTimeZoneDetails {
      errorCode
      message
      timeZones {
        time_zone
        zone_id
      }
    }
  }
`;

export const LIST_EMP_APPS_ACTIVITY = gql`
  query listMyActivityApps(
    $employeeId: Int!
    $date: Date!
    $callFromMyTeamActivityForm: Int
    $timeZone: String!
    $workScheduleId: Int!
  ) {
    listMyActivityApps(
      employeeId: $employeeId
      date: $date
      callFromMyTeamActivityForm: $callFromMyTeamActivityForm
      timeZone: $timeZone
      workScheduleId: $workScheduleId
    ) {
      errorCode
      message
      appDetails
    }
  }
`;

export const LIST_EMP_URLS_ACTIVITY = gql`
  query listMyActivityUrls(
    $employeeId: Int!
    $date: Date!
    $callFromMyTeamActivityForm: Int
    $timeZone: String!
    $workScheduleId: Int!
  ) {
    listMyActivityUrls(
      employeeId: $employeeId
      date: $date
      callFromMyTeamActivityForm: $callFromMyTeamActivityForm
      timeZone: $timeZone
      workScheduleId: $workScheduleId
    ) {
      errorCode
      message
      urlDetails
    }
  }
`;

export const GET_ACTIVITY_GOAL_FOR_DASHBOARD = gql`
  query getActivityGoalSettingsInDashboard($employeeId: Int) {
    getActivityGoalSettingsInDashboard(employeeId: $employeeId) {
      errorCode
      message
      activityGoal {
        organizationLevelActivityGoalSettings {
          lowActivityMinimum
          lowActivityMaximum
          moderateActivityMinimum
          moderateActivityMaximum
          highActivityMinimum
          highActivityMaximum
        }
        employeeLevelActivityGoalSettings {
          employeeId
          lowActivityMinimum
          lowActivityMaximum
          moderateActivityMinimum
          moderateActivityMaximum
          highActivityMinimum
          highActivityMaximum
        }
      }
    }
  }
`;

// used for consent(for both bulk and single notify)
export const NOTIFY_UN_TRACKED_EMPLOYEES = gql`
  query notifyEmployee(
    $memberId: [Int!]
    $emailBody: String!
    $emailSubject: String!
  ) {
    notifyEmployee(
      memberId: $memberId
      emailBody: $emailBody
      emailSubject: $emailSubject
    ) {
      errorCode
      message
    }
  }
`;

export const RETRIEVE_INSIGHTS_CHART_DETAILS = gql`
  query dailyInsights(
    $employeeId: Int!
    $date: Date
    $callFromMyTeamActivityForm: Int
    $timeZone: String!
    $workScheduleId: Int!
  ) {
    dailyInsights(
      employeeId: $employeeId
      date: $date
      callFromMyTeamActivityForm: $callFromMyTeamActivityForm
      timeZone: $timeZone
      workScheduleId: $workScheduleId
    ) {
      errorCode
      message
      activity
      activityDate
      validationError
    }
  }
`;

export const RETRIEVE_DAILY_INSIGHTS_INTERNET_STATUS = gql`
  query getInternetStatusOfDay(
    $employeeId: Int!
    $date: Date!
    $callFromMyTeamActivityForm: Int
    $timeZone: String!
    $workScheduleId: Int!
  ) {
    getInternetStatusOfDay(
      employeeId: $employeeId
      date: $date
      callFromMyTeamActivityForm: $callFromMyTeamActivityForm
      timeZone: $timeZone
      workScheduleId: $workScheduleId
    ) {
      errorCode
      message
      internetData {
        internetStatus
        timeRange
      }
    }
  }
`;

export const RETRIEVE_DAILY_INSIGHTS_HOURLY_ACTIVITIES = gql`
  query getDailyActivitiesForAnHour(
    $employeeId: Int!
    $startDateTime: Date
    $date: Date
    $callFromMyTeamActivityForm: Int
    $timeZone: String!
    $workScheduleId: Int!
    $isExport: Int
  ) {
    getDailyActivitiesForAnHour(
      employeeId: $employeeId
      startDateTime: $startDateTime
      date: $date
      callFromMyTeamActivityForm: $callFromMyTeamActivityForm
      timeZone: $timeZone
      workScheduleId: $workScheduleId
      isExport: $isExport
    ) {
      errorCode
      message
      activityDetails
      activityTime
    }
  }
`;

export const GET_ACTIVITY_SUMMARY_DETAILS = gql`
  query getActivitySummaryDetails(
    $employeeId: Int!
    $date: Date!
    $callFromMyTeamActivityForm: Int
    $timeZone: String!
    $workScheduleId: Int!
  ) {
    getActivitySummaryDetails(
      employeeId: $employeeId
      date: $date
      callFromMyTeamActivityForm: $callFromMyTeamActivityForm
      timeZone: $timeZone
      workScheduleId: $workScheduleId
    ) {
      errorCode
      message
      activityDetails
      considerNotActiveIdleTime
      fixedDailyWorkHours
    }
  }
`;

export const GET_APPLICATION_SUMMARY_DETAILS = gql`
  query getApplicationSummaryDetails(
    $employeeId: Int!
    $date: Date!
    $callFromMyTeamActivityForm: Int
    $timeZone: String!
    $workScheduleId: Int!
  ) {
    getApplicationSummaryDetails(
      employeeId: $employeeId
      date: $date
      callFromMyTeamActivityForm: $callFromMyTeamActivityForm
      timeZone: $timeZone
      workScheduleId: $workScheduleId
    ) {
      errorCode
      message
      appDetails
      totalTimeSpent
    }
  }
`;

export const GET_URL_SUMMARY_DETAILS = gql`
  query getUrlSummaryDetails(
    $employeeId: Int!
    $date: Date!
    $callFromMyTeamActivityForm: Int
    $timeZone: String!
    $workScheduleId: Int!
  ) {
    getUrlSummaryDetails(
      employeeId: $employeeId
      date: $date
      callFromMyTeamActivityForm: $callFromMyTeamActivityForm
      timeZone: $timeZone
      workScheduleId: $workScheduleId
    ) {
      errorCode
      message
      urlDetails
      totalTimeSpent
    }
  }
`;

export const GET_EMPLOYEE_WORK_SCHEDULE_DETAILS = gql`
  query getEmployeeWorkScheduleDetails($employeeId: Int!) {
    getEmployeeWorkScheduleDetails(employeeId: $employeeId) {
      errorCode
      message
      employeeWorkScheduleDetails {
        workScheduleId
        workSchedule
        shiftMarginStartDateTime
        shiftMarginEndDateTime
        timeZoneId
        timeZone
      }
    }
  }
`;

export const GET_TEAM_PRODUCTIVITY_SUMMARY = gql`
  query getMyTeamProductivityDetails(
    $date: Date!
    $workScheduleId: Int!
    $timeZone: String!
  ) {
    getMyTeamProductivityDetails(
      date: $date
      workScheduleId: $workScheduleId
      timeZone: $timeZone
    ) {
      errorCode
      message
      productivityDetails {
        averageTimeOnProductiveAndNeutralAppsAndUrls
        userProductivityPercentageProductiveAndNeutral
        considerNotActiveIdleTime
        fixedDailyWorkHours
      }
    }
  }
`;

export const GET_TEAM_ACTIVITY_SUMMARY = gql`
  query getMyTeamActivityDetails(
    $date: Date!
    $workScheduleId: Int!
    $timeZone: String!
  ) {
    getMyTeamActivityDetails(
      date: $date
      workScheduleId: $workScheduleId
      timeZone: $timeZone
    ) {
      errorCode
      message
      teamActivityDetails {
        considerNotActiveIdleTime
        fixedDailyWorkHours
        averageUserActiveTime
        averageSystemUpTime
        averageComputerActivityTime
        systemProductivityPercentage
      }
    }
  }
`;

export const LIST_TEAM_ACTIVITY = gql`
  query listMyTeamActivityDetails(
    $date: Date!
    $designationId: [Int]
    $departmentId: [Int]
    $empTypeId: [Int]
    $locationId: [Int]
    $timeZone: String!
    $subscribedPlan: String
    $workScheduleId: Int!
  ) {
    listMyTeamActivityDetails(
      date: $date
      designationId: $designationId
      departmentId: $departmentId
      empTypeId: $empTypeId
      locationId: $locationId
      timeZone: $timeZone
      subscribedPlan: $subscribedPlan
      workScheduleId: $workScheduleId
    ) {
      errorCode
      message
      considerNotActiveIdleTime
      fixedDailyWorkHours
      myTeamDetails {
        employeeId
        userDefinedEmployeeId
        employeeName
        employeeEmail
        managerId
        memberRole
        memberId
        assetId
        checkInTime
        checkOutTime
        trackingStatus
        systemUpTime
        userActiveTime
        notActiveAndIdleTime
        computerActivityTime
        systemProductivityPercentage
      }
    }
  }
`;

export const LIST_TEAM_PRODUCTIVITY = gql`
  query listMyTeamAppUrlProductivity(
    $date: Date!
    $employeeId: [Int]!
    $timeZone: String!
    $workScheduleId: Int!
  ) {
    listMyTeamAppUrlProductivity(
      date: $date
      employeeId: $employeeId
      timeZone: $timeZone
      workScheduleId: $workScheduleId
    ) {
      errorCode
      message
      teamAppUrlProductivityDetails {
        employeeId
        productiveTime
        unProductiveTime
        neutralTime
      }
    }
  }
`;

export const RETRIEVE_CHOSEN_TIMEZONE = gql`
  query retrieveDefaultTimeZoneDetails {
    retrieveDefaultTimeZoneDetails {
      errorCode
      message
      timeZones {
        time_zone
        location_id
        zone_id
        workschedule_id
      }
    }
  }
`;
// ===============
// Mutation
// ===============
export const ACCEPT_INVITE = gql`
  mutation acceptInvite($inviteData: String!) {
    acceptInvite(inviteData: $inviteData) {
      message
    }
  }
`;

export const UPDATE_TIME_ZONE = gql`
  mutation updateDefaultTimeZoneDetails(
    $locationId: Int!
    $timeZoneId: Int!
    $workScheduleId: Int!
  ) {
    updateDefaultTimeZoneDetails(
      locationId: $locationId
      timeZoneId: $timeZoneId
      workScheduleId: $workScheduleId
    ) {
      errorCode
      message
    }
  }
`;

export const UPDATE_HELP_SCREEN_FLAG = gql`
  mutation updateHelpScreenFlag {
    updateHelpScreenFlag {
      errorCode
      message
    }
  }
`;
