export default {
  //shift-scheduling
  //store the shift type list array in shift-scheduling
  UPDATE_SHIFT_TYPE_LIST(state, list) {
    state.shiftTypeList = list;
  },
  UPDATE_ACTIVE_SHIFT_LIST(state, list) {
    state.activeShiftTypeList = list;
  },
  UPDATE_PAYCYCLE_DATES(state, dates) {
    state.paycycleDates = dates;
  },
  UPDATE_CURRENT_SUB_TAB_NAME(state, tabName) {
    state.currentSelectedTab = tabName;
  },
  UPDATE_SHIFT_SEARCH_VAL(state, searchValue) {
    state.shiftSearch = searchValue;
  },
  UPDATE_SHIFT_FILTER_VAL(state, filterVal) {
    state.shiftFilterVal = filterVal;
  },
  UPDATE_DYNAMIC_WEEK_OFF_FLAG(state, flagValue) {
    state.dynamicWeekOff = flagValue;
  },
  UPDATE_BULK_SELECTED_OPTION(state, selectedVal) {
    state.bulkImportSelectedOption = selectedVal;
  },
  UPDATE_CURRENT_PAYROLL_MONTH(state, month) {
    state.currentPayrollMonth = month;
  },
  UPDATE_FILTERED_MONTH_YEAR(state, monthYear) {
    state.selectedFilterMonthYear = monthYear;
  },
};
