export default {
  // update the current tab
  UPDATE_EMP_MONITOR_CURRENT_TAB(state, value) {
    state.empMonitorCurrentTab = value;
  },

  // update the source from where the settings is triggered
  UPDATE_EMP_MONITOR_SETTINGS_SOURCE(state, value) {
    state.empMonitorSettingsSource = value;
  },
};
