import { createProvider } from "../../apolloConfiguration";
import { RETRIEVE_RATINGS_SETTINGS } from "@/graphql/employees/performanceEvaluationQueries";

export default {
  //delete the selected salary template
  retrieveRatingsSettings({ commit }) {
    const {
      clients: { apolloClientC },
    } = createProvider();
    return new Promise((resolve) => {
      apolloClientC
        .query({
          query: RETRIEVE_RATINGS_SETTINGS,
          client: "apolloClientC",
        })
        .then((res) => {
          const { errorCode, getRatingDetails } = res.data.retrieveRatings;
          if (getRatingDetails && !errorCode) {
            const { ratingDetails, maximumRating } = getRatingDetails;
            commit("UPDATE_RATINGS_SCALE", maximumRating ? maximumRating : 5);
            commit("UPDATE_RATING_DETAILS", ratingDetails ? ratingDetails : []);
            resolve("success");
          } else {
            resolve("error");
          }
        })
        .catch(() => {
          resolve("error");
        });
    });
  },
};
