import state from "./moduleEmployeeMonitoring.js";
import mutations from "./moduleEmployeeMonitoringMutations.js";
import actions from "./moduleEmployeeMonitoringActions.js";
import getters from "./moduleEmployeeMonitoringGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};
