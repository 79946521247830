import Vue from "vue";
import moment from "moment";
import store from "../store";
//Filter to return first letter in capital of each word
Vue.filter("letterAvatar", function (value, isSingleLetter) {
  if (!value) return "";
  var firstChar = value ? value.charAt(0).toUpperCase() : "";
  var lastChar = value ? value.split(" ").pop().charAt(0).toUpperCase() : "";
  //condition checked for single letter avatar
  if (isSingleLetter) {
    return firstChar;
  } else {
    return firstChar + lastChar;
  }
});

//Filter to return concatenate first letter in capital of each param
Vue.filter("capitalize", function (value, lastWord) {
  if (!value) return "";
  value = value.toString();

  let capitalize_value = value.charAt(0).toUpperCase();
  let capitalize_last_word = "";

  if (!lastWord) {
    lastWord = value.toString();
    capitalize_last_word = lastWord.charAt(0).toUpperCase();
  }

  return capitalize_value + capitalize_last_word;
});

// filter to format the seconds into HH:mm:ss or mm:ss format
Vue.filter("formatSeconds", function (value, format) {
  if (format === "hh:mm:ss") {
    if (!value) return "00:00:00";
    var sec_num = value;
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds;
  } else if (format === "mm:ss") {
    if (!value) return "00:00";
    var mins = Math.floor(value / 60);
    var sec = value - mins * 60;
    return mins + ":" + sec;
  } else {
    if (!value) return "0";
    var min = Math.floor(value / 60);
    return Math.round(min);
  }
});

Vue.filter("formatDate", function (value) {
  const day = moment(value);
  return day.format("ddd, D MMM");
});

Vue.filter("orgDateFormatter", function (value) {
  if (value) {
    const { orgDateFormat } = store.state.orgDetails;
    const day = moment(value);
    let format = orgDateFormat;
    return day.format(format);
  } else {
    return "";
  }
});
