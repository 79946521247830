import { getErrorCodes, handleNetworkErrors } from "@/helper.js";

export default {
  listActiveShiftType(state) {
    let shiftList = state.shiftTypeList;
    shiftList = shiftList.filter((el) => el.Status === "Active");
    return shiftList;
  },

  // get error messages based on error codes for update shift scheduling function
  shiftSchedulingCommonErrors: () => (payLoad) => {
    const { errorsList, type } = payLoad;
    let msgBasedOnType =
      type === "bulk"
        ? "importing the shift assignments"
        : "updating the shift assignment";
    let errorContent = "";
    if (errorsList && errorsList.graphQLErrors) {
      let errorCode = errorsList.graphQLErrors
        ? getErrorCodes(errorsList)
        : errorsList.errorCode;
      switch (errorCode) {
        case "_DB0000": // Technical issue
          errorContent = `It’s us! There seems to be some technical difficulties while ${msgBasedOnType}. Please try after some time.`;
          break;
        case "_DB0102": // This employee does not have a edit access rights.
          errorContent = `Sorry, you don't have access rights to ${
            type === "bulk" ? "import" : "update"
          } the shift assignment. Please contact your HR administrator.`;
          break;
        case "_DB0101": // This employee does not have a create access rights.
          errorContent = `Sorry, you don't have access rights to ${
            type === "bulk" ? "import" : "add"
          } the shift assignment. Please contact your HR administrator.`;
          break;
        case "SS0134": // Shift is in 'In Active' status or doesn't exist. Please choose an alternate shift.
          errorContent =
            "The shift is in 'In Active' status or doesn't exist. Please choose an alternate shift.";
          break;
        case "OT0107": //  Shift does not schedule for this date.
          errorContent = "The shift does not schedule for this date.";
          break;
        case "OT0128": // The next shift consideration start date-time should be greater than the current shift's regular end date-time when the overlapping shift is enabled.
          errorContent =
            "The next shift consideration start date-time should be greater than the current shift's regular end date-time when the overlapping shift is enabled.";
          break;
        case "SS0101": // Shift cannot be assigned before the date of join or after the resignation date.
          errorContent =
            "The shift cannot be assigned before the date of join or after the resignation date.";
          break;
        case "SS0102": // Shift is already scheduled for the shift date.
          errorContent = "The shift is already scheduled for the shift date.";
          break;
        case "SS0103": // Shift is overlapped with the previous or next day shift.
          errorContent =
            "The shift is overlapped with the previous or next day shift.";
          break;
        case "SS0145": // Shift does not exist for this date as the record is already deleted in the same or some other user session.
          errorContent =
            "Shift does not exist for this date as the record is already deleted in the same or some other user session.";
          break;
        case "SS0147": // Unable to add the week off as the holiday exists for this date.
          errorContent =
            "Unable to add the week off as the holiday exists for this date.";
          break;
        case "SS0110": // Shift is already updated.
          errorContent = "The shift is already updated.";
          break;
        case "SS0149": // Unable to update shift as the leave or compensatory off, or attendance exist.
          errorContent =
            "Unable to update shift as the leave or compensatory off, or attendance exist.";
          break;
        case "SS0104": // Unable to add the shift as some employees have joined after the shift date or resigned before the shift date or shift is already scheduled for a chosen date.
          errorContent =
            "Unable to add the shift as some employees have joined after the shift date or resigned before the shift date or shift is already scheduled for a chosen date.";
          break;
        case "SS0105": // Unable to add the shift as some employees have joined after the shift date or resigned before the shift date or the chosen shift is overlapped with the previous or next day shift.
          errorContent =
            "Unable to add the shift as some employees have joined after the shift date or resigned before the shift date or the chosen shift is overlapped with the previous or next day shift.";
          break;
        case "SS0106": // Unable to add the shift for the employees as the shift is already scheduled for the chosen date or the chosen shift is overlapped with the previous or next day shift.
          errorContent =
            "Unable to add the shift for the employees as the shift is already scheduled for the chosen date or the chosen shift is overlapped with the previous or next day shift.";
          break;
        case "SS0107": // Unable to add the shift as the shift should not be scheduled before the date of join or after the resignation or the shift is already scheduled or the shift is overlapped with the previous or next day shift.
          errorContent =
            "Unable to add the shift as the shift should not be scheduled before the date of join or after the resignation or the shift is already scheduled or the shift is overlapped with the previous or next day shift.";
          break;
        case "_DB0001": // Error while retrieving the employee access rights
        case "_DB0002": // Error while trying to check the employee access rights
        case "_DB0104": // While check access rights form not found'
        case "_UH0001": // unhandled error from backend
        case "SS0109": // Unable to validate the form access.
        case "SS0117": // Error while processing the request to add/update the shift schedule.
        case "SS0131": // Error while processing the request to get the attendance details for the shift date.
        case "SS0129": // Shift-type work schedule detail does not exist.
        case "SS0136": // Error while processing the request to import shift scheduling details.
        case "SS0111": // Unable to update the shift due to internal error.
        case "SS0130": // Error while processing the request to get the shift-type work schedule details.
        case "SS0003": // Unable to update the shift.
        case "SS0001": // Unable to add the shift.
        case "SS0108": // Unable to add the shift due to internal error.
        default:
          errorContent = `Something went wrong while ${msgBasedOnType}. If you continue to see this problem please talk to your platform administrator.`;
          break;
      }
    } else if (errorsList && errorsList.networkError) {
      errorContent = handleNetworkErrors(errorsList);
    } else {
      errorContent = `Something went wrong while ${msgBasedOnType}. If you continue to see this problem please talk to your platform administrator.`;
    }
    return errorContent;
  },

  // week off import error handing
  bulkWeekOffErrors: () => (payLoad) => {
    const { errorsList } = payLoad;
    let errorContent = "";
    if (errorsList && errorsList.graphQLErrors) {
      let errorCode = errorsList.graphQLErrors
        ? getErrorCodes(errorsList)
        : errorsList.errorCode;
      switch (errorCode) {
        case "_DB0000": // Technical issue
          errorContent = `It’s us! There seems to be some technical difficulties while importing the week off. Please try after some time.`;
          break;
        case "_DB0102": // This employee does not have a edit access rights.
          errorContent = `Sorry, you don't have access rights to import the week off. Please contact your HR administrator.`;
          break;
        case "SS0144": // Unable to add the week off as leave exists for this date.
          errorContent =
            "Unable to add the week off as leave exists for this date.";
          break;
        case "SS0146": // Unable to add the week off as compensatory Off exists for this date.
          errorContent =
            "Unable to add the week off as compensatory off exists for this date.";
          break;
        case "SS0148": // Unable to add the week off as attendance Off exists for this date.
          errorContent =
            "Unable to add the week off as attendance off exists for this date.";
          break;
        case "SS0145": // Shift does not exist for this date as the record is already deleted in the same or some other user session.
          errorContent =
            "Shift does not exist for this date as the record is already deleted in the same or some other user session.";
          break;
        case "SS0143": // Week off added for this date already.
          errorContent = "Week off added for this date already.";
          break;
        case "SS0147": // Unable to add the week off as the holiday exists for this date.
          errorContent =
            "Unable to add the week off as the holiday exists for this date.";
          break;
        case "_DB0001": // Error while retrieving the employee access rights
        case "_DB0002": // Error while trying to check the employee access rights
        case "_DB0104": // While check access rights form not found'
        case "_UH0001": // unhandled error from backend
        case "SS0109": // Unable to validate the form access.
        case "SS0142": // Error while processing the request to import week off details.
        case "SS0133": // Error while processing the request to update the week off for the date.
        case "SS0002": // Unable to add the week off.
        default:
          errorContent = `Something went wrong while importing the week off. If you continue to see this problem please talk to your platform administrator.`;
          break;
      }
    } else if (errorsList && errorsList.networkError) {
      errorContent = handleNetworkErrors(errorsList);
    } else {
      errorContent = `Something went wrong while importing the week off. If you continue to see this problem please talk to your platform administrator.`;
    }
    return errorContent;
  },
};
