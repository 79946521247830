import gql from "graphql-tag";

// ===============
// Queries
// ===============

export const LIST_EMPLOYEES_GOALS_AND_RATINGS = gql`
  query listEmployeeGoalsAndRating($year: Int!, $month: Int!) {
    listEmployeeGoalsAndRating(year: $year, month: $month) {
      errorCode
      message
      listEmployeeGoalRatingsDetails {
        performanceAssessmentId
        employeeId
        employeeName
        designation
        department
        userDefinedEmpId
        dateOfJoining
        reviewerName
        reviewerEmployeeId
        reviewerUserDefinedEmpId
        assessmentStatus
        goalPublishStatus
        ratingPublishStatus
        overallRating
        averageYearlyRating
        comments
        photoPath
        employeeStatus
        addedOn
        addedByEmployeeName
        updatedOn
        updatedByEmployeeName
      }
    }
  }
`;

export const LIST_EMPLOYEE_GOALS_AND_RATINGS = gql`
  query listEmployeesAchievement(
    $startYear: Int!
    $endYear: Int!
    $startMonth: Int!
    $endMonth: Int!
  ) {
    listEmployeesAchievement(
      startYear: $startYear
      endYear: $endYear
      startMonth: $startMonth
      endMonth: $endMonth
    ) {
      errorCode
      message
      listEmployeeAchievementDetails {
        assessmentMonthYear
        overallRating
        assessmentStatus
        goalPublishStatus
        ratingPublishStatus
      }
    }
  }
`;

export const LIST_PMS_EMPLOYEES = gql`
  query listPmsEmployees(
    $departmentId: [Int]
    $designationId: [Int]
    $locationId: [Int]
    $customGroupId: [Int]
    $empTypeId: [Int]
    $month: Int!
    $year: Int!
  ) {
    listPmsEmployees(
      departmentId: $departmentId
      designationId: $designationId
      locationId: $locationId
      customGroupId: $customGroupId
      empTypeId: $empTypeId
      month: $month
      year: $year
    ) {
      errorCode
      message
      employees {
        employee_id
        user_defined_empid
        photo_path
        employee_name
        department_name
        designation_name
      }
    }
  }
`;

export const LIST_REVIEWERS = gql`
  query listPmsReviewers(
    $departmentId: [Int]
    $designationId: [Int]
    $locationId: [Int]
    $empTypeId: [Int]
  ) {
    listPmsReviewers(
      departmentId: $departmentId
      designationId: $designationId
      locationId: $locationId
      empTypeId: $empTypeId
    ) {
      errorCode
      message
      reviewers {
        employee_id
        user_defined_empid
        employee_name
        department_name
        designation_name
      }
    }
  }
`;

export const LIST_GOALS_LIBRARY = gql`
  query listGoals {
    listGoals {
      errorCode
      message
      goalsList {
        goalId
        goalDescription
        status
        isGoalAssociated
      }
    }
  }
`;

export const RETRIEVE_EMPLOYEE_GOALS_RATINGS = gql`
  query retrieveEmployeeGoalsAndRating(
    $employeeId: Int!
    $year: Int!
    $month: Int!
    $isAdminView: Int!
  ) {
    retrieveEmployeeGoalsAndRating(
      employeeId: $employeeId
      year: $year
      month: $month
      isAdminView: $isAdminView
    ) {
      errorCode
      message
      employeeGoalsRatings {
        employeeId
        overallRating
        averageYearlyRating
        comments
        goalsAndRating
        lastReviewDate
      }
    }
  }
`;

export const RETRIEVE_RATINGS_SETTINGS = gql`
  query retrieveRatings {
    retrieveRatings {
      errorCode
      message
      getRatingDetails {
        maximumRating
        ratingDetails {
          Rating_Id
          Rating_Description
        }
      }
    }
  }
`;

export const RETRIEVE_PERFORMANCE_MONTHS_YEARS = gql`
  query listPerformanceYear {
    listPerformanceYear {
      errorCode
      message
      performanceYear
    }
  }
`;

export const RETRIEVE_MONTHLY_TEAM_RATINGS = gql`
  query retrieveTeamRatings($year: Int!, $month: Int!) {
    retrieveTeamRatings(year: $year, month: $month) {
      errorCode
      message
      teamRating {
        rating
        employeeCount
      }
    }
  }
`;

export const RETRIEVE_RATINGS_COUNT = gql`
  query retrieveTeamGoalsRatingsCount($month: Int!, $year: Int!) {
    retrieveTeamGoalsRatingsCount(month: $month, year: $year) {
      errorCode
      message
      teamGoalsRatingsCount {
        teamEmployeesCount
        goalsPublishedCount
        ratingsPublishedCount
        averageOverallRating
        monthYear
      }
    }
  }
`;

export const RETRIEVE_YEARLY_TEAM_RATINGS = gql`
  query retrieveTeamAverageMonthlyRatings($month: Int!, $year: Int!) {
    retrieveTeamAverageMonthlyRatings(month: $month, year: $year) {
      errorCode
      message
      teamMonthlyAverageRatings {
        performanceMonths
        averageMonthlyRatings
        performanceYear
      }
    }
  }
`;

export const RETRIEVE_INDIVIDUAL_YEARLY_RATINGS = gql`
  query retrieveEmployeeYearlyRating(
    $year: Int!
    $month: Int!
    $employeeId: Int!
  ) {
    retrieveEmployeeYearlyRating(
      year: $year
      month: $month
      employeeId: $employeeId
    ) {
      errorCode
      message
      employeeYearlyRatings {
        performanceMonthArray
        ratingArray
        performanceYear
      }
    }
  }
`;

export const RETRIEVE_NONCOMPLIANCE_REPORT = gql`
  query retrievePmsNonComplianceReport($month: Int!, $year: Int!) {
    retrievePmsNonComplianceReport(month: $month, year: $year) {
      errorCode
      message
      employees {
        userDefinedEmployeeId
        employeeName
        performanceMonthName
        performanceYear
        reviewerName
        dateOfJoin
        designationName
        departmentName
        locationName
        status
      }
    }
  }
`;
// ===============
// Mutation
// ===============

export const ADD_GOALS_IN_LIBRARY = gql`
  mutation addGoalsToLibrary($goalsList: [String]!) {
    addGoalsToLibrary(goalsList: $goalsList) {
      errorCode
      message
    }
  }
`;

export const ADD_EMPLOYEES_GOALS_AND_ACHIEVEMENT = gql`
  mutation addEmployeeGoalsAndAchievement(
    $employeeIds: [Int!]
    $reviewerId: Int!
    $goalIds: [Int!]
    $month: Int!
    $year: Int!
  ) {
    addEmployeeGoalsAndAchievement(
      employeeIds: $employeeIds
      reviewerId: $reviewerId
      goalIds: $goalIds
      month: $month
      year: $year
    ) {
      errorCode
      message
    }
  }
`;

export const UPDATE_EMPLOYEES_GOALS_AND_ACHIEVEMENT = gql`
  mutation updateEmployeeGoalsAndAchievement(
    $employeeId: Int!
    $reviewerId: Int!
    $overallRating: Int!
    $comments: String!
    $year: Int!
    $month: Int!
    $goalsAndRating: [goalsAndRatingInput]
    $goalIdArray: [Int]!
  ) {
    updateEmployeeGoalsAndAchievement(
      employeeId: $employeeId
      reviewerId: $reviewerId
      overallRating: $overallRating
      comments: $comments
      year: $year
      month: $month
      goalsAndRating: $goalsAndRating
      goalIdArray: $goalIdArray
    ) {
      errorCode
      message
    }
  }
`;

export const DELETE_EMPLOYEES_GOALS_AND_ACHIEVEMENT = gql`
  mutation deleteEmployeeGoalsAndAchievement($performanceAssessmentId: Int!) {
    deleteEmployeeGoalsAndAchievement(
      performanceAssessmentId: $performanceAssessmentId
    ) {
      errorCode
      message
    }
  }
`;

export const PUBLISH_GOALS = gql`
  mutation publishGoals(
    $performanceAssessmentId: [Int]!
    $month: Int!
    $year: Int!
  ) {
    publishGoals(
      performanceAssessmentId: $performanceAssessmentId
      month: $month
      year: $year
    ) {
      errorCode
      message
    }
  }
`;

export const PUBLISH_RATINGS = gql`
  mutation publishRatings(
    $performanceAssessmentId: [Int]!
    $month: Int!
    $year: Int!
  ) {
    publishRatings(
      performanceAssessmentId: $performanceAssessmentId
      month: $month
      year: $year
    ) {
      errorCode
      message
    }
  }
`;

export const UNPUBLISH_RATINGS_AND_GOALS = gql`
  mutation revertGoalsOrRatings(
    $action: String!
    $employeeId: [Int]!
    $month: Int!
    $year: Int!
  ) {
    revertGoalsOrRatings(
      action: $action
      employeeId: $employeeId
      month: $month
      year: $year
    ) {
      errorCode
      message
    }
  }
`;

export const UPDATE_GOAL = gql`
  mutation updateGoalsToLibrary($goalId: Int!, $goalDescription: String!) {
    updateGoalsToLibrary(goalId: $goalId, goalDescription: $goalDescription) {
      errorCode
      message
    }
  }
`;

export const DELETE_GOAL = gql`
  mutation deleteGoalsFromLibrary($goalId: Int!) {
    deleteGoalsFromLibrary(goalId: $goalId) {
      errorCode
      message
    }
  }
`;

export const UPDATE_GOAL_STATUS = gql`
  mutation updateGoalStatus($goalId: Int!, $goalStatus: String!) {
    updateGoalStatus(goalId: $goalId, goalStatus: $goalStatus) {
      errorCode
      message
    }
  }
`;
