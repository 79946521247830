import gql from "graphql-tag";

// ===============
// Queries
// ===============

export const GET_ALL_HOLIDAYS = gql`
  query listHolidaysInDashboard {
    listHolidaysInDashboard {
      errorCode
      message
      holidayDetails
    }
  }
`;
export const LIST_LEAVE_HISTORY = gql`
  query empLeaveHistory {
    empLeaveHistory {
      errorCode
      message
      leaveHistory
    }
  }
`;
export const LIST_TIMEOFF_QUERY = gql`
  query listLeavesAndShortTimeOff {
    listLeavesAndShortTimeOff {
      errorCode
      message
      leaveDetails
      shortTimeOffDetails
      compensatoryOffDetails
    }
  }
`;
export const CHECK_GEO_WORKPLACE_ENFORCE = gql`
  query checkGeoEnforceAndWorkPlace {
    checkGeoEnforceAndWorkPlace {
      errorCode
      message
      isGeoEnable
      isWorkPlaceEnable
      workPlaceDetails {
        workPlaceId
        workPlace
        imageUrl
        autoAttendance
      }
    }
  }
`;

export const GET_EMPLOYEE_DETAILS = gql`
  query getEmployeeDetails {
    getEmployeeDetails {
      errorCode
      message
      employeeDetails
    }
  }
`;

export const LIST_PAYROLL_ACTIONS = gql`
  query listPayrollActions {
    listPayrollActions {
      errorCode
      message
      payrollActionsList
    }
  }
`;
export const LIST_EMPLOYEES_ACTIONS = gql`
  query listEmployeeActions {
    listEmployeeActions {
      errorCode
      message
      employeeActionsList
    }
  }
`;

export const LIST_MY_UTILIZATION = gql`
  query getUtilization {
    getUtilization {
      errorCode
      message
      utilization
    }
  }
`;

export const GET_WORKED_DAYS = gql`
  query getWorkedDays {
    getLateAttendanceStatistics {
      errorCode
      message
      lateAttendanceStatistics
    }
  }
`;

export const GET_REMINDERS = gql`
  query {
    getReminders {
      errorCode
      message
      reminders {
        birthday
        workAnniversary
        awards
        leaveClosure
      }
    }
  }
`;

//Admin Dashbaord queries
export const GET_TEAM_UPDATES = gql`
  query {
    myTeamUpdates {
      errorCode
      message
      myTeamUpdates {
        probation
        newJoining
        offboarding
      }
    }
  }
`;

export const GET_WORKPLACE_COUNT = gql`
  query {
    getWorkPlaceCount {
      errorCode
      message
      workPlaceCount {
        office
        field
        workFromHome
        others
      }
    }
  }
`;
export const GET_EMPLOYEES_COUNT = gql`
  query {
    getEmployeeCount {
      errorCode
      message
      totalEmployeesCount
      activeEmployeesCount
    }
  }
`;
export const GET_ATTENDANCE_COUNT = gql`
  query {
    getAttendanceCount {
      errorCode
      message
      onTimeArrivals
      lateArrivals
    }
  }
`;

export const GET_LEAVE_DISTRIBUTION = gql`
  query {
    getLeaveDistribution {
      errorCode
      message
      totalLeaveCount
      leaveDistribution {
        leaveName
        leaveCount
        currentDateLeaveEmployeeDetails {
          user_defined_employee_id
          employee_name
        }
      }
    }
  }
`;

export const LIST_PROBATION_EMPLOYEES = gql`
  query listProbationEmployees($isTeamDashboard: Int!) {
    listProbationEmployees(isTeamDashboard: $isTeamDashboard) {
      errorCode
      message
      probationEmployeesDetails {
        employeeId
        employeeName
        userDefinedEmployeeId
        dateOfJoin
        probationDate
        probationDays
      }
    }
  }
`;

export const CHECK_ATTENDANCE_CONFIGURATION = gql`
  query checkAttendanceConfiguration($employeeId: Int) {
    checkAttendanceConfiguration(employeeId: $employeeId) {
      errorCode
      message
      attendanceConfigurationDetails {
        attendanceTypeId
        attendanceConfigurationName
        geoFencingEnabled
        facialRecognitionEnabled
        forceFencing
        forceFaicalRecognition
        centerPoint
        radius
        enableLivenessDetection
        noOfChallenges
      }
    }
  }
`;

export const FACIAL_RECOGNITION = gql`
  query faceVerification(
    $enableLivenessDetection: String
    $imageWithChallenges: [imageWithChallenges!]
  ) {
    faceVerification(
      enableLivenessDetection: $enableLivenessDetection
      imageWithChallenges: $imageWithChallenges
    ) {
      errorCode
      message
    }
  }
`;

export const CHECK_REGISTER_USER = gql`
  query checkRegisterUserAndGetRandomChallenge($noOfChallenges: Int!) {
    checkRegisterUserAndGetRandomChallenge(noOfChallenges: $noOfChallenges) {
      errorCode
      message
      challenges {
        challengeId
        challengeName
      }
    }
  }
`;

export const VALIDATE_WFH_PREAPPORVAL_REQUEST = gql`
  query validateWfhPreApproval(
    $employeeId: Int!
    $source: String!
    $attendanceDate: Date
    $attendanceDateTime: String
    $punchInDateTime: String
    $punchOutDateTime: String
    $punchType: String!
    $checkInCheckoutWorkPlace: String!
  ) {
    validateWfhPreApproval(
      employeeId: $employeeId
      source: $source
      attendanceDate: $attendanceDate
      attendanceDateTime: $attendanceDateTime
      punchInDateTime: $punchInDateTime
      punchOutDateTime: $punchOutDateTime
      punchType: $punchType
      checkInCheckoutWorkPlace: $checkInCheckoutWorkPlace
    ) {
      errorCode
      message
    }
  }
`;

export const GET_EXPIRED_DOCUMENT_LIST = gql`
  query retrieveDashboardExpiredDocsDetails($selfService: Int!) {
    retrieveDashboardExpiredDocsDetails(selfService: $selfService) {
      errorCode
      message
      ExpiredDocuments {
        getPassportDetails {
          Employee_Id
          expiryDate
          Expiry_Date
          Document_Name
          userDefinedEmpId
          Status
          Employee_Name
          Passport_No
          Issuing_Authority
          Issuing_Country
          issueDate
        }
        getLicenseDetails {
          Employee_Id
          expiryDate
          Expiry_Date
          Document_Name
          userDefinedEmpId
          Status
          Employee_Name
          Driving_License_No
          Issuing_Authority
          Issuing_Country
          Issuing_State
          Vehicle_Type
          issueDate
        }
        getAccredidationDetails {
          Employee_Id
          expiryDate
          Expiry_Date
          Document_Name
          userDefinedEmpId
          Status
          Employee_Name
          Identifier
          File_Name
          Accreditation_Category
          receivedDate
        }
      }
    }
  }
`;
export const LIST_WORK_PLACES = gql`
  query ListWorkPlaces($status: String) {
    listWorkPlaces(status: $status) {
      errorCode
      message
      workPlaces
      employeeWorkPlace
    }
  }
`;
// ===============
// Mutation
// ===============
export const UPDATE_PROBATION = gql`
  mutation updateProbationAndConfirmation(
    $employeeId: Int!
    $dateOfJoin: Date!
    $oldProbationDate: Date!
    $newProbationDate: Date!
    $confirmationDate: Date!
  ) {
    updateProbationAndConfirmation(
      employeeId: $employeeId
      dateOfJoin: $dateOfJoin
      oldProbationDate: $oldProbationDate
      newProbationDate: $newProbationDate
      confirmationDate: $confirmationDate
    ) {
      errorCode
      message
    }
  }
`;
