import state from "./moduleCoreHRState.js";
import mutations from "./moduleCoreHRMutations.js";
import actions from "./moduleCoreHRActions.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
};
