export default {
  UPDATE_FILTER_APPLIED(state, value) {
    state.isFilterApplied = value;
  },
  UPDATE_REPORT_TYPE(state, value) {
    state.reportType = value;
  },
  UPDATE_REPORT_EMP_ID(state, value) {
    state.chosenReportsEmpId = value;
  },
  UPDATE_REPORT_EMP_NAME(state, value) {
    state.chosenReportEmpName = value;
  },
  UPDATE_EMP_REPORT_DATA(state, value) {
    state.reportActivityData = value;
  },
  // update the list of employees report data
  UPDATE_EMP_LIST_REPORTS(state, value) {
    state.reportsEmpList = value;
  },
  // update My Team report data
  UPDATE_MY_TEAM_REPORTS(state, value) {
    state.reportsMyTeamData = value;
  },
  // update onboarding roles list
  UPDATE_EMP_ONBOARD_ROLE_LIST(state, value) {
    state.onboardingRoleList = value;
  },
  // update bulk manual/import formatted invite data
  UPDATE_BULK_MANUAL_INVITE_DATA(state, value) {
    state.bulkManualInviteData = value;
  },

  // available team members in productivity monitoring
  UPDATE_EMP_MONITOR_MEMBERS(state, value) {
    state.empMonitoringAllMembersList = value;
  },

  UPDATE_ORG_TIMEZONE_DETAILS(state, value) {
    state.orgTimezoneDetails = value;
  },

  UPDATE_EMPLOYEE_WORK_SCHEDULE_DETAILS(state, value) {
    state.employeeWorkScheduleDetails = value;
  },

  // set the member role in productivity monitoring (Admin,Manager,Team Member - EMPMONITORDASHBOARD , Admin, Member - HRMSDASHBOARD)
  UPDATE_MEMBER_ROLE(state, value) {
    state.memberRole = value;
  },

  UPDATE_ACCOUNT_STATUS(state, value) {
    state.memberAccountStatus = value;
  },

  // Update the members list for EMPLOYEEMONITORINGDASHBOARD and consent based members list
  UPDATE_EMPMONITOR_CONSENT_MEMBERS(state, members) {
    state.empMonitorConsentMembers = members;
  },

  // Update the members list for EMPLOYEEMONITORINGDASHBOARD and stealth based members list
  UPDATE_EMPMONITOR_STEALTH_MEMBERS(state, members) {
    state.empMonitorStealthMembers = members;
  },

  // to update the member data which is currently opened for view or edit
  UPDATE_CURRENT_MEMBER_DATA(state, value) {
    state.currentlyViewingMemberData = value;
  },

  // if the invite screen is triggered from Activity Tracker, to know the source of trigger
  UPDATE_INVITE_FROM_SOURCE(state, value) {
    state.inviteFromSource = value;
  },

  // when the redirection is made from activity dashboard
  UPDATE_REDIRECT_FROM_ACTIVITY_DASHBOARD(state, value) {
    state.redirectFromActivityDashboard = value;
  },

  // org and emp level activity goal ranges
  UPDATE_ORG_EMP_ACTIVITY_GOAL_RANGE(state, value) {
    state.orgEmpActivityGoalRange = value;
  },

  // update the app tracking mode - stealth/consent based
  UPDATE_APP_TRACKING_MODE(state, value) {
    state.appTrackingMode = value;
  },

  // update the privacy mode(0/1) of the organization for productivity monitoring
  UPDATE_PRIVACY_MODE_FLAG(state, value) {
    state.privacyMode = value;
  },

  // update the bulk import type - onboarding/team-member
  UPDATE_BULK_IMPORT_USED_FOR(state, value) {
    state.bulkImportUsedFor = value;
  },

  // update the assets list
  UPDATE_ASSETS(state, value) {
    state.assets = value;
  },

  // update the hrms employees list
  UPDATE_HRMS_EMPLOYEES(state, value) {
    state.hrmsEmployees = value;
  },

  // activity tracker desktop app download data
  UPDATE_DESKTOP_APP_DOWNLOAD_DATA(state, value) {
    state.desktopAppDownloadData = value;
  },
};
