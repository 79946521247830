import state from "./moduleRosterManagementState.js";
import mutations from "./moduleRosterManagementMutations.js";
import actions from "./moduleRosterManagementActions.js";
import getters from "./moduleRosterManagementGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};
