import state from "./moduleBillingState";
import mutations from "./moduleBillingMutations";
import actions from "./moduleBillingActions";

export default {
  namespaced: true,
  actions: actions,
  state: state,
  mutations: mutations,
};
