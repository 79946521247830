// import extend function - to add custom rules in vee-validate
import { extend } from "vee-validate";
import { changeTimeToSeconds, checkTimeBetweenTwoTimes } from "./helper";

// import the already available rules
import {
  required,
  max,
  email,
  min,
  min_value,
  numeric,
  max_value,
} from "vee-validate/dist/rules";

// ---- existing rules ----

// required field validation
export const requiredField = extend("required", {
  ...required,
  message: "{_field_} is required.",
});

export const emailIdField = extend("email", {
  ...email,
  message: "Please provide a valid email address.",
});

export const numericField = extend("numeric", {
  ...numeric,
  message: "Only numerics are allowed.",
});

export const maxVal = extend("max", max);

export const minVal = extend("min", min);

export const minInteger = extend("min_value", min_value);

export const maxInteger = extend("max_value", max_value);

/* ---- custom rules ---- */

// maximum value validation with customizing message
export const maxIntegerValidation = extend("maximum", (value, args) => {
  if (parseInt(value, 10) <= parseInt(args[0], 10)) {
    return true;
  }
  return args && args[1] // when custom msg is available we can present that
    ? args[1] // custom msg
    : "The value should be less than or equal to " + args; // default msg
});

// minimum value validation with customizing message
export const minIntegerValidation = extend("minimum", (value, args) => {
  if (parseInt(value, 10) >= parseInt(args[0], 10)) {
    return true;
  }
  return "The value should be greater than or equal to " + args;
});

export const notSameValidation = extend("notSame", (value, args) => {
  if (value !== args[0]) {
    return true;
  }
  return args[1];
});

// maximum float value validation with customizing message
export const maxFloatValidation = extend("maxFloat", (value, args) => {
  // args[0]: maximum number to be compared with value
  // args[1]: custom message
  if (parseFloat(value) <= parseFloat(args[0])) {
    return true;
  }
  return args && args[1] // when custom msg is available we can present that
    ? args[1] // custom msg
    : "The value should be less than or equal to " + args; // default msg
});

// minimum float value validation with customizing message
export const minFloatValidation = extend("minFloat", (value, args) => {
  // args[0]: minimum number to be compared with value
  // args[1]: custom message
  if (parseFloat(value) >= parseFloat(args[0])) {
    return true;
  }
  let argsAsString = parseInt(args, 10) === 0 ? "zero" : args;
  return args && args[1] // when custom msg is available we can present that
    ? args[1] // custom msg
    : "The value should be greater than or equal to " + argsAsString; // default msg
});

// max length validation
export const maxLengthValidation = extend("max", (value, args) => {
  if (value.length <= args.length) {
    return true;
  }
  return (
    "The value should not exceed more than " + args.length + " characters."
  );
});

// min length validation
export const minLengthValidation = extend("min", (value, args) => {
  if (value.length >= args.length) {
    return true;
  }
  return "The value should be more than " + args.length + " characters.";
});

// alphabets, space and dots are allowed
export const alphaSpaceDotValidation = extend("alphaSpaceDot", (value) => {
  if (/^[a-zA-Z.\s]+$/.test(value)) {
    return true;
  }
  return "Only alphabets, space and dots are allowed.";
});

// number with two decimal
export const numberWithTwoDecimalValidation = extend(
  "numberWithTwoDecimal",
  (value) => {
    if (/^\d{1,13}(\.\d{0,2})?$/.test(value)) {
      return true;
    }
    return "Numeric value with maximum two decimal places are allowed.";
  }
);

// alphabets, space, hyphen, underscore and dots are allowed
export const alphaNumSpaceDotHyphenQuotesValidation = extend(
  "alphaNumSpaceDotHyphenQuotes",
  (value) => {
    if (/^[a-zA-Z0-9'.\-_\s]+$/i.test(value)) {
      return true;
    }
    return "Only alphanumeric, spaces and symbols( ' . - _ ) are allowed.";
  }
);

// alphanumeric, spaces and symbols(+ , / . # & : () ' - ) are allowed
export const commentValidation = extend("vComment", (value) => {
  if (/^[\w\.\,\#\+\&\/\-\(\)\:\'\ ]*$/.test(value)) {
    return true;
  }
  return "Only alphanumeric, spaces and symbols(+ , / . # & : () ' - ) are allowed.";
});

// check the passed argument is greater than the time value
export const greaterThanTimeValidation = extend(
  "greaterThanTime",
  (value, args) => {
    let valueInSecs = changeTimeToSeconds(value);
    let argsInSecs = changeTimeToSeconds(args[0]);
    if (valueInSecs > argsInSecs) {
      return true;
    }
    return args[1]; // custom message;
  }
);

// check the passed argument is less than the time value
export const lessThanTimeValidation = extend("lessThanTime", (value, args) => {
  let valueInSecs = changeTimeToSeconds(value);
  let argsInSecs = changeTimeToSeconds(args[0]);
  if (valueInSecs < argsInSecs) {
    return true;
  }
  return args[1]; // custom message;
});

// check the passed value is between passed argument times
export const betweenTimeValidation = extend("betweenTime", (value, args) => {
  let isBetween = checkTimeBetweenTwoTimes(value, args[0], args[1]);
  if (isBetween) {
    return true;
  }
  return args[2]; // custom message;
});

// alphanumeric, spaces and symbols(, () )  are allowed
export const alphaNumSpaceWithTwoSymbolValidation = extend(
  "alphaNumSpaceWithTwoSymbol",
  (value) => {
    if (/^[\w\(\)\,\ ]*$/.test(value)) {
      return true;
    }
    return "Only alphanumeric,spaces and symbols(& - ) are allowed";
  }
);

//Only alphanumeric, space and symbols ( . , # + & / - ( ) : ` ' ? ) are allowed
export const alphaNumSpCDotHySlashValidation = extend(
  "alphaNumSpCDotHySlashValidation",
  (value) => {
    if (/^[a-zA-Z0-9\.\,\#\+\&\/\-\(\)\:\`\'\?\ ]*$/.test(value)) {
      return true;
    }
    return "Only alphanumeric, space and symbols ( . , # + & / - ( ) : ` ' ? ) are allowed";
  }
);

//numbers space and hyphen
export const numSpaceWithHyphenValidation = extend(
  "numSpaceHyphen",
  (value) => {
    if (/^(?=.*[0-9])[- 0-9]+$/.test(value)) {
      return true;
    }
    return "Only numeric, spaces and hyphen are allowed.";
  }
);

// mobile & telephone number validation
export const mobileNoValidation = extend("mobileNo", (value) => {
  if (
    /^([\+][0-9]{1,3}[ \.\-])?([\(]{1}[0-9]{2,6}[\)])?([0-9 \+\.\-\/]{5,15})((x|ext|extension)[ ]?[0-9]{1,4})?$/.test(
      value
    )
  ) {
    return true;
  }
  return "Please provide a valid mobile number.";
});

// alphanumeric, spaces and symbols(- , ( ) )  are allowed
export const alphaNumSpaceWithFourSymbolValidation = extend(
  "alphaNumSpaceWithFourSymbol",
  (value) => {
    if (/^[\w\-\(\)\,\ ]*$/.test(value)) {
      return true;
    }
    return "Only alphanumeric, spaces and symbols(- , ( ) )  are allowed.";
  }
);

// alphabets, space are allowed
export const alphaNumHySpaceValidation = extend("alphaNumHySpace", (value) => {
  if (/^[\w\-\ ]*$/.test(value)) {
    return true;
  }
  return "Only alphanumeric, space and hyphen are allowed.";
});

// alphanumeric, spaces and symbols(& , () . )  are allowed.
export const onlyLetterSpDotAmpBracketValidation = extend(
  "onlyLetterSpDotAmpBracket",
  (value) => {
    if (/^[\w\.\&\,\(\)\ ]+$/.test(value)) {
      return true;
    }
    return "Only alphanumeric, spaces and symbols(& , () . )  are allowed.";
  }
);

// alphanumeric, spaces and symbols(& , () . )  are allowed.
export const designationValidation = extend("vDesignation", (value) => {
  if (/^[a-zA-Z0-9\|\\_\\.\,\#\+\&\/\-\(\)\:\`\'\ ]*$/.test(value)) {
    return true;
  }
  return "Only alphanumeric, spaces and symbols(+ , / . # & : () ' - ` | _) are allowed.";
});

export const titleValidation = extend("vTitle", (value) => {
  if (/^[\w\.\,\#\+\&\/\-\(\)\:\`\'\ ]*$/.test(value)) {
    return true;
  }
  return "Only alphanumeric, spaces and symbols(+ , / . # & : () ' - ` ) are allowed.";
});

// letters, numbers, spaces, and certain special characters are allowed
export const descriptionValidation = extend(
  "descriptionValidation",
  (value) => {
    if (/^[\w\s\d.,!@%^&*-_+=;:'"<>?/\()|`~’\n]+$/.test(value)) {
      return true;
    }
    return `Only letters, numbers, spaces, and special characters(. , ! @ % ^ & * - _ + = ; : ' " < > ( ) ?) are allowed.`;
  }
);

export const domainNameValidation = extend("vDomainName", (value) => {
  const domainPattern =
    /^([a-zA-Z0-9](?:[a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;
  if (domainPattern.test(value)) {
    return true;
  }
  return "Please enter valid domain name";
});

export const alphaNumSpaceWithSymbolsValidation = extend(
  "alphaNumSpaceWithSymbols",
  (value) => {
    if (/^[a-zA-Z0-9\s&+,/#:()'.-]/.test(value)) {
      return true;
    }
    return "Only alphanumeric, spaces and symbols( + , / . # & : ( ) ' - ) are allowed.";
  }
);

// alphabets and numerics are allowed
export const alphaNumericValidation = extend("alphaNumeric", (value) => {
  if (/^[a-zA-Z0-9]*$/.test(value)) {
    return true;
  }
  return "Only alphanumeric characters are allowed.";
});

export const bsbCodeValidation = extend("bsbCode", (value) => {
  if (/\d{3}-?\d{3}/.test(value)) {
    return true;
  }
  return "Please provide valid BSB code";
});

//IFSC validation
export const ifscValidation = extend("ifscCode", (value) => {
  if (/^[A-Z]{4}[0][A-Z0-9]{6}$/.test(value)) {
    return true;
  }
  return "Please provide valid IFSC code";
});
