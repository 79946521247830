export default {
  UPDATE_LEAVES_LIST(state, lists) {
    state.leavesLists = lists;
  },
  UPDATE_SHORT_TIMEOFF_LISTS(state, lists) {
    state.shortTimeOffLists = lists;
  },
  UPDATE_COMPOFF_LISTS(state, lists) {
    state.compOffLists = lists;
  },
  UPDATE_GEO_ENFORCE_LABEL(state, geoEnforced) {
    state.isGeoEnforced = geoEnforced;
  },
  UPDATE_WORKPLACE_ENABLE(state, workplace) {
    if (Object.keys(workplace).length > 0) {
      state.workPlaceData = workplace;
    } else {
      state.workPlaceData = {
        isWorkPlaceEnabled: false,
        workPlaceDetails: [],
      };
    }
  },
  UPDATE_EMPLOYEES_ACTIONS_LIST(state, list) {
    state.employeesActionsList = list;
  },
  UPDATE_PAYROLL_ACTIONS_LIST(state, list) {
    state.payrollActionsList = list;
  },
  UPDATE_LEAVE_UTILIZATION(state, leaveList) {
    state.employeeLeaveUtilization = leaveList;
  },
  UPDATE_MISSED_CHECKOUT_ATTENDANCE(state, attendanceList) {
    state.missedCheckOutAttendance = attendanceList;
  },
  UPDATE_MISSED_NO_ATTENDANCE(state, attendanceList) {
    state.missedNoAttendance = attendanceList;
    let currentDateRecord = [];
    currentDateRecord = attendanceList.filter(
      (attendance) => attendance.iscurrentdate === 1
    );
    //if have current date missed check in attendance then refresh no attendance on dashboard check in success
    if (currentDateRecord.length > 0) {
      state.isCurrentRecordAvailable = true;
    }
  },
  UPDATE_REGULARIZATION_LIMIT_DETAILS(
    state,
    regularizationRequestLimitDetails
  ) {
    state.regularizationRequestLimitDetails = regularizationRequestLimitDetails;
  },
  UPDATE_NO_ATTENDANCE_LOADING(state, value) {
    state.isNoAttendanceLoading = value;
  },
  UPDATE_MISSED_ATTENDANCE_LOADING(state, value) {
    state.isMissedAttendanceLoading = value;
  },

  //update user reminders(birthday,awards,work-anniversary) to store
  UPDATE_REMINDERS(state, reminders) {
    state.reminders = reminders;
  },

  REQUIRE_TO_UPDATE_ATTENDANCE(state, value) {
    state.needToUpdateAttendance = value;
  },

  UPDATE_WATCH_POSITION(state, id) {
    state.watchPositionId = id;
  },

  // Admin dashboard Mutations
  // =======================

  //my team updates data (probation,new joiners,Off-boarding) to store
  MY_TEAM_UPDATES(state, updates) {
    state.myTeamUpdates = updates;
  },

  //update employee leave count data. leaveData hold {leaveDistribution and totalLeaveCount}
  LEAVE_DISTRIBUTION_DATA(state, leaveData) {
    state.leaveDistributionData = leaveData;
  },
  UPDATE_TEAM_AVAILABILITY(state, empAvailability) {
    state.teamAvailability = empAvailability;
  },
};
