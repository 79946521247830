import gql from "graphql-tag";

// ===============
// Queries
// ===============
export const GET_DOMAIN_DETAILS_QUERY = gql`
  query getDomainDetails {
    getDomainDetails {
      errorCode
      message
      domainDetails {
        supportEmail
        copyRight
        supportLink
        termsLink
        privacyPolicyLink
        chatBot
        paymentPortalSiteName
      }
      licensingDetails
    }
  }
`;
export const GET_ORG_USER_DETAILS_QUERY = gql`
  query getOrganizationUserDetails {
    getOrganizationUserDetails {
      errorCode
      message
      organizationName
      orgDateFormat
      productIconPath
      assessmentYear
      paycycle
      employeeId
      monitoringType
      privacyMode
      disableLogout
      camuBaseUrl
      userDetails {
        employeeId
        userDefinedEmployeeId
        employeeFullName
        employeeFirstName
        employeePhotoPath
        employeeEmail
      }
    }
  }
`;
export const GET_SIDEBAR_MENU_QUERY = gql`
  query listModulesAndForms {
    listModulesAndForms {
      errorCode
      message
      modulesAndForms {
        moduleList {
          moduleId
          moduleName
          formList {
            formId
            formName
            customFormName
            url
          }
        }
        formAccessList
        formIdAccessList
      }
    }
  }
`;
export const LIST_ANNOUNCEMENT = gql`
  query listAnnouncements {
    listAnnouncements {
      errorCode
      message
      announcementDetails {
        announcementId
        title
        embedUrl
        flashContent
        announcementType
        announcementText
      }
      announcementCount
    }
  }
`;
export const LIST_NOTIFICATIONS = gql`
  query listNotificationsInDashboard {
    listNotificationsInDashboard {
      errorCode
      message
      notificationList
      notificationCount
      leaveWorkflowEnabled
    }
  }
`;

export const GET_EMPLOYEE_GROUP_IDS = gql`
  query getEmployeeGroupIds($employeeId: Int!) {
    getEmployeeGroupIds(employeeId: $employeeId) {
      errorCode
      message
      GroupIdArray
    }
  }
`;

export const GET_SERVICE_PROVIDER_EMPLOYEES = gql`
  query getServiceProviderEmployees($employeeId: Int!) {
    getServiceProviderEmployees(employeeId: $employeeId) {
      errorCode
      message
      employeeId
    }
  }
`;

export const GET_SUBSCRIBED_PLAN_TYPE = gql`
  query getOrganizationSubscribedPlan {
    getOrganizationSubscribedPlan {
      errorCode
      message
      subscribedDashboard
    }
  }
`;
//Modules request access query
export const REQUEST_ACCESS = gql`
  query requestRights {
    requestRights {
      errorCode
      message
    }
  }
`;
export const SEND_COUPON_EMAIL_REQUEST = gql`
  query sendEmailToClaimCoupon($organizationName: String!) {
    sendEmailToClaimCoupon(organizationName: $organizationName) {
      errorCode
      message
    }
  }
`;
export const ENCRYPT_REFRESH_TOKEN = gql`
  query encryptRefreshToken($refreshToken: String!) {
    encryptRefreshToken(refreshToken: $refreshToken) {
      errorCode
      message
      refreshToken
    }
  }
`;
export const LOGOUT_ENTOMO = gql`
  query logoutFromEntomo($xAuthToken: String!) {
    logoutFromEntomo(xAuthToken: $xAuthToken) {
      errorCode
      message
    }
  }
`;
