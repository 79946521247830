import state from "./moduleBenefitsState.js";
import mutations from "./moduleBenefitsMutations.js";
import actions from "./moduleBenefitsActions.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
};
