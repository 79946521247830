<template>
  <div>
    <div
      v-if="windowWidth > 960 || (windowWidth >= 1280 && centerTab)"
      :class="$store.state.modalOpened ? 'top-bar-modal-opened' : 'top-bar'"
      flat
      height="4em"
    >
      <v-toolbar class="active_tab_bg" height="58">
        <v-row class="mr-0 d-flex align-center">
          <v-col md="7" class="main-tab pb-0">
            <v-tabs v-model="currentTabItem" class="active_tab_bg form-tabs">
              <v-tab
                v-for="(tab, i) in tabsList"
                :key="i + '-tab'"
                :href="'#tab-' + i"
                class="font-weight-bold"
                style="text-transform: capitalize !important"
                @click="$emit('tab-clicked', tab)"
              >
                {{ tab }}
                <div
                  v-if="showNewLabel(tab)"
                  class="d-flex justify-center align-center hrapp-module-new-label"
                >
                  {{ tabName }}
                </div>
              </v-tab>
            </v-tabs>
          </v-col>
          <v-col class="d-flex justify-end pa-0">
            <slot name="topBarContent"></slot>
          </v-col>
        </v-row>
      </v-toolbar>
    </div>

    <!-- mobile topbar design -->
    <div
      v-if="windowWidth <= 960 || (windowWidth < 1280 && centerTab)"
      :class="$store.state.modalOpened ? 'top-bar-modal-opened' : 'top-bar'"
      flat
      height="4em"
    >
      <v-toolbar class="active_tab_bg">
        <v-tabs
          v-model="currentTabItem"
          centered
          class="active_tab_bg"
          show-arrows
        >
          <v-tab
            v-for="(tab, i) in tabsList"
            :key="i + '-tab'"
            :href="'#tab-' + i"
            class="active_tab_bg font-weight-bold"
            style="text-transform: capitalize !important"
            @click="$emit('tab-clicked', tab)"
          >
            {{ tab }}
          </v-tab>
        </v-tabs>
      </v-toolbar>
      <v-bottom-navigation
        v-if="showBottomSheet"
        fixed
        color="teal"
        elevation="15"
        background-color="white"
        class="align-center"
      >
        <slot name="topBarContent"></slot>
      </v-bottom-navigation>
    </div>
  </div>
</template>
<script>
// import helper function
import { checkValExistsInList } from "@/helper";

export default {
  name: "AppTopBarTab",

  props: {
    // have to change this as Array and check
    tabsList: {
      type: Array,
      required: true,
    },
    currentTab: {
      type: String,
      default: "tab-0",
    },
    showBottomSheet: {
      type: Boolean,
      default: true,
    },
    tabName: {
      type: String,
      default: "New",
    },
    centerTab: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    currentTabItem: "",
    // forms for which new label has to be shown
    newForms: [],
  }),

  computed: {
    // current window size
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    showNewLabel() {
      return (tabName) => {
        let isNewForm = checkValExistsInList(this.newForms, tabName);
        return isNewForm;
      };
    },
  },

  watch: {
    currentTabItem(val) {
      this.$store.commit("UPDATE_CURRENT_TAB", val);
    },
    //on current active tab change update the currentTabItem value to highlight the updated active tab
    currentTab(val) {
      this.currentTabItem = val;
    },
  },

  created() {
    this.currentTabItem = this.currentTab;
  },
};
</script>
<style scoped lang="css">
.active_tab_bg {
  background: #f9f9f9 !important;
}
.main-tab {
  padding: 0em 2.5em;
}
@media screen and (max-width: 1264px) {
  .main-tab {
    padding-left: oem 1.2em !important;
  }
}
@media screen and (max-width: 500px) {
  .main-tab {
    padding-left: 0em 0.5em !important;
  }
}
.top-bar {
  height: 5em;
  position: fixed !important;
  width: 100% !important;
  z-index: 4;
}
.top-bar-modal-opened {
  height: 5em;
  width: 100% !important;
  z-index: 1;
}
@media screen and (max-width: 1263px) {
  .top-bar {
    margin-left: 0px !important;
  }
}

.hrapp-module-new-label {
  width: 45px;
  background: var(--v-deeporange-lighten1);
  border-radius: 25px;
  opacity: 1;
  z-index: 5;
  height: 18px;
  font-family: inherit;
  font-size: 0.9em;
  color: #fff !important;
  margin-top: -10px;
  margin-left: -1px;
}
</style>
