import gql from "graphql-tag";
// ===============
// Queries
// ===============
export const LIST_OVERTIME_CLAIM = gql`
  query listOvertimeDetails(
    $filterMonth: Int!
    $filterYear: Int!
    $status: [String]
    $startDate: Date
    $endDate: Date
  ) {
    listOvertimeDetails(
      filterMonth: $filterMonth
      filterYear: $filterYear
      status: $status
      startDate: $startDate
      endDate: $endDate
    ) {
      errorCode
      message
      overtimeDetails
      salaryStartDate
      salaryEndDate
    }
  }
`;
export const LIST_OVERTIME_CONFIGURATION_DETAILS = gql`
  query getOTConfigurationDetails {
    getOTConfigurationDetails {
      errorCode
      message
      configurationData {
        minAndMaxYears
        overtimeSettings {
          Overtime_Part_Of_Payroll
          Overtime_Payment_Method
          Overtime_Flat_OR_Slab_Rate
          Comp_Off_Applicable_For_Overtime
          Shift_Allowance_Applicable_For_Overtime
        }
      }
    }
  }
`;
export const LIST_OVERTIME_EMPLOYEES = gql`
  query listOvertimeEmployees(
    $departmentId: [Int]
    $designationId: [Int]
    $locationId: [Int]
    $workScheduleId: [Int]
    $empTypeId: [Int]
  ) {
    listOvertimeEmployees(
      departmentId: $departmentId
      designationId: $designationId
      locationId: $locationId
      workScheduleId: $workScheduleId
      empTypeId: $empTypeId
    ) {
      errorCode
      message
      employeeDetails {
        employee_id
        photo_path
        designation_name
        department_name
        user_defined_empid
        employee_name
        manager_id
        doj
        exitDate
      }
    }
  }
`;
export const CALCULATE_OVERTIME_WAGES = gql`
  query overtimeWageCalculation(
    $employeeId: Int!
    $otStartTime: Date!
    $otEndTime: Date!
    $overtimeClaimId: Int!
    $action: String!
  ) {
    overtimeWageCalculation(
      employeeId: $employeeId
      otStartTime: $otStartTime
      otEndTime: $otEndTime
      overtimeClaimId: $overtimeClaimId
      action: $action
    ) {
      totalHours
      overtimeWages
      totalHoursInFormat
    }
  }
`;

export const LIST_OVERTIME_PREREQUISITES = gql`
  query getOvertimePrerequisites($employeeId: Int!, $otStartTime: Date!) {
    getOvertimePrerequisites(
      employeeId: $employeeId
      otStartTime: $otStartTime
    ) {
      errorCode
      message
      otPreRequisites
    }
  }
`;
export const CALCULATE_COMPOFF_BALANCE = gql`
  query calculateOvertimeCompOffBalance(
    $employeeId: Int!
    $otStartTime: Date!
    $otEndTime: Date!
  ) {
    calculateOvertimeCompOffBalance(
      employeeId: $employeeId
      otStartTime: $otStartTime
      otEndTime: $otEndTime
    ) {
      errorCode
      message
      compensatoryOffBalance
    }
  }
`;
export const LIST_OVERTIME_SHIFT_TYPES = gql`
  query listShiftAllowanceShiftType(
    $employeeId: Int!
    $otStartTime: Date!
    $otEndTime: Date!
  ) {
    listShiftAllowanceShiftType(
      employeeId: $employeeId
      otStartTime: $otStartTime
      otEndTime: $otEndTime
    ) {
      errorCode
      message
      shiftAllowanceShiftTypeList {
        WorkSchedule_Id
        Title
      }
    }
  }
`;
export const CALCULATE_SHIFT_ALLOWANCE = gql`
  query calculateOvertimeShiftAllowance(
    $employeeId: Int!
    $otStartTime: Date!
    $otEndTime: Date!
    $workScheduleId: Int!
  ) {
    calculateOvertimeShiftAllowance(
      employeeId: $employeeId
      otStartTime: $otStartTime
      otEndTime: $otEndTime
      workScheduleId: $workScheduleId
    ) {
      errorCode
      message
      shiftAllowance
    }
  }
`;
// ===============
// Mutation
// ===============
export const DELETE_OVERTIME_CLAIM = gql`
  mutation deleteOvertimeDetails($overtimeClaimId: [Int]!) {
    deleteOvertimeDetails(overtimeClaimId: $overtimeClaimId) {
      errorCode
      message
    }
  }
`;
export const ADD_OVERTIME_CLAIM = gql`
  mutation addOvertimeDetails(
    $employeeId: Int!
    $otStartTime: Date!
    $otEndTime: Date!
    $status: String!
    $reason: String
    $shiftAllowanceAppliedWorkScheduleId: Int!
    $filterMonth: Int!
    $filterYear: Int!
  ) {
    addOvertimeDetails(
      employeeId: $employeeId
      otStartTime: $otStartTime
      otEndTime: $otEndTime
      status: $status
      reason: $reason
      shiftAllowanceAppliedWorkScheduleId: $shiftAllowanceAppliedWorkScheduleId
      filterMonth: $filterMonth
      filterYear: $filterYear
    ) {
      errorCode
      message
    }
  }
`;
export const UPDATE_OVERTIME_CLAIM = gql`
  mutation updateOvertimeDetails(
    $employeeId: Int!
    $overtimeClaimId: Int!
    $otStartTime: Date!
    $otEndTime: Date!
    $status: String!
    $reason: String
    $shiftAllowanceAppliedWorkScheduleId: Int!
    $filterMonth: Int!
    $filterYear: Int!
  ) {
    updateOvertimeDetails(
      employeeId: $employeeId
      overtimeClaimId: $overtimeClaimId
      otStartTime: $otStartTime
      otEndTime: $otEndTime
      status: $status
      reason: $reason
      shiftAllowanceAppliedWorkScheduleId: $shiftAllowanceAppliedWorkScheduleId
      filterMonth: $filterMonth
      filterYear: $filterYear
    ) {
      errorCode
      message
    }
  }
`;
export const UPDATE_OVERTIME_APPROVAL_STATUS = gql`
  mutation updateOvertimeDetailsStatus(
    $newApprovalStatus: String!
    $overtimeClaimIds: [Int]!
    $oneApprovalRecordComment: String!
    $filterMonth: Int!
    $filterYear: Int!
  ) {
    updateOvertimeDetailsStatus(
      newApprovalStatus: $newApprovalStatus
      overtimeClaimIds: $overtimeClaimIds
      oneApprovalRecordComment: $oneApprovalRecordComment
      filterMonth: $filterMonth
      filterYear: $filterYear
    ) {
      errorCode
      message
    }
  }
`;
export const UPDATE_OVERTIME_STATUS_AS_COMPLETED = gql`
  mutation updateOvertimeClaimCompletedStatus(
    $oneApprovalRecordComment: String!
    $overtimeClaimIds: [Int]!
    $filterMonth: Int!
    $filterYear: Int!
  ) {
    updateOvertimeClaimCompletedStatus(
      overtimeClaimIds: $overtimeClaimIds
      oneApprovalRecordComment: $oneApprovalRecordComment
      filterMonth: $filterMonth
      filterYear: $filterYear
    ) {
      errorCode
      message
    }
  }
`;
