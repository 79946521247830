import { createProvider } from "../../apolloConfiguration";
import { RETRIEVE_ROUND_OFF_SETTINGS } from "@/graphql/settings/payrollSettingsQueries";
import { DELETE_TEMPLATE } from "@/graphql/payroll/salaryTemplate";
import {
  CALCULATE_COMPOFF_BALANCE,
  CALCULATE_SHIFT_ALLOWANCE,
} from "@/graphql/payroll/overtimeClaim";
// import helper function
import { getErrorCodes, handleNetworkErrors } from "@/helper";
import moment from "moment";
import Vue from "vue";

export default {
  // action to fetch salary dates based on passed input month
  getSalaryDay({ rootGetters, dispatch }, payload) {
    let baseUrl = rootGetters.baseUrl;
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(baseUrl + "payroll/salary-payslip/get-salary-day", {
          salaryMonthYear: payload.salaryMonthYear,
        })
        .then((result) => {
          let { salaryDates } = result.data;
          if (salaryDates && Object.keys(salaryDates).length > 0) {
            resolve(salaryDates);
          } else {
            resolve("");
          }
        })
        .catch((salaryDayError) => {
          //on session expire error we clear session and redirect to auth
          if (salaryDayError.status === 200) {
            dispatch("clearUserLock", null, { root: true });
          }
          reject(salaryDayError);
        });
    });
  },
  // action to fetch the paycycle details for the employee next payslip month
  getLastPayslipMonth({ rootGetters, dispatch }, payload) {
    let baseUrl = rootGetters.baseUrl;
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(baseUrl + "payroll/salary-payslip/get-last-payslip-month", {
          employeeId: payload.employeeId,
          formName: "additionalWageClaim",
        })
        .then((result) => {
          let payslipMonthsData = result.data;
          if (payslipMonthsData.success) {
            const { salaryDates } = payslipMonthsData;
            if (salaryDates && Object.keys(salaryDates).length > 0) {
              let salaryStartDate = moment(salaryDates.salaryDate),
                lastSalaryDate = moment(payload.lastSalaryDate);
              // payslip is not generated, if the filter month - last salary date is greater than or equal to salary start date
              if (lastSalaryDate.isSameOrAfter(salaryStartDate)) {
                resolve("payslipNotGenerated");
              } else resolve("payslipGenerated");
            }
          } else {
            reject("error");
          }
        })
        .catch((payslipMonthsError) => {
          //on session expire error we clear session and redirect to auth
          if (payslipMonthsError.status === 200) {
            dispatch("clearUserLock", null, { root: true });
          }
          reject("error");
        });
    });
  },
  //action to calculated compoff balance in additional wage claim
  calculateCompOffBalance(state, payload) {
    const {
      clients: { apolloClientC },
    } = createProvider();
    return new Promise((resolve, reject) => {
      apolloClientC
        .query({
          query: CALCULATE_COMPOFF_BALANCE,
          variables: {
            employeeId: parseInt(payload.employeeId, 10),
            otStartTime: payload.otStartTime,
            otEndTime: payload.otEndTime,
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          const { calculateOvertimeCompOffBalance } = response.data;
          if (response.data && !calculateOvertimeCompOffBalance.errorCode) {
            resolve(
              calculateOvertimeCompOffBalance.compensatoryOffBalance
                ? calculateOvertimeCompOffBalance.compensatoryOffBalance
                : 0
            );
          } else {
            reject(
              "Something went wrong while calculating the compensatory off balance. If you continue to see this problem please talk to your platform administrator."
            );
          }
        })
        .catch((calcError) => {
          if (calcError && calcError.graphQLErrors) {
            let errorCode = getErrorCodes(calcError);
            switch (errorCode) {
              //common for all endpoints
              case "_DB0000": //- Technical issue // common for all endpoint
                reject(
                  "It’s us! There seems to be some technical difficulties while calculating the compensatory off balance. Please try after some time."
                );
                break;
              case "OT0107": // Shift does not scheduled for this date
                reject(
                  "You can't check compensatory off eligibility as there was no shift scheduled for this date."
                );
                break;
              case "OT0110": // Overtime should not fall in the regular work hours
                reject(
                  "You are not allowed to claim compensatory off balance because the overtime should not fall in the regular work hours."
                );
                break;
              case "OT0011": // Unable to retrieve the employee work schedule details as the given date does not fall on yesterday or today or tomorrow's work schedule consideration date-time.
              case "OT0127": // The overlapping shift is enabled. But the shift is not scheduled for the next date.
              case "OT0128": // The next shift consideration start date-time should be greater than the current shift's regular end date-time when the overlapping shift is enabled.
              case "OT0129": // The next shift consideration start date-time should be greater than the current shift consideration start date-time when the overlapping shift is enabled.
              case "OT0130": // The difference between the next shift consideration start date-time and the current shift consideration start date-time should be less than or equal to 24 hours when the overlapping shift is enabled.
                reject(
                  "You are not eligible for claiming the compensatory off balance."
                );
                break;
              case "OT0120": // Overtime settings do not exist or an error occurred while processing the request to retrieve the overtime settings.
              case "OT0121": // Error while processing the request to calculate the compensatory off balance for the overtime.
              case "OT0122": // Compensatory off is not applicable for the overtime hours.
              case "IVE0000": // Invalid input request
              case "IVE0101": // Start time is required
              case "IVE0102": // End time is required
              case "IVE0083": // Please provide valid Employee Id
              case "IVE0098": // Start time should be less than the end time.
              case "_UH0001": //  unhandled error from backend
              default:
                reject(
                  "Something went wrong while calculating the compensatory off balance. If you continue to see this problem please talk to your platform administrator."
                );
                break;
            }
          } else if (calcError && calcError.networkError) {
            reject(handleNetworkErrors(calcError));
          }
        });
    });
  },
  // action to calculate shift allowance amount in additional wage claim
  calculateShiftAllowance(state, payload) {
    const {
      clients: { apolloClientC },
    } = createProvider();
    return new Promise((resolve, reject) => {
      apolloClientC
        .query({
          query: CALCULATE_SHIFT_ALLOWANCE,
          variables: {
            employeeId: parseInt(payload.employeeId, 10),
            otStartTime: payload.otStartTime,
            otEndTime: payload.otEndTime,
            workScheduleId: payload.workScheduleId,
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          const { calculateOvertimeShiftAllowance } = response.data;
          if (response.data && !calculateOvertimeShiftAllowance.errorCode) {
            resolve(
              calculateOvertimeShiftAllowance.shiftAllowance
                ? calculateOvertimeShiftAllowance.shiftAllowance
                : 0
            );
          } else {
            reject(
              "Something went wrong while calculating the shift allowance. If you continue to see this problem please talk to your platform administrator."
            );
          }
        })
        .catch((calcError) => {
          if (calcError && calcError.graphQLErrors) {
            let errorCode = getErrorCodes(calcError);
            switch (errorCode) {
              case "_DB0000": //- Technical issue // common for all endpoint
                reject(
                  "It’s us! There seems to be some technical difficulties while calculating the shift allowance. Please try after some time."
                );
                break;
              case "OT0107": // Shift does not scheduled for this date
                reject(
                  "You are not allowed to claim shift allowance because shift does not scheduled for this date."
                );
                break;
              case "OT0110": // Overtime should not fall in the regular work hours
                reject(
                  "You are not allowed to claim shift allowance because the overtime should not fall in the regular work hours."
                );
                break;
              case "OT0119": // Overtime start date-time should be greater than or equal to the shift consideration start date-time.
                reject(
                  "You are not allowed to claim shift allowance because the overtime start date-time should be greater than or equal to the shift consideration start date-time."
                );
                break;
              case "OT0123": // Shift allowance is not applicable for the overtime hours.
                reject(
                  "Shift allowance is not applicable for the overtime hours."
                );
                break;
              case "OT0125": // Shift allowance is not applicable as the overtime is claimed for the week off or holiday before the regular to date-time.
                reject(
                  "Shift allowance is not applicable as the overtime is claimed for the week off or holiday before the regular end date-time."
                );
                break;
              case "OT0011": // Unable to retrieve the employee work schedule details as the given date does not fall on yesterday or today or tomorrow's work schedule consideration date-time.
              case "OT0127": // The overlapping shift is enabled. But the shift is not scheduled for the next date.
              case "OT0128": // The next shift consideration start date-time should be greater than the current shift's regular end date-time when the overlapping shift is enabled.
              case "OT0129": // The next shift consideration start date-time should be greater than the current shift consideration start date-time when the overlapping shift is enabled.
              case "OT0130": // The difference between the next shift consideration start date-time and the current shift consideration start date-time should be less than or equal to 24 hours when the overlapping shift is enabled.
                reject("You are not allowed to claim shift allowance.");
                break;
              case "OT0120": // Overtime settings do not exist or an error occurred while processing the request to retrieve the overtime settings.
              case "OT0010": // Unable to validate the custom group shift-type exists or not.
              case "OT0126": // Error while processing the request to calculate the employee shift allowance.
              case "IVE0000": // Invalid input request
              case "IVE0101": // Start time is required
              case "IVE0102": // End time is required
              case "IVE0083": // please provide valid Employee Id
              case "IVE0098": // Start time should be less than the end time.
              case "IVE0150": // please provide a valid workScheduleId
              case "_UH0001": // unhandled error from backend
              default:
                reject(
                  "Something went wrong while calculating the shift allowance. If you continue to see this problem please talk to your platform administrator."
                );
                break;
            }
          } else if (calcError && calcError.networkError) {
            reject(handleNetworkErrors(calcError));
          }
        });
    });
  },
  //action to get round off settings configured
  fetchRoundOffSettings({ commit }) {
    const {
      clients: { apolloClientB },
    } = createProvider();
    return new Promise((resolve, reject) => {
      apolloClientB
        .query({
          query: RETRIEVE_ROUND_OFF_SETTINGS,
        })
        .then((response) => {
          if (response.data) {
            const {
              viewPayrollSettings: { errorCode, payrollSettings },
            } = response.data;
            if (!errorCode && payrollSettings) {
              let parsedRoundOffSetting = JSON.parse(payrollSettings);

              commit("UPDATE_ROUND_OFF_SETTINGS", parsedRoundOffSetting);
              resolve(parsedRoundOffSetting);
            } else {
              reject(errorCode);
            }
          } else {
            reject("errorCode");
          }
        })
        .catch((roundOffError) => {
          reject(roundOffError);
        });
    });
  },
  //delete the selected salary template
  deleteSalaryTemplate({ commit }, payload) {
    const {
      clients: { apolloClientF },
    } = createProvider();
    const { templateId, formName } = payload;
    return new Promise((resolve, reject) => {
      apolloClientF
        .mutate({
          mutation: DELETE_TEMPLATE,
          variables: {
            templateId: templateId,
          },
          client: "apolloClientF",
        })
        .then((res) => {
          let { deleteSalaryTemplate } = res.data;
          if (deleteSalaryTemplate && !deleteSalaryTemplate.errorCode) {
            commit("UPDATE_TEMPLATE_REFETCH", true);
            resolve();
          } else {
            let snackbarData = {
              isOpen: true,
              message:
                "Something went wrong while deleting the template. If you continue to see this problem please talk to your platform administrator",
              type: "warning",
            };

            reject(snackbarData);
          }
        })
        .catch((deleteError) => {
          let snackbarData = {
            isOpen: true,
            message: "",
            type: "warning",
          };
          if (deleteError && deleteError.graphQLErrors) {
            let errorCode = getErrorCodes(deleteError);
            switch (errorCode) {
              //specific errors for delete templates
              case "PST0101": //Salary template cannot be deleted since it is mapped to an employee.
                snackbarData.message =
                  "Sorry, you can't delete this " +
                  formName +
                  " as it is mapped to an employee";
                break;
              case "PST0003": //Salary template does not exits.
                snackbarData.message =
                  "The template you are trying to delete do not exists or may be deleted earlier. Kindly refresh and try again.";
                break;
              case "PST0002": //Error while deleting salary template.
                snackbarData.message =
                  "Something went wrong while deleting the template. Please try after some time or talk to your platform administrator.";
                break;
              //common for all endpoints
              case "_DB0000": //- Technical issue // common for all endpoint
                snackbarData.message =
                  "Sorry, This issue seems to be from our side. Would you mind trying it after some time or talk to your administrator ?";

                break;
              case "_DB0001": //Error while retrieving the employee access rights
              case "_DB0002": //Error while trying to check the employee access rights
              case "_DB0104": //While check access rights form not found'
              case "_UH0001": //  unhandled error from backend
              default:
                snackbarData.message =
                  "Something went wrong while deleting the template. If you continue to see this problem please talk to your platform administrator.";

                break;
            }
          } else if (deleteError && deleteError.networkError) {
            snackbarData.message = handleNetworkErrors(deleteError);
          }
          reject(snackbarData);
        });
    });
  },
};
