import { fixedOffValues } from "@/helper.js";
export default {
  // salary template getters
  // ========================

  getRoundedValue: (state) => (formName) => {
    // based on form id get roundOff method and round off value based on that
    let roundOff = state.roundOffValue;
    let roundedValue;
    switch (formName) {
      case "Salary":
        roundedValue = roundOff["37"];
        break;
      case "TDS":
        roundedValue = roundOff["39"];
        break;
      case "Gratuity":
        roundedValue = roundOff["110"];
        break;
      case "NPS":
        roundedValue = roundOff["126"];
        break;
      case "ESI":
        roundedValue = roundOff["58"];
        break;
      case "EPF":
        roundedValue = roundOff["52"];
        break;
    }
    return roundedValue;
  },
  //get the rounded value based on the settings
  roundOffValue: (state, getters) => (payload) => {
    let roundedValue;
    let type = getters.getRoundedValue(payload.type);
    switch (type) {
      case 1:
        //Round to nearest digit.
        //Eg: If a value is 6444.44 then it will be rounded to 6444 and 6444.78 will be rounded to 6445
        roundedValue = Math.round(payload.value);
        break;
      case 2:
        //Round up to next digit
        //Eg:  value 6444.44, will be rounded to 6445 and 6444.78 will rounded to 6445
        roundedValue = Math.ceil(payload.value);
        break;
      case 3:
        //Round down to previous digit
        //Eg: value 6444.44 will be rounded to 6444 and 6444.78 will rounded to 6444
        roundedValue = Math.floor(payload.value);
        break;
      case 0: //Do not round of the value
      default:
        //Round value up to 2 digit after decimal point(Eg:3.33333 -> 3.33)
        roundedValue = fixedOffValues(payload.value, 2);
        break;
    }

    return roundedValue;
  },
};
