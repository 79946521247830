<template>
  <v-dialog
    v-model="openConfirmationModal"
    width="450px"
    @click:outside="$emit('close-modal')"
  >
    <v-card class="rounded-lg">
      <div class="d-flex justify-end">
        <v-icon
          color="secondary"
          class="pr-3 pt-3"
          @click="$emit('close-modal')"
          >close</v-icon
        >
      </div>
      <v-row class="pa-4 pt-0">
        <v-col lg="12" md="12" sm="12" cols="12">
          <v-card-title v-if="imageName || iconName">
            <v-row v-if="!imageName" class="justify-center">
              <v-icon :color="iconColor" size="60" class="font-weight-bold">{{
                iconName
              }}</v-icon>
            </v-row>
            <v-row v-else class="justify-center">
              <img
                width="50px"
                height="auto"
                :src="getDeleteImageUrl"
                alt="delete"
              />
            </v-row>
          </v-card-title>
          <v-card-text class="justify-center">
            <v-row v-if="showHeading" class="justify-center modal-heading-text">
              <span class="primary--text pr-2">{{ validRecords }}</span
              ><span>out of </span>
              <span class="primary--text pl-2 pr-2">{{ totalRecords }}</span>
              <span class="text-center">{{ popHeading }}</span>
            </v-row>
            <v-row class="justify-center">
              <slot name="bodyContent"></slot>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              elevation="2"
              class="cancel-button"
              @click="$emit('close-modal')"
            >
              Cancel
            </v-btn>
            <v-btn
              color="secondary"
              class="ml-2 white--text main-button"
              :disabled="disableButton"
              @click="$emit('accept-confirmation')"
            >
              {{ buttonText }}
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "AppConfirmationModal",

  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    validRecords: {
      type: Number,
      default: 0,
    },
    totalRecords: {
      type: Number,
      default: 0,
    },
    popHeading: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "Proceed",
    },
    showHeading: {
      type: Boolean,
      default: false,
    },
    imageName: {
      type: String,
      default: "",
    },
    iconColor: {
      type: String,
      default: "warning",
    },
    iconName: {
      type: String,
      default: "fas fa-exclamation-circle",
    },
    sideNotesHeading: {
      type: String,
      default: "",
    },
    disableButton: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      openConfirmationModal: false,
    };
  },
  computed: {
    //to know whether browser support webp or not
    isBrowserSupportWebp() {
      return this.$store.state.isWebpSupport;
    },
    getDeleteImageUrl() {
      if (this.isBrowserSupportWebp)
        return require("@/assets/images/" + this.imageName + ".webp");
      else return require("@/assets/images/" + this.imageName + ".png");
    },
  },
  mounted() {
    this.openConfirmationModal = this.openModal;
  },
};
</script>
<style scoped>
.v-btn:not(.v-btn--round).v-size--default {
  min-width: 90px !important;
}
.content-highlighted-text {
  font-weight: 600;
}
.modal-heading-text {
  font-size: 1.3em;
  font-weight: 600;
}
.main-button {
  order: 1 !important;
}
.cancel-button {
  order: 0 !important;
}
.v-dialog {
  overflow-y: hidden !important;
}
@media (max-width: 568px) {
  .main-button {
    order: 0 !important;
  }
  .cancel-button {
    order: 1 !important;
  }
}
</style>
