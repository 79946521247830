export default {
  // update member count statistics
  UPDATE_AVAILABLE_MEMBER_COUNTS(state, value) {
    state.memberCountStatistics = value;
  },
  // update subscription plan details
  UPDATE_SUBSCRIBED_PLAN_DETAILS(state, value) {
    state.subscribedPlanDetails = value;
  },
};
