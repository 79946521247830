import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "firebase/app";
import "firebase/auth";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  // authenticated routing
  {
    path: "",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "../views/dashboard/MainDashboard.vue"
          ),
        meta: {
          title: "Dashboard", //Shown as tab title for this route
          requiresAuth: true, //meta data to check user logged in
        },
      },
      //this route is added for update alert for mobile app
      {
        path: "/dashboard",
        name: "Layout",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "../views/dashboard/MainDashboard.vue"
          ),
        meta: {
          title: "Layout", //Shown as tab title for this route
          requiresAuth: true, //meta data to check user logged in
        },
      },
      {
        path: "/quick-menu",
        name: "Quick Menu",
        component: () =>
          import(
            /* webpackChunkName: "quick-menu" */ "../layouts/QuickMenu.vue"
          ),
        meta: {
          title: "Quick Menu", //Shown as tab title for this route
          requiresAuth: true, //meta data to check user logged in
        },
      },
      //=======================
      //PRODUCTIVITY MONITORING MODULE ROUTES
      //=======================
      {
        path: "/productivity-monitoring/activity-tracker",
        name: "Activity Tracker",
        component: () =>
          import(
            /* webpackChunkName: "activity-tracker" */ "../views/employee-monitoring/activity-tracker/ActivityTracker.vue"
          ),
        meta: {
          title: "Activity Tracker",
          requiresAuth: true,
        },
      },
      {
        path: "/productivity-monitoring/members",
        name: "Team Members",
        component: () =>
          import(
            /* webpackChunkName: "members" */ "../views/employee-monitoring/members/TeamMembersMain.vue"
          ),
        meta: {
          title: "Team Members",
          requiresAuth: true,
        },
      },
      {
        path: "/productivity-monitoring/reports",
        name: "Activity Tracker Reports",
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "../views/employee-monitoring/reports/ActivityTrackerReports.vue"
          ),
        meta: {
          title: "Activity Tracker Reports",
          requiresAuth: true,
        },
      },
      {
        path: "/productivity-monitoring/activity-dashboard",
        name: "Activity Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "activity-dashboard" */ "../views/employee-monitoring/dashboard/EmployeeMonitoringDashboard.vue"
          ),
        meta: {
          title: "Activity Dashboard",
          requiresAuth: true,
        },
      },
      {
        path: "/productivity-monitoring/workforce-analytics",
        name: "Workforce Analytics",
        component: () =>
          import(
            /* webpackChunkName: "workforce-analytics" */ "../views/employee-monitoring/workforce-analytics/WorkforceAnalytics.vue"
          ),
        meta: {
          title: "Workforce Analytics",
          requiresAuth: true,
        },
      },
      //=======================
      //EMPLOYEES MODULE ROUTES
      //=======================
      {
        path: "/employees/employee-directory",
        name: "Employee Directory",
        component: () =>
          import(
            /* webpackChunkName: "employees-directory" */ "../views/employees/employees/Employees.vue"
          ),
        meta: {
          title: "Employee Directory",
          requiresAuth: true,
        },
      },
      {
        path: "/employees/redeem-rewards",
        name: "Redeem Rewards",
        component: () =>
          import(
            /* webpackChunkName: "redeem-rewards" */ "../views/employees/employees/Employees.vue"
          ),
        meta: {
          title: "Redeem Rewards",
          requiresAuth: true,
        },
      },
      {
        path: "/attendance-box/:id",
        name: "Attendance Box",
        component: () =>
          import(
            /* webpackChunkName: "AttendanceBox" */ "../views/employees/attendance/AttendanceBox.vue"
          ),
        meta: {
          title: "Attendance Box",
          isFullPageLayout: true,
          requiresAuth: true,
        },
      },
      //=======================
      //PERFORMANCE MANAGEMENT MODULE ROUTES
      //=======================
      {
        path: "/performance-management/performance-evaluation",
        name: "Goals And Achievement",
        component: () =>
          import(
            /* webpackChunkName: "goals-and-achievement" */ "../views/performance-management/performance-evaluation/PerformanceEvaluation.vue"
          ),
        meta: {
          title: "Goals And Achievement",
          requiresAuth: true,
        },
      },
      //=======================
      //ROASTER MANAGEMENT MODULE ROUTES
      //=======================
      {
        path: "/roster-management/shift-scheduling",
        name: "Shift Scheduling",
        component: () =>
          import(
            /* webpackChunkName: "roster-management" */ "../views/roster-management/shift-scheduling/ShiftScheduling.vue"
          ),
        meta: {
          title: "Shift Scheduling",
          requiresAuth: true,
        },
      },
      //=======================
      //PAYROLL MODULE ROUTES
      //=======================
      {
        path: "/payroll/salary-template",
        name: "Salary Template",
        component: () =>
          import(
            /* webpackChunkName: "salary-template" */ "../views/payroll/salary-template/SalaryTemplate.vue"
          ),
        meta: {
          title: "Salary Template",
          requiresAuth: true,
        },
      },
      {
        path: "/payroll/additional-wage-claim",
        name: "Additional Wage Claim",
        component: () =>
          import(
            /* webpackChunkName: "overtime" */ "../views/payroll/additional-wage-claim/AdditionalWageClaim.vue"
          ),
        meta: {
          title: "Additional Wage Claim",
          requiresAuth: true,
        },
      },
      //=======================
      //ASSET MANAGEMENT MODULE ROUTES
      //=======================
      {
        path: "/asset-management/assets",
        name: "Assets",
        component: () =>
          import(
            /* webpackChunkName: "asset-management" */ "../views/asset-management/assets/Assets.vue"
          ),
        meta: {
          title: "Assets",
          requiresAuth: true,
        },
      },
      //=======================
      //SETTINGS MODULE ROUTES
      //=======================
      {
        path: "/settings/productivity-monitoring",
        name: "Productivity Monitoring Settings",
        component: () =>
          import(
            /* webpackChunkName: "productivity-monitoring-settings" */ "../views/settings/employee-monitoring/EmployeeMonitoring.vue"
          ),
        meta: {
          title: "Productivity Monitoring Settings",
          requiresAuth: true,
        },
      },
      {
        path: "/settings/payroll",
        name: "Payroll",
        component: () =>
          import(
            /* webpackChunkName: "payroll-settings" */ "../views/settings/payroll/Payroll.vue"
          ),
        meta: {
          title: "Payroll",
          requiresAuth: true,
        },
      },
      {
        path: "/settings/tax-and-statutory-compliance",
        name: "Tax and Statutory Compliance",
        component: () =>
          import(
            /* webpackChunkName: "tax-settings" */ "../views/settings/tax-and-statutory-compliance/Tax.vue"
          ),
        meta: {
          title: "Tax and Statutory Compliance",
          requiresAuth: true,
        },
      },
      {
        path: "/settings/performance-management",
        name: "Performance Management",
        component: () =>
          import(
            /* webpackChunkName: "performance-management-settings" */ "../views/settings/employees/performance-management/PerformanceManagement.vue"
          ),
        meta: {
          title: "Performance Management",
          requiresAuth: true,
        },
      },
      {
        path: "/settings/core-hr",
        name: "Core HR",
        component: () =>
          import(
            /* webpackChunkName: "core-hr" */ "../views/settings/core-hr/CoreHR.vue"
          ),
        meta: {
          title: "Core HR",
          requiresAuth: true,
        },
      },
      {
        path: "/settings/core-hr/time-off",
        name: "Time Off",
        component: () =>
          import(
            /* webpackChunkName: "time-off" */ "../views/settings/core-hr/time-off/TimeOff.vue"
          ),
        meta: {
          title: "Time Off",
          requiresAuth: true,
        },
      },
      {
        path: "/settings/core-hr/geo-fencing-&-selfie-attendance",
        name: "Geo-Fencing & Selfie Attendance",
        component: () =>
          import(
            /* webpackChunkName: "geo-fencing-selfie-attendance" */ "../views/settings/core-hr/attendance-configuration/AttendanceConfiguration.vue"
          ),
        meta: {
          title: "Geo-Fencing & Selfie Attendance",
          requiresAuth: true,
        },
      },
      {
        path: "/settings/core-hr/holidays",
        name: "Holiday Settings",
        component: () =>
          import(
            /* webpackChunkName: "holidays-settings" */ "../views/settings/core-hr/holiday/HolidaySettings.vue"
          ),
        meta: {
          title: "Holiday Settings",
          requiresAuth: true,
        },
      },
      {
        path: "/settings/data-loss-prevention",
        name: "Data Loss Prevention",
        component: () =>
          import(
            /* webpackChunkName: "data-loss-prevention" */ "../views/settings/data-loss-prevention/DataLossPrevention.vue"
          ),
        meta: {
          title: "Data Loss Prevention",
          requiresAuth: true,
        },
      },
      //=======================
      //CORE-HR MODULE ROUTES
      //=======================
      {
        path: "/core-hr/custom-employee-groups",
        name: "Custom Group",
        component: () =>
          import(
            /* webpackChunkName: "custom-employee-groups" */ "../views/core-hr/employee-data-management/custom-group/CustomGroup.vue"
          ),
        meta: {
          title: "Custom Group",
          requiresAuth: true,
        },
      },
      {
        path: "/core-hr/work-schedule",
        name: "Work Schedule",
        component: () =>
          import(
            /* webpackChunkName: "work-schedule" */ "../views/core-hr/work-schedule/WorkScheduleMain.vue"
          ),
        meta: {
          title: "Work Schedule",
          requiresAuth: true,
        },
      },
      {
        path: "/core-hr/register-face",
        name: "Facial Registration",
        component: () =>
          import(
            /* webpackChunkName: "facial-registration" */ "../views/core-hr/face-registration/FaceRegistration.vue"
          ),
        meta: {
          title: "Facial Registration",
          requiresAuth: true,
        },
      },

      {
        path: "/core-hr/employee-data-management",
        name: "Employee Data Management",
        component: () =>
          import(
            /* webpackChunkName: "employee-data-management" */ "../views/core-hr/employee-data-management/EmployeeDataSetup.vue"
          ),
        meta: {
          title: "Employee Data Management",
          requiresAuth: true,
        },
      },

      {
        path: "/core-hr/designations",
        name: "Designations",
        component: () =>
          import(
            /* webpackChunkName: "designations" */ "../views/core-hr/employee-data-management/designation/Designation.vue"
          ),
        meta: {
          title: "Designations",
          requiresAuth: true,
        },
      },
      {
        path: "/core-hr/holidays",
        name: "Holidays",
        component: () =>
          import(
            /* webpackChunkName: "holidays" */ "../views/core-hr/holiday/Holiday.vue"
          ),
        meta: {
          title: "Holiday",
          requiresAuth: true,
        },
      },
      {
        path: "/core-hr/user-accounts",
        name: "User Accounts",
        component: () =>
          import(
            /* webpackChunkName: "user-accounts" */ "../views/core-hr/employee-data-management/user-account-details/UserAccountMain.vue"
          ),
        meta: {
          title: "User Accounts",
          requiresAuth: true,
        },
      },
      //=======================
      //ONBOARDING MODULE ROUTES
      //=======================
      {
        path: "/onboarding/vendors",
        name: "Vendors",
        component: () =>
          import(
            /* webpackChunkName: "vendors" */ "../views/onboarding/vendor/VendorOnboard.vue"
          ),
        meta: {
          title: "Vendors",
          requiresAuth: true,
        },
      },
      //=======================
      //BILLING MODULE ROUTES
      //=======================
      {
        path: "/billing",
        name: "Billing",
        component: () =>
          import(
            /* webpackChunkName: "billing" */ "../views/billing/BillingMainScreen.vue"
          ),
        meta: {
          title: "Billing",
          requiresAuth: true,
        },
      },
      //=======================
      //BENEFITS MODULE ROUTES
      //=======================
      {
        path: "/benefits/esop",
        name: "Benefits",
        component: () =>
          import(
            /* webpackChunkName: "benefits" */ "../views/benefits/esop/ESOP.vue"
          ),
        meta: {
          title: "Benefits",
          requiresAuth: true,
        },
      },
      //=======================
      //FORMS MANAGER MODULE ROUTES
      //=======================
      {
        path: "/compliance-management/docusign",
        name: "DocuSign",
        component: () =>
          import(
            /* webpackChunkName: "docusign" */ "../views/compliance-management/docusign/ComplianceManagement.vue"
          ),
        meta: {
          title: "DocuSign",
          requiresAuth: true,
        },
      },
      {
        path: "/compliance-management/accreditation",
        name: "Accreditation",
        component: () =>
          import(
            /* webpackChunkName: "accreditation" */ "../views/compliance-management/accreditation/AccreditationsMain.vue"
          ),
        meta: {
          title: "Accreditation",
          requiresAuth: true,
        },
      },
      {
        path: "/compliance-management/accreditation/individuals",
        name: "Individual Accreditation",
        component: () =>
          import(
            /* webpackChunkName: "individual-accreditation" */ "../views/compliance-management/accreditation/individuals/Accreditations.vue"
          ),
        meta: {
          title: "Individual Accreditation",
          requiresAuth: true,
        },
      },
      {
        path: "/compliance-management/accreditation/vendors",
        name: "Vendor Accreditation",
        component: () =>
          import(
            /* webpackChunkName: "vendor-accreditation" */ "../views/compliance-management/accreditation/vendor/VendorAccreditations.vue"
          ),
        meta: {
          title: "Vendor Accreditation",
          requiresAuth: true,
        },
      },
      //=======================
      //FILE TRANSFERS MODULE ROUTES
      //=======================
      {
        path: "/data-loss-prevention/file-transfers",
        name: "File Transfers",
        component: () =>
          import(
            /* webpackChunkName: "data-loss-prevention" */ "../views/data-loss-prevention/file-transfers/FileTransfers.vue"
          ),
        meta: {
          title: "Data Loss Prevention",
          requiresAuth: true,
        },
      },
    ],
  },

  // unAuthenticated routing
  {
    path: "",
    component: () => import("@/layouts/UnAuthenticateLayout.vue"),
    children: [
      //=======================
      //TruLead Auth
      //=======================
      {
        path: "/tlauth",
        name: "TruLead Auth",
        component: () =>
          import(
            /* webpackChunkName: "tl-auth" */ "../views/pages/TruLeadLandingScreen.vue"
          ),
        meta: {
          title: "TruLead Auth",
          requiresAuth: false,
        },
      },
      //=======================
      //SIGNATORIES
      //=======================
      {
        path: "/docusign",
        name: "Signatories",
        component: () =>
          import(
            /* webpackChunkName: "Signatories" */ "../views/signatories/Signatories.vue"
          ),
        meta: {
          title: "Signatories",
          requiresAuth: false,
        },
      },
    ],
  },
  // =============================================================================
  // FULL PAGE LAYOUTS
  {
    path: "/page-not-found",
    name: "PageNotFound",
    component: () => import("../views/pages/PageNotFound.vue"),
    meta: {
      title: "Page Not Found",
    },
  },

  // Redirect to 404 page, if no match found
  {
    path: "*",
    redirect: "/page-not-found",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

// Route pre-entry check
router.beforeEach((to, from, next) => {
  firebase.auth().onAuthStateChanged(() => {
    let org_name = store.getters.organizationName;

    let domain = store.getters.domain;
    if (domain === "cannyhr") {
      //To Update App Title for cannyhr domain
      if (org_name) {
        document.title = to.meta.title + " - " + org_name + " | CANNYHR";
      } else {
        document.title = to.meta.title + " | CANNYHR";
      }
    } else {
      if (org_name) {
        document.title = to.meta.title + " - " + org_name + " | HRAPP";
      } else {
        //incase any error while receiving company name present the below title
        document.title = to.meta.title + " | HRAPP - Hire to Retire";
      }
    }

    // get firebase current user
    const firebaseCurrentUser = firebase.auth().currentUser;
    if (firebaseCurrentUser) {
      //note: if firebase user exist we store the user data in store for future use.
      firebase
        .auth()
        .currentUser.getIdTokenResult()
        .then((signedUserData) => {
          store.commit("UPDATE_USER_INFO", signedUserData);
        })
        .catch(() => {
          store.commit("UPDATE_USER_INFO", {});
        });
    }
    //before each route, check accessToken and refreshToken exist in cookie
    //for auth required screen if both value should exist in the cookie
    //otherwise redirect to auth screen
    const accessToken = window.$cookies.get("accessToken");
    const refreshToken = window.$cookies.get("refreshToken");
    let baseUrl = store.getters.baseUrl;
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

    if (requiresAuth) {
      //when user sign-in from mobile we don't get refresh-token  in cookie as of now
      //so if either refresh-token or native-app we allow user to enter into our app
      if (accessToken || refreshToken) {
        next();
      } else {
        console.log(
          "Auth token or Refresh token not exists",
          accessToken,
          refreshToken
        );
        store.dispatch("clearUserLock");
        return (window.location.href = baseUrl + "auth");
      }
    } else {
      next();
    }
  });
});

router.onError((err) => {
  const accessToken = window.$cookies.get("accessToken");
  const refreshToken = window.$cookies.get("refreshToken");
  //redirect to auth page if no access token or refresh token
  if (!accessToken && !refreshToken) {
    let baseUrl = store.getters.baseUrl;
    return (window.location.href = baseUrl + "auth");
  } else {
    console.log("Routing-Error:", err);
    //logging errors
    store.dispatch("addErrorLog", err ? err.message : "");
    //reloading website if chunk fails
    //pattern : Loading (\D)+ (\w)(\D)+(\w)+ failed
    // const pattern = /Loading chunk (\d)+ failed/g;
    // const isChunkLoadFailed = err.message.match(pattern);
    const isChunkLoadFailed = err.message.includes("Loading", "failed");
    if (isChunkLoadFailed) {
      return window.location.reload();
    }
  }
});

export default router;
