import { createProvider } from "../../apolloConfiguration";
import {
  LIST_GROUP_EMPLOYEES_BASED_ON_TYPE,
  INCLUDE_EXCLUDE_EMPLOYEES,
} from "@/graphql/core-hr/customGroupQueries";
import {
  getErrorCodesWithValidationFromMessage,
  handleNetworkErrors,
} from "@/helper";

export default {
  listGroupEmployeesBasedOnType(state, payload) {
    const {
      clients: { apolloClientA },
    } = createProvider();
    return new Promise((resolve, reject) => {
      apolloClientA
        .query({
          query: LIST_GROUP_EMPLOYEES_BASED_ON_TYPE,
          variables: {
            type: payload.type,
            groupId: payload.groupId,
            designation: payload.designation,
            department: payload.department,
            employeeType: payload.employeeType,
            workSchedule: payload.workSchedule,
            location: payload.location,
            serviceProvider: [],
            state: [],
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          if (response.data) {
            const {
              listTypesOfEmployees: { errorCode, employees },
            } = response.data;
            if (!errorCode && employees) {
              resolve(employees);
            } else {
              reject(errorCode);
            }
          } else {
            reject("error");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  includeExcludeEmployeesInGroup({ rootState }, payload) {
    const {
      clients: { apolloClientA },
    } = createProvider();
    let actionMsg = "",
      action = "removed";
    if (payload.typeOfAction === "remove-from-default") {
      actionMsg = "excluding the employees";
      action = "excluded";
    } else if (payload.typeOfAction === "add-to-additionalinclusion") {
      actionMsg = "including the employees to the additional category";
      action = "included";
    } else if (payload.typeOfAction === "remove-employee-from-list") {
      actionMsg = "removing the employees from default or additional category";
    } else if (payload.typeOfAction === "remove-from-additionalinclusion") {
      actionMsg = "removing the employees from the additional category";
    } else if (payload.typeOfAction === "remove-from-exclusion") {
      actionMsg = "removing the employees from the exclusion category";
    }
    return new Promise((resolve, reject) => {
      try {
        apolloClientA
          .mutate({
            mutation: INCLUDE_EXCLUDE_EMPLOYEES,
            variables: {
              typeOfAction: payload.typeOfAction,
              groupId: payload.groupId,
              employeeId: payload.employeeId,
              logInEmpId: rootState.orgDetails.employeeId,
            },
          })
          .then((response) => {
            if (response.data) {
              const {
                includeExcludeEmployees: { errorCode },
              } = response.data;
              if (!errorCode) {
                resolve();
              } else {
                reject(
                  `Something went wrong while ${actionMsg}. Please try after some time.`
                );
              }
            } else {
              reject(
                `Something went wrong while ${actionMsg}. Please try after some time.`
              );
            }
          })
          .catch((error) => {
            if (error && error.graphQLErrors) {
              let errorCode = getErrorCodesWithValidationFromMessage(error);
              switch (errorCode[0]) {
                case "_DB0000": // db connection error
                  reject(
                    "It’s us! There seems to be some technical difficulties. Please try after some time."
                  );
                  break;
                case "_DB0102": // no edit access
                  reject(
                    `Sorry you don't have access rights to ${actionMsg}. Please contact HR administrator.`
                  );
                  break;
                case "ERE0125": // Custom employee group is associated with the forms.
                  reject(
                    `Unable to ${actionMsg} as it was associated with other forms.`
                  );
                  break;
                case "_EC0001": // no records found
                  reject(
                    `Unable to ${actionMsg} as it was already ${action} in same or some other user session.`
                  );
                  break;
                case "ERE0019": // Error while updating the custom employee groups.
                case "ERE0119": // Error while processing the request for updating the custom employee groups.
                  reject(
                    `Something went wrong while updating the custom group when ${actionMsg}. Please try after some time.`
                  );
                  break;
                case "ERE0109": // This group is associated with leave types. You should not exclude the employee from this group.
                  reject(
                    `Unable to ${actionMsg} as it was associated with leave types.`
                  );
                  break;
                case "ERE0110": // Some of the employee(s) are applied leave.
                  reject(
                    `Unable to ${actionMsg} as some of the employee(s) are applied leave who are associated with this group.`
                  );
                  break;
                case "ERE0111": // Custom employee group updated successfully. To update your leave balance contact the system admin
                  reject(
                    "Custom group updated successfully. But unable to update the leave balance. Please contact system admin."
                  );
                  break;
                case "IVE0000": // Invalid input request
                case "_EC0007": // Invalid input field(s).
                default:
                  reject(
                    `Something went wrong while ${actionMsg}. Please try after some time.`
                  );
                  break;
              }
            } else if (error && error.networkError) {
              reject(handleNetworkErrors(error));
            } else {
              reject(
                `Something went wrong while ${actionMsg}. Please try after some time.`
              );
            }
          });
      } catch {
        reject(
          `Something went wrong while ${actionMsg}. Please try after some time.`
        );
      }
    });
  },
};
