export default {
  // ========================
  // salary template state
  // ========================
  salaryTemplateList: [],
  filteredTemplateList: [],
  retiralsComponents: [],
  templateCurrencySymbol: "",
  isTemplateRefetchRequire: false,
  //earning components are fixed so formed in UI
  earningsComponents: [
    {
      itemId: "Allowance",
      itemName: "Allowance",
      accessName: "allowanceArray",
    },
    {
      itemId: "Bonus",
      itemName: "Bonus",
      accessName: "bonusArray",
    },
    {
      itemId: "Flexible_Benefit_Plan",
      itemName: "Flexible Benefit Plan",
      accessName: "flexiBenefitPlanArray",
    },
    {
      itemId: "Reimbursement",
      itemName: "Reimbursement",
      accessName: "reimbursementArray",
    },
  ],
  //hold round off settings value
  roundOffValue: {},

  // ========================
  // additional wage claim state
  // ========================
  isCompOffEnabled: 0,
  isShiftAllowanceEnabled: 0,
};
