import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@fortawesome/fontawesome-free/css/all.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import { Ripple } from "vuetify/lib/directives";

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
});

const brandColor = JSON.parse(localStorage.getItem("brand_color"));
export default new Vuetify({
  icons: {
    iconfont: "md",
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes:
      brandColor && brandColor.Primary_Color
        ? {
            light: {
              primary: brandColor.Primary_Color,
              secondary: brandColor.Secondary_Color,
              grey: "#9E9E9E",
              green: "#4CAF50",
              deeporange: "#FF5722",
              lightblue: "#8b8d8e",
              hoverColor: brandColor.Hover_Color,
              headerColor: brandColor.Table_Header_Color,
              headerTextColor: brandColor.Table_Header_Text_Color,
            },
            dark: {
              primary: brandColor.Primary_Color,
              secondary: brandColor.Secondary_Color,
              grey: "#9E9E9E",
              green: "#4CAF50",
              deeporange: "#FF5722",
              lightblue: "#8b8d8e",
              hoverColor: brandColor.Hover_Color,
              headerColor: brandColor.Table_Header_Color,
              headerTextColor: brandColor.Table_Header_Text_Color,
            },
          }
        : {
            light: {
              primary: "#260029",
              secondary: "#ec407a",
              grey: "#9E9E9E",
              green: "#4CAF50",
              deeporange: "#FF5722",
              lightblue: "#8b8d8e",
            },
            dark: {
              primary: "#260029",
              secondary: "#ec407a",
              grey: "#9E9E9E",
              green: "#4CAF50",
              deeporange: "#FF5722",
              lightblue: "#8b8d8e",
            },
          },
  },
});
