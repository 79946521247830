import { createProvider } from "../../apolloConfiguration";
import {
  LIST_EMP_ONBOARDING_ROLES,
  LIST_EMPLOYEE_MANAGERS,
  RETRIEVE_STEALTH_MODE_APP_UPDATES,
  DELETE_INVITED_MEMBERS,
} from "@/graphql/employee-monitoring/membersQueries";
import {
  GET_EMPLOYEE_WORK_SCHEDULE_DETAILS,
  GET_ACTIVITY_GOAL_FOR_DASHBOARD,
} from "@/graphql/employee-monitoring/activityTrackerQueries";
// import helper function
import { getErrorCodes, handleNetworkErrors } from "@/helper";

export default {
  // action to get roles list of members(currently admin,managers and team-members are available)
  getMembersRolesList({ commit }) {
    const {
      clients: { apolloClientE },
    } = createProvider();
    return new Promise((resolve, reject) => {
      apolloClientE
        .query({
          query: LIST_EMP_ONBOARDING_ROLES,
        })
        .then((roleResponse) => {
          const { listEmpMonitoringRoles } = roleResponse.data;
          if (roleResponse.data && !listEmpMonitoringRoles.errorCode) {
            commit(
              "UPDATE_EMP_ONBOARD_ROLE_LIST",
              listEmpMonitoringRoles.rolesData
                ? listEmpMonitoringRoles.rolesData
                : []
            );
            resolve();
          } else {
            reject(
              "Something went wrong while fetching the employee roles. If you continue to see this issue, please contact the platform administrator."
            );
          }
        })
        .catch((calcError) => {
          if (calcError && calcError.graphQLErrors) {
            let errorCode = getErrorCodes(calcError);
            switch (errorCode) {
              case "_DB0000": //- Technical error
                reject(
                  "It’s us! There seems to be some technical difficulties while fetching the employee roles. Please try after some time."
                );
                break;
              case "EM0038": //Error while retrieving the roles in .catch block
              case "EM0117": // Error while retrieving the roles in the main catch block
              case "ERE0124": //Error while getting the employeeId
              case "_UH0001": //unhandled error
              default:
                reject(
                  "Something went wrong while fetching the employee roles. If you continue to see this issue, please contact the platform administrator."
                );
                break;
            }
          } else if (calcError && calcError.networkError) {
            reject(handleNetworkErrors(calcError));
          } else {
            reject(
              "Something went wrong while fetching the employee roles. If you continue to see this issue, please contact the platform administrator."
            );
          }
        });
    });
  },

  // action to get roles list of members(currently admin,managers and team-members are available)
  getManagersList() {
    const {
      clients: { apolloClientE },
    } = createProvider();
    return new Promise((resolve, reject) => {
      apolloClientE
        .query({
          query: LIST_EMPLOYEE_MANAGERS,
          fetchPolicy: "no-cache",
        })
        .then((fetchManagerResponse) => {
          const { listManagerDetails } = fetchManagerResponse.data;
          if (fetchManagerResponse.data && !listManagerDetails.errorCode) {
            resolve(
              listManagerDetails.managerData
                ? listManagerDetails.managerData
                : []
            );
          } else {
            reject(
              "Something went wrong while fetching the managers. If you continue to see this issue, please contact the platform administrator."
            );
          }
        })
        .catch((fetchManagerError) => {
          if (fetchManagerError && fetchManagerError.graphQLErrors) {
            let errorCode = getErrorCodes(fetchManagerError);
            switch (errorCode) {
              case "_DB0000": //- Technical error
                reject(
                  "It’s us! There seems to be some technical difficulties while fetching the managers. Please try after some time."
                );
                break;
              case "_UH0001": //unhandled error
              default:
                reject(
                  "Something went wrong while fetching the managers. If you continue to see this issue, please contact the platform administrator."
                );
                break;
            }
          } else if (fetchManagerError && fetchManagerError.networkError) {
            reject(handleNetworkErrors(fetchManagerError));
          } else {
            reject(
              "Something went wrong while fetching the managers. If you continue to see this issue, please contact the platform administrator."
            );
          }
        });
    });
  },

  // fetch stealth agent details
  retrieveStealthAgentDetails({ commit }) {
    const {
      clients: { apolloClientE },
    } = createProvider();
    return new Promise((resolve, reject) => {
      apolloClientE
        .query({
          query: RETRIEVE_STEALTH_MODE_APP_UPDATES,
          variables: {
            type: "get-all-updates",
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          let { errorCode, appUpdates } =
            response.data.retrieveStealthAppUpdates;

          if (appUpdates && !errorCode) {
            // download updates retrieved successfully
            let downloadData = JSON.parse(appUpdates);
            // update the download data in store
            commit("UPDATE_DESKTOP_APP_DOWNLOAD_DATA", downloadData);
            resolve(response.data.retrieveStealthAppUpdates);
          } else {
            // update the download data in store
            commit("UPDATE_DESKTOP_APP_DOWNLOAD_DATA", null);
            reject();
          }
        })
        .catch((stealthAgentError) => {
          if (stealthAgentError && stealthAgentError.graphQLErrors) {
            let errorCode = getErrorCodes(stealthAgentError);
            switch (errorCode) {
              // if the file is not found in s3
              case "EM0156":
                // update the download data in store
                commit("UPDATE_DESKTOP_APP_DOWNLOAD_DATA", "file-not-found");
                break;
              default:
                // update the download data in store
                commit("UPDATE_DESKTOP_APP_DOWNLOAD_DATA", null);
                break;
            }
          } else if (stealthAgentError && stealthAgentError.networkError) {
            reject(handleNetworkErrors(stealthAgentError));
          } else {
            commit("UPDATE_DESKTOP_APP_DOWNLOAD_DATA", null);
          }
          reject();
        });
    });
  },

  // action to delete invited members based on passed member id
  deleteInvitedMembers(state, payload) {
    const {
      clients: { apolloClientE },
    } = createProvider();
    return new Promise((resolve, reject) => {
      apolloClientE
        .mutate({
          mutation: DELETE_INVITED_MEMBERS,
          variables: {
            memberId: payload.memberId,
          },
        })
        .then((deleteResponse) => {
          const { deleteInvitedMember } = deleteResponse.data;
          if (!deleteInvitedMember.errorCode) {
            resolve();
          } else {
            reject(
              "Something went wrong while deleting the invited member. If you continue to see this issue, please contact the platform administrator."
            );
          }
        })
        .catch((calcError) => {
          if (calcError && calcError.graphQLErrors) {
            let errorCode = getErrorCodes(calcError);
            switch (errorCode) {
              case "_DB0000": //- Technical error
                reject(
                  "It’s us! There seems to be some technical difficulties while deleting the invited member. Please try after some time."
                );
                break;
              case "_DB0103":
                reject(
                  "Sorry, you don't have access to delete the invited member. Please contact HR administrator."
                );
                break;
              case "_EC0001": // no record found
                reject(
                  "Unable to delete the invited member as the member record is deleted in the same or some other user session."
                );
                break;
              case "_UH0001": //unhandled error // common for all endpoint
              case "_DB0001": // Error while retrieving the employee access rights
              case "_DB0104": // While check access rights form not found
              case "IVE0000": // Invalid input request
              case "IVE0094": // Please provide a valid memberId.
              case "EM0176": // Error while processing the request to delete the invited members.
              case "EM0093": // Error while deleting the invited members.
              default:
                reject(
                  "Something went wrong while deleting the invited member. If you continue to see this issue, please contact the platform administrator."
                );
                break;
            }
          } else if (calcError && calcError.networkError) {
            reject(handleNetworkErrors(calcError));
          } else {
            reject(
              "Something went wrong while deleting the invited member. If you continue to see this issue, please contact the platform administrator."
            );
          }
        });
    });
  },

  // action to get employee's work schedule details
  getEmpWorkScheduleDetails({ commit }, payload) {
    const {
      clients: { apolloClientK },
    } = createProvider();
    return new Promise((resolve, reject) => {
      apolloClientK
        .query({
          query: GET_EMPLOYEE_WORK_SCHEDULE_DETAILS,
          fetchPolicy: "no-cache",
          variables: {
            employeeId: payload.employeeId,
          },
        })
        .then((res) => {
          try {
            const { errorCode, employeeWorkScheduleDetails } =
              res.data.getEmployeeWorkScheduleDetails;
            if (!errorCode && employeeWorkScheduleDetails) {
              commit(
                "UPDATE_EMPLOYEE_WORK_SCHEDULE_DETAILS",
                employeeWorkScheduleDetails
              );
              resolve(employeeWorkScheduleDetails);
            } else {
              reject();
            }
          } catch {
            reject();
          }
        })
        .catch(() => {
          reject();
        });
    });
  },

  getActivityGoalsSettings({ commit }, payload) {
    const {
      clients: { apolloClientE },
    } = createProvider();
    return new Promise((resolve, reject) => {
      try {
        apolloClientE
          .query({
            query: GET_ACTIVITY_GOAL_FOR_DASHBOARD,
            variables: {
              employeeId: payload.employeeId ? payload.employeeId : null,
            },
          })
          .then((res) => {
            const { errorCode, activityGoal } =
              res.data.getActivityGoalSettingsInDashboard;
            if (!errorCode && activityGoal) {
              if (payload.isCommit) {
                commit("UPDATE_ORG_EMP_ACTIVITY_GOAL_RANGE", activityGoal);
              }
              resolve(activityGoal);
            } else {
              reject();
            }
          })
          .catch(() => {
            reject();
          });
      } catch {
        reject();
      }
    });
  },
};
