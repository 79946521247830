<template>
  <div id="app">
    <v-app>
      <router-view></router-view>
    </v-app>
  </div>
</template>
<script>
import { CUSTOM_COLOR_PICKER } from "@/graphql/commonQueries.js";
export default {
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.handleWindowResize);
    });
    this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);
    this.$store.dispatch("fetchUserIp");
    this.retriveCustomColorPicker();
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.handleWindowResize);
  },
  methods: {
    retriveCustomColorPicker() {
      if (!localStorage.getItem("brand_color")) {
        let vm = this;
        vm.isErrorInList = false;
        vm.$apollo
          .query({
            query: CUSTOM_COLOR_PICKER,
            client: "apolloClientAC",
            fetchPolicy: "no-cache",
          })
          .then((response) => {
            if (
              response.data &&
              response.data.customColorPicker &&
              response.data.customColorPicker.colorResult &&
              !response.data.customColorPicker.errorCode.length
            ) {
              let colour = response.data.customColorPicker.colorResult[0];
              localStorage.setItem("brand_color", JSON.stringify(colour));
            } else {
              vm.handleListError();
            }
          })
          .catch((err) => {
            vm.handleListError(err);
          });
      }
    },
    handleListError(err = "") {
      this.jobPostMemberLoading = false;
      this.$store
        .dispatch("handleApiErrors", {
          error: err,
          action: "retrieving",
          form: "custom color picker",
          isListError: true,
        })
        .then((errorMessages) => {
          this.errorContent = errorMessages;
          this.isErrorInList = true;
        });
    },
    handleWindowResize() {
      this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);
    },
  },
};
</script>
<style>
::-webkit-scrollbar {
  z-index: 30;
  width: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 0;
  box-shadow: none;
  border: 0;
}
::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}
::-webkit-scrollbar-track {
  border-radius: 0;
  box-shadow: none;
  border: 0;
}

::-webkit-scrollbar-track {
  background-color: #eaeaea;
}
</style>
