export default {
  // get the rating color classes based on the rating value
  ratingColors: (state) => (payload) => {
    // if the values is less than one set 1 as default
    let ratingIndex =
      parseInt(payload.value, 10) < 1 ? 1 : parseInt(payload.value, 10);
    if (state.selectedRatingScale === 5) {
      return {
        colorClass: "ratingScale" + Math.floor(ratingIndex),
        bgClass: "ratingScaleBg" + Math.floor(ratingIndex),
      };
    } else {
      let colorIndex = [];
      // added zero for array index adjustment
      colorIndex = [0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5];
      return {
        colorClass: "ratingScale" + colorIndex[Math.floor(ratingIndex)],
        bgClass: "ratingScaleBg" + colorIndex[Math.floor(ratingIndex)],
      };
    }
  },
};
