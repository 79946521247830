<template>
  <v-card
    class="card-radius"
    :min-height="windowWidth > 700 ? 350 : ''"
    width="550px"
    color="white"
    elevation="10"
    @click:outside="$emit('cancel-close')"
  >
    <div class="d-flex justify-end">
      <v-icon
        color="secondary"
        class="pr-4 pt-4 font-weight-bold"
        @click="$emit('cancel-close')"
        >close</v-icon
      >
    </div>
    <v-row class="set-layout">
      <v-col cols="12" class="d-flex flex-column justify-center align-center">
        <div class="mb-5 d-flex flex-column justify-center align-center">
          <img width="70%" height="auto" :src="exitImgUrl" alt="form exit" />
        </div>

        <span class="primary--text font-weight-bold mb-3 text-center"
          >Are you sure to exit this form?</span
        >
        <div class="mb-4">
          <v-row>
            <v-col xl="6" lg="6" md="6" sm="6" cols="6">
              <v-btn
                id="close_confirmation_no"
                light
                @click="$emit('cancel-close')"
              >
                No
              </v-btn>
            </v-col>
            <v-col xl="6" lg="6" md="6" sm="6" cols="6">
              <v-btn
                id="close_confirmation_yes"
                elevation="2"
                color="primary"
                @click="$emit('confirm-close')"
              >
                Yes
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "AppFormCloseConfirmation",

  props: {
    imgUrl: {
      type: String,
      default: "common/exit_form",
    },
  },

  computed: {
    isBrowserSupportWebp() {
      return this.$store.state.isWebpSupport;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    //Load webp if browser support webp image format or load png
    exitImgUrl() {
      if (this.isBrowserSupportWebp)
        return require("@/assets/images/" + this.imgUrl + ".webp");
      else return require("@/assets/images/" + this.imgUrl + ".png");
    },
  },
};
</script>
