<template>
  <div>
    <!-- no-record-bg - only need in income u/s 24. so this class is added in their income u/s24 parent component -->
    <v-row class="set-layout no-record-bg">
      <v-col cols="12" class="d-flex flex-column justify-center align-center">
        <div class="mb-5">
          <img width="100%" height="auto" :src="getImageUrl" alt="No Records" />
        </div>
        <div class="main-content-title secondary--text mb-5">
          {{ mainTitle }}
        </div>
        <span class="mb-5 sub-content">{{ content }}</span>
        <div v-if="addRights === 1" class="mb-4">
          <v-btn rounded color="primary" @click="$emit('button-click')">
            <v-icon class="add-icon"> add </v-icon>{{ buttonText }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "AppNoRecordScreen",
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    mainTitle: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      default: "",
    },
    imageName: {
      type: String,
      required: true,
    },
    addRights: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    //to know whether browser support webp or not
    isBrowserSupportWebp() {
      return this.$store.state.isWebpSupport;
    },
    getImageUrl() {
      if (this.isBrowserSupportWebp)
        return require("@/assets/images/" + this.imageName + ".webp");
      else return require("@/assets/images/" + this.imageName + ".png");
    },
  },
};
</script>

<style lang="scss" scoped>
.add-icon {
  font-weight: bold !important;
  font-size: 1.8em !important;
}
.set-layout {
  margin-top: 5%;
}
.main-content-title {
  font-weight: bold;
  font-size: 2em;
}
.sub-content {
  font-size: 1.5em;
  width: 50%;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .sub-content {
    font-size: 1em;
    width: 100%;
    text-align: center;
  }
}
</style>
