import Vue from "vue";
import Vuex from "vuex";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

//import modules
import moduleDashboard from "./dashboard/moduleDashboard.js";
import moduleEmployeeMonitoring from "./employee-monitoring/moduleEmployeeMonitoring";
import moduleSettings from "./settings/moduleSettings";
import moduleRosterManagement from "./roster-management/moduleRosterManagement";
import modulePayroll from "./payroll/modulePayroll";
import moduleEmployees from "./employees/moduleEmployees";
import moduleBenefits from "./benefits/moduleBenefits";
import moduleBilling from "./billing/moduleBilling";
import CoreHR from "./core-hr/moduleCoreHR";

Vue.use(Vuex);

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    dashboard: moduleDashboard,
    employeeMonitoring: moduleEmployeeMonitoring,
    settings: moduleSettings,
    rosterManagement: moduleRosterManagement,
    employees: moduleEmployees,
    payroll: modulePayroll,
    benefits: moduleBenefits,
    billing: moduleBilling,
    coreHR: CoreHR,
  },
  strict: process.env.NODE_ENV !== "production",
});
