<template>
  <div
    class="app-toggle-button"
    :style="inActiveStyles"
    @click.stop="
      {
      }
    "
  >
    <div class="toggle-button-area">
      <div
        v-tooltip="{
          content: tooltipContent,
          trigger: 'hover',
          placement: 'top',
        }"
        class="main-button"
        @click="changeText()"
      >
        <input
          :id="idValue"
          v-model="chosenValue"
          type="checkbox"
          class="checkbox"
          :class="isDisableToggle ? 'disable-toggle-btn' : ''"
          :disabled="isDisableToggle"
        />
        <div
          id="activeInactiveId"
          class="text"
          :activeText="buttonActiveText"
          :inactiveText="buttonInactiveText"
        ></div>
        <label class="checkbox-label" :for="idValue">
          <span class="active-button-text">{{ buttonInactiveText }}</span>
          <span class="inactive-button-text">{{ buttonActiveText }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppToggleButton",

  props: {
    buttonActiveText: {
      type: String,
      default: "Active",
    },
    buttonInactiveText: {
      type: String,
      default: "Inactive",
    },
    buttonActiveColor: {
      type: String,
      default: "green",
    },
    buttonInactiveColor: {
      type: String,
      default: "red",
    },
    idValue: {
      type: [String, Number],
      default: "",
    },
    currentValue: {
      type: Boolean,
      required: true,
    },
    isDisableToggle: {
      type: Boolean,
      default: false,
    },
    tooltipContent: {
      type: String,
      default: "",
    },
    resetValue: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    chosenValue: "",
  }),

  computed: {
    inActiveStyles() {
      return {
        "--toggle-text-color": this.isDisableToggle ? "#b4b4b4" : "#928b8b",
        "--active-bg-color": this.buttonActiveColor,
        "--inactive-bg-color": this.buttonInactiveColor,
      };
    },
  },

  watch: {
    currentValue: {
      immediate: true,
      handler(val) {
        this.chosenValue = val;
      },
    },
    // reset chosen value when the resetValue props is true
    resetValue(val) {
      if (val) {
        this.chosenValue = this.currentValue;
      }
    },
  },

  methods: {
    changeText() {
      setTimeout(() => {
        this.$emit("chosen-value", [this.idValue, this.chosenValue]);
      }, 600);
    },
  },
};
</script>

<style lang="css">
:root {
  --active-bg-color: green;
  --inactive-bg-color: red;
}

.disable-toggle-btn {
  cursor: not-allowed !important;
}

.checkbox-label {
  display: flex;
  justify-content: space-around;
  height: 100%;
  align-items: center;
}

.inactive-button-text {
  padding-left: 15px;
  font-weight: 600;
  color: var(--toggle-text-color);
}

.active-button-text {
  margin-right: 5px;
  font-weight: 600;
  color: var(--toggle-text-color);
}
.toggle-button-area {
  height: 40px;
  position: relative;
}

.toggle-button-area .main-button {
  top: 0;
  left: 0;
  width: 160px;
  height: 100%;
  background-color: #f5f5f5;
  border-radius: 110px;
}

.toggle-button-area .checkbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.toggle-button-area .text::after {
  position: absolute;
  content: attr(inactiveText);
  top: 4.5px;
  left: 3px;
  width: 80px;
  height: 30px;
  background: var(--inactive-bg-color);
  text-align: center;
  line-height: 35px;
  border-radius: 30px;
  transition: 0.4s;
  color: #fff;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggle-button-area .checkbox:checked + .text::after {
  content: attr(activeText);
  left: 75px;
  right: 0px;
  background-color: var(--active-bg-color);
}
</style>
