<template>
  <div class="custom-loading-cls">
    <div class="loader">
      <div class="loader--dot"></div>
      <div class="loader--dot"></div>
      <div class="loader--dot"></div>
      <div class="loader--dot"></div>
      <div class="loader--dot"></div>
      <div class="loader--dot"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppLoading",
};
</script>

<style scoped>
.custom-loading-cls {
  background: white;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  opacity: 0.8;
}
</style>
