import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { createProvider } from "./apolloConfiguration";
import * as inputValidators from "./inputValidators";
import L from "leaflet";
/* eslint-disable-next-line */
window.$ = window.jQuery = require("jquery");
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

// configure axios globally
//$http hold axios object so can access axios using $http inside app
import Axios from "axios";
Vue.prototype.$http = Axios;

// Globally Registered Base Components
import "./components/globalComponent.js";

//register perfect scrollbar globally
import VuePerfectScrollbar from "vue-perfect-scrollbar";
Vue.component("VuePerfectScrollbar", VuePerfectScrollbar);

import VueCookies from "vue-cookies";
Vue.use(VueCookies);

Vue.$cookies.config("", "", "", true);

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("Apexchart", VueApexCharts);

import { VTooltip, VPopover, VClosePopover } from "v-tooltip";
Vue.directive("tooltip", VTooltip);
Vue.directive("close-popover", VClosePopover);
Vue.component("VPopover", VPopover);

import "./filters/filters";

// import vee-validate components - input validation
import { ValidationObserver, ValidationProvider } from "vee-validate";
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

import Clipboard from "v-clipboard";
Vue.use(Clipboard);
// Firebase
import "@/firebase/firebaseConfig";

//analysis performance in devtool
Vue.config.performance = true;
Vue.config.productionTip = false;

new Vue({
  inputValidators,
  router,
  store,
  vuetify,
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount("#app");
