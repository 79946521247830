export default {
  leavesLists: [],
  shortTimeOffLists: [],
  compOffLists: [],
  isGeoEnforced: false,
  workPlaceData: {
    isWorkPlaceEnabled: false,
    workPlaceDetails: [],
  },
  employeesActionsList: {},
  payrollActionsList: {},
  employeeLeaveUtilization: {},

  missedCheckOutAttendance: [],
  missedNoAttendance: [],
  regularizationRequestLimitDetails: [],
  isNoAttendanceLoading: false,
  isMissedAttendanceLoading: false,

  reminders: {
    //as we get null for empty scenario we initialize as null.
    //Handled null in company update component
    birthday: null,
    workAnniversary: null,
    awards: null,
  },

  needToUpdateAttendance: false,
  isCurrentRecordAvailable: false, //Will use it in next task

  watchPositionId: "",

  // ====================
  // Admin Dashboard Data
  // ====================

  //my team updates data in admin dashboard
  myTeamUpdates: {
    probation: null,
    newJoining: null,
    offboarding: null,
  },

  //employees leave count for the current day based on different leave type
  leaveDistributionData: {
    leaveDistribution: [],
    totalLeaveCount: 0,
  },

  //team availability data
  teamAvailability: [],
};
