import Vue from "vue";
import { createProvider } from "../../apolloConfiguration";
import { LIST_MY_UTILIZATION } from "@/graphql/dashboard/dashboardQueries";
export default {
  //action to fetch no attendance data
  fetchNoAttendanceRecord({ rootGetters, dispatch, commit, rootState }) {
    commit("UPDATE_NO_ATTENDANCE_LOADING", true);
    let empId = parseInt(rootState.orgDetails.employeeId);
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(
          rootGetters.baseUrl +
            "employees/attendance-finalization/list-attendance-finalization",
          {
            finalizationMethod: "dashboardNoAttendance", //either dashboardAttendance or dashboardNoAttendance
            startDate: "",
            endDate: "",
            employeeId: empId ? [empId] : "",
            status: [],
            employeeType: "",
            location: "",
            department: "",
          }
        )
        .then((result) => {
          commit("UPDATE_NO_ATTENDANCE_LOADING", false);
          let noAttendanceData = result.data;
          if (noAttendanceData && noAttendanceData.success) {
            let missedAttendanceList = noAttendanceData.noAttendanceData.aaData;
            let regularizationRequestLimitDetails =
              noAttendanceData.regularizationRequestLimitDetails;
            commit("UPDATE_MISSED_NO_ATTENDANCE", missedAttendanceList);
            commit(
              "UPDATE_REGULARIZATION_LIMIT_DETAILS",
              regularizationRequestLimitDetails
            );
            resolve(noAttendanceData);
          } else {
            //any error other than session while data retrieve we don't present error to the users
            //so handles only session expired error
            if (noAttendanceData.msg === "Session Expired") {
              dispatch("clearUserLock", null, { root: true });
            }
            reject(noAttendanceData.msg);
          }
        })
        .catch((noAttendanceError) => {
          commit("UPDATE_NO_ATTENDANCE_LOADING", false);

          //on session expire error we clear session and redirect to auth
          if (noAttendanceError.status === 200) {
            dispatch("clearUserLock", null, { root: true });
          }
          reject(noAttendanceError);
        });
    });
  },

  //action to fetch missed check out attendance data
  fetchMissedCheckOutAttendance({ rootGetters, dispatch, commit, rootState }) {
    commit("UPDATE_MISSED_ATTENDANCE_LOADING", true);

    let empId = parseInt(rootState.orgDetails.employeeId);
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(
          rootGetters.baseUrl +
            "employees/attendance-finalization/list-attendance-finalization",
          {
            finalizationMethod: "dashboardAttendance", //either dashboardAttendance or dashboardNoAttendance
            startDate: "",
            endDate: "",
            employeeId: empId ? [empId] : "",
            status: "Draft",
            employeeType: "",
            location: "",
            department: "",
          }
        )
        .then((result) => {
          commit("UPDATE_MISSED_ATTENDANCE_LOADING", false);

          let attendanceData = result.data;
          if (attendanceData && attendanceData.success) {
            let missedAttendanceList = attendanceData.attendanceData.aaData;
            let regularizationRequestLimitDetails =
              attendanceData.regularizationRequestLimitDetails;
            commit("UPDATE_MISSED_CHECKOUT_ATTENDANCE", missedAttendanceList);
            commit(
              "UPDATE_REGULARIZATION_LIMIT_DETAILS",
              regularizationRequestLimitDetails
            );
            resolve(attendanceData);
          } else {
            //any error other than session while data retrieve we don't present error to the users
            //so handles only session expired error
            if (attendanceData.msg === "Session Expired") {
              dispatch("clearUserLock", null, { root: true });
            }
            reject(attendanceData.msg);
          }
        })
        .catch((noAttendanceError) => {
          commit("UPDATE_MISSED_ATTENDANCE_LOADING", false);

          //on session expire error we clear session and redirect to auth
          if (noAttendanceError.status === 200) {
            dispatch("clearUserLock", null, { root: true });
          }
          reject(noAttendanceError);
        });
    });
  },

  /** navigator.geolocation.watchPosition Returns the current position of the user and
   *  continues to return updated position as the user moves (like the GPS in a car).
   * So once we initialized it will continue tracking the position until we clear it.
   * Watch initialization used to performs the check-in/checkout in dashboard or in attendance regularization*/
  initializeGeolocationWatch({ commit }) {
    let watchGeoPositionId = navigator.geolocation.watchPosition(() => {});
    commit("UPDATE_WATCH_POSITION", watchGeoPositionId);
  },

  //action to get the geo coordinates using watchPosition
  fetchUserCoordinates({ commit }) {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        let geoPositionId = navigator.geolocation.watchPosition(
          function (position) {
            let geoPosition = position.coords;
            commit("UPDATE_WATCH_POSITION", geoPositionId);
            resolve([geoPosition, geoPositionId]); //on success return geo-coordinates and watchPositionId
          },
          function error() {
            commit("UPDATE_WATCH_POSITION", geoPositionId);
            reject(["geo_error", geoPositionId]); //on error return geo_error and watchPositionId
          },
          {
            enableHighAccuracy: true, //option to enable geo position accuracy
            timeout: 5000, // set 5 sec timeout to get geo-coordinates values
            maximumAge: 1000, //set 1 sec time for maximum age. This is to tell how long it can use the last geo-coordinates.
          }
        );
      } else {
        //on geolocation not supported in browser we present the following message in snackbar
        let snackbarData = {
          isOpen: true,
          message: "Geolocation is not supported by this browser.",
          type: "warning",
        };
        commit("OPEN_SNACKBAR", snackbarData, { root: true });
        reject("geo_not_support");
      }
    });
  },

  //clear the watch position id after getting the coordinates.
  //need to clear existing watch position id before initializing another watch position
  clearGeoWatchPosition({ commit }, geoPositionId) {
    navigator.geolocation.clearWatch(geoPositionId);
    commit("UPDATE_WATCH_POSITION", "");
  },

  //action to get utilization details
  fetchUtilization({ commit }) {
    const {
      clients: { apolloClientC },
    } = createProvider();
    return new Promise((resolve, reject) => {
      apolloClientC
        .query({
          query: LIST_MY_UTILIZATION,
        })
        .then((response) => {
          if (response.data) {
            const {
              getUtilization: { errorCode, utilization },
            } = response.data;
            if (!errorCode && utilization) {
              commit(
                "UPDATE_LEAVE_UTILIZATION",
                utilization ? JSON.parse(utilization) : {}
              );
              resolve("success");
            } else {
              reject(errorCode);
            }
          } else {
            reject("error");
          }
        })
        .catch((utilizationError) => {
          reject(utilizationError);
        });
    });
  },
};
