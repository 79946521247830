import state from "./moduleSettingsState.js";
import mutations from "./moduleSettingsMutations.js";
import actions from "./moduleSettingsActions";
import getters from "./moduleSettingsGetters";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};
