const prod_baseurl = "https://api.hrapp.co/";
const stag_baseurl = "https://api.hrapp.co.in/";
const onboarding_stag_baseurl = "https://onboardapi.hrapp.co.in/";
const onboarding_prod_baseurl = "https://onboardapi.hrapp.co/";
const production = {
  domain: "hrapp.co",
  productlogo: 0,
  production: 1,
  TZDate: "m/d/Y",
  graphql_endpoint: {
    stepFunction: prod_baseurl + "rographql",
    ats: prod_baseurl + "ats/graphql",
    atsSignIn: prod_baseurl + "ats/signinGraphql",
    settings: prod_baseurl + "hrappBe/settingsgraphql",
    hrappBE: prod_baseurl + "hrappBe/graphql",
    employeeMonitoring: prod_baseurl + "employeeMonitoring/graphql",
    empMonitorBatchRead: prod_baseurl + "employeeMonitoringbatch/roGraphql",
    empMonitorRead: prod_baseurl + "employeeMonitoring/roGraphql",
    payMaster: prod_baseurl + "paymaster/graphql",
    billing: prod_baseurl + "billing/billinggraphql",
    coreHrRead: prod_baseurl + "coreHr/rographql",
    coreHrWrite: prod_baseurl + "coreHr/wographql ",
    coreHrNoAuth: prod_baseurl + "coreHr/noauthrographql",
    docusignRead: prod_baseurl + "docusign/rographql",
    docusignWrite: prod_baseurl + "docusign/wographql ",
    docusignNoAuthRead: prod_baseurl + "docusign/noauthrographql",
    docusignNoAuthWrite: prod_baseurl + "docusign/noauthwographql",
    hrappBERead: prod_baseurl + "hrappBe/roGraphql",
    hrappBEWrite: prod_baseurl + "hrappBe/woGraphql",
    empMonitorWrite: prod_baseurl + "employeeMonitoring/woGraphql",
    attendanceRead: prod_baseurl + "facialAttendance/rographql",
    attendanceWrite: prod_baseurl + "facialAttendance/wographql",
    onboardingRead: onboarding_prod_baseurl + "rographql",
    onboardingWrite: onboarding_prod_baseurl + "wographql",
    onboardingReadWrite: onboarding_prod_baseurl + "graphql",
    integrationRead: prod_baseurl + "integration/rographql",
    taxAndStatutoryRead: prod_baseurl + "taxAndStatutory/rographql",
    atsExternal: prod_baseurl + "ats/external",
    coreHrExternal: prod_baseurl + "coreHr/external",
    orgDataWrite: prod_baseurl + "orgDataManagement/wographql",
    orgDataRead: prod_baseurl + "orgDataManagement/rographql",
  },
  workflowUrl: prod_baseurl + "workflowEngine",
  firebase_credentials: {
    apiKey: "AIzaSyAupi9_2ATYi05M7hfgO3pZFqF1dNGK7tk",
    authDomain: "hrappidentity.firebaseapp.com",
    databaseURL: "https://hrappidentity.firebaseio.com",
    projectId: "hrappidentity",
    storageBucket: "hrappidentity.appspot.com",
    messagingSenderId: "887685568909",
  },
  // need to change prod config here
  tl_firebase_credentials: {
    apiKey: "AIzaSyCUfF3bLGc3yU_qBK91Tx72_3HaWf3cTYc",
    authDomain: "hrapptruleadidentity.firebaseapp.com",
    databaseURL:
      "https://hrapptruleadidentity-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "hrapptruleadidentity",
    storageBucket: "hrapptruleadidentity.appspot.com",
    messagingSenderId: "265861830197",
  },
  ipAddressApi: "https://api.ipify.org/?format=json",
  publicImageS3Path:
    "https://s3.ap-south-1.amazonaws.com/s3.hrapp-dev-public-images/",
  googleMapsAPIKey: "AIzaSyA8X1UihjoJuukpRgUr4QFy-R6PrnTiXyw",
  empMonitoring: {
    brand: "FlowTrack",
    website: "https://hrapp.in/employee-monitoring-software/",
    dataLossPreventionWebsite: "https://www.flowtrack.ai/data-loss-prevention/",
    productVideo: "https://youtu.be/4O3TsjTa7WA",
    stealth: {
      singleMemberOnboardVideo:
        "https://www.youtube.com/watch?v=EgG-Aw0tVWs&list=PLBGsKHuHQdH1xYT83J2xrTIzO5kbCnQOV&index=2",
      bulkImportVideo:
        "https://www.youtube.com/watch?v=TIEm-0uJcUA&list=PLBGsKHuHQdH1xYT83J2xrTIzO5kbCnQOV&index=2",
      bulkManualVideo:
        "https://www.youtube.com/watch?v=69eBl3qRnyU&list=PLBGsKHuHQdH1xYT83J2xrTIzO5kbCnQOV&index=3",
      windowsInstallerVideo:
        "https://www.youtube.com/watch?v=On-dgIGUCqA&list=PLBGsKHuHQdH1QfDZEq83mgVNjdiWtbT6O&index=1",
    },
    consent: {
      bulkImportVideo:
        "https://www.youtube.com/watch?v=7Qg547Ln5vQ&list=PLBGsKHuHQdH2ySgaoBG8O2N9REuMJTmyw&index=2",
      bulkManualVideo:
        "https://www.youtube.com/watch?v=HpHiKS0TzE0&list=PLBGsKHuHQdH2ySgaoBG8O2N9REuMJTmyw&index=2",
      windowsInstallerVideo:
        "https://www.youtube.com/watch?v=kKT2jx36iek&list=PLBGsKHuHQdH0XSgMgxGh_rbj6HLkWejbz&index=1",
    },
    macInstallerVideo: "https://youtu.be/nefJwy8QqrE",
    linuxInstallerVideo:
      "https://www.youtube.com/watch?v=MQkqA3SC7LU&list=PLBGsKHuHQdH0XSgMgxGh_rbj6HLkWejbz&index=2",
  },
  demoBookingLink: "https://meetings.hubspot.com/chandra18",
  pmsIntroVideo: "https://youtu.be/BGyE5I1C7Us",
  termsAndConditionUrl: "",
};
const staging = {
  domain: "hrapp.co.in",
  productlogo: 1,
  production: 1,
  TZDate: "m/d/Y",
  graphql_endpoint: {
    stepFunction: stag_baseurl + "rographql",
    ats: stag_baseurl + "ats/graphql",
    atsSignIn: stag_baseurl + "ats/signinGraphql",
    settings: stag_baseurl + "hrappBe/settingsgraphql",
    hrappBE: stag_baseurl + "hrappBe/graphql",
    employeeMonitoring: stag_baseurl + "employeeMonitoring/graphql",
    empMonitorRead: stag_baseurl + "employeeMonitoring/roGraphql",
    empMonitorBatchRead: stag_baseurl + "employeeMonitoringbatch/roGraphql",
    payMaster: stag_baseurl + "paymaster/graphql",
    billing: stag_baseurl + "billing/billinggraphql",
    coreHrRead: stag_baseurl + "coreHr/rographql",
    coreHrWrite: stag_baseurl + "coreHr/wographql",
    coreHrNoAuth: stag_baseurl + "coreHr/noauthrographql",
    docusignRead: stag_baseurl + "docusign/rographql",
    docusignWrite: stag_baseurl + "docusign/wographql ",
    docusignNoAuthRead: stag_baseurl + "docusign/noauthrographql",
    docusignNoAuthWrite: stag_baseurl + "docusign/noauthwographql",
    hrappBERead: stag_baseurl + "hrappBe/roGraphql",
    hrappBEWrite: stag_baseurl + "hrappBe/woGraphql",
    empMonitorWrite: stag_baseurl + "employeeMonitoring/woGraphql",
    attendanceRead: stag_baseurl + "facialAttendance/rographql",
    attendanceWrite: stag_baseurl + "facialAttendance/wographql",
    onboardingRead: onboarding_stag_baseurl + "rographql",
    onboardingWrite: onboarding_stag_baseurl + "wographql",
    onboardingReadWrite: onboarding_stag_baseurl + "graphql",
    integrationRead: stag_baseurl + "integration/rographql",
    taxAndStatutoryRead: stag_baseurl + "taxAndStatutory/rographql",
    atsExternal: stag_baseurl + "ats/external",
    coreHrExternal: stag_baseurl + "coreHr/external",
    orgDataWrite: stag_baseurl + "orgDataManagement/wographql",
    orgDataRead: stag_baseurl + "orgDataManagement/rographql",
  },
  workflowUrl: stag_baseurl + "workflowEngine",
  firebase_credentials: {
    apiKey: "AIzaSyB-QCDxis2HG3hHIreLPiidSlN_eCyi3m8",
    authDomain: "hrappsample.firebaseapp.com",
    databaseURL: "https://hrappsample.firebaseio.com",
    projectId: "hrappsample",
    storageBucket: "hrappsample.appspot.com",
    messagingSenderId: "514098503657",
  },
  tl_firebase_credentials: {
    apiKey: "AIzaSyDqZn6gno9dipDFBl4RSRBgCVX0-rb3BaU",
    authDomain: "hrapptrulead.firebaseapp.com",
    databaseURL: "https://hrapptrulead-default-rtdb.firebaseio.com",
    projectId: "hrapptrulead",
    storageBucket: "hrapptrulead.appspot.com",
    messagingSenderId: "805458700336",
  },
  ipAddressApi: "https://api.ipify.org/?format=json",
  publicImageS3Path:
    "https://s3.ap-south-1.amazonaws.com/s3.hrapp-dev-public-images/",
  googleMapsAPIKey: "AIzaSyA8X1UihjoJuukpRgUr4QFy-R6PrnTiXyw",
  empMonitoring: {
    brand: "FlowTrack",
    website: "https://hrapp.in/employee-monitoring-software/",
    dataLossPreventionWebsite: "https://www.flowtrack.ai/data-loss-prevention/",
    productVideo: "https://youtu.be/4O3TsjTa7WA",
    stealth: {
      singleMemberOnboardVideo:
        "https://www.youtube.com/watch?v=EgG-Aw0tVWs&list=PLBGsKHuHQdH1xYT83J2xrTIzO5kbCnQOV&index=2",
      bulkImportVideo:
        "https://www.youtube.com/watch?v=TIEm-0uJcUA&list=PLBGsKHuHQdH1xYT83J2xrTIzO5kbCnQOV&index=2",
      bulkManualVideo:
        "https://www.youtube.com/watch?v=69eBl3qRnyU&list=PLBGsKHuHQdH1xYT83J2xrTIzO5kbCnQOV&index=3",
      windowsInstallerVideo:
        "https://www.youtube.com/watch?v=On-dgIGUCqA&list=PLBGsKHuHQdH1QfDZEq83mgVNjdiWtbT6O&index=1",
    },
    consent: {
      bulkImportVideo:
        "https://www.youtube.com/watch?v=7Qg547Ln5vQ&list=PLBGsKHuHQdH2ySgaoBG8O2N9REuMJTmyw&index=2",
      bulkManualVideo:
        "https://www.youtube.com/watch?v=HpHiKS0TzE0&list=PLBGsKHuHQdH2ySgaoBG8O2N9REuMJTmyw&index=2",
      windowsInstallerVideo:
        "https://www.youtube.com/watch?v=kKT2jx36iek&list=PLBGsKHuHQdH0XSgMgxGh_rbj6HLkWejbz&index=1",
    },
    macInstallerVideo: "https://youtu.be/nefJwy8QqrE",
    linuxInstallerVideo:
      "https://www.youtube.com/watch?v=MQkqA3SC7LU&list=PLBGsKHuHQdH0XSgMgxGh_rbj6HLkWejbz&index=2",
  },
  demoBookingLink: "https://meetings.hubspot.com/chandra18",
  pmsIntroVideo: "https://youtu.be/BGyE5I1C7Us",
  termsAndConditionUrl: "",
};

const configuration =
  process.env.NODE_ENV === "production" ? production : staging;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...configuration,
};
