export default {
  //shift scheduling
  shiftTypeList: [],
  activeShiftTypeList: [],
  paycycleDates: [],
  currentSelectedTab: "",
  shiftSearch: "",
  shiftFilterVal: {
    workSchedule: "",
    minEmpCount: null,
    maxEmpCount: null,
    shiftType: [],
    shiftDate: [],
  },
  dynamicWeekOff: false,
  bulkImportSelectedOption: null,
  currentPayrollMonth: "",
  selectedFilterMonthYear: "",
};
