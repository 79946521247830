export default {
  // salary template mutation
  // ========================

  //update entire salary template list
  UPDATE_SALARY_TEMPLATE_LIST(state, list) {
    state.salaryTemplateList = list;
  },
  //update filtered template list in UI
  UPDATE_FILTERED_TEMPLATE_LIST(state, list) {
    state.filteredTemplateList = list;
  },
  //update retirals components to present in filters
  UPDATE_RETIRALS_COMPONENTS_LIST(state, list) {
    state.retiralsComponents = list;
  },
  //Save the template currency symbol to present it in other forms like filter
  UPDATE_TEMPLATE_SYMBOL(state, symbol) {
    state.templateCurrencySymbol = symbol;
  },
  //update the round off settings details
  UPDATE_ROUND_OFF_SETTINGS(state, settings) {
    state.roundOffValue = settings;
  },
  //update template refetch value to fetch template after update api calls
  UPDATE_TEMPLATE_REFETCH(state, refetch) {
    state.isTemplateRefetchRequire = refetch;
  },
  // update comp off enabled value(fetched from overtime claim settings)
  UPDATE_COMPOFF_ENABLED_FLAG(state, value) {
    state.isCompOffEnabled = value;
  },
  // update shift allowance off enabled value(fetched from overtime claim settings)
  UPDATE_SHIFT_ALLOWANCE_BALANCE(state, value) {
    state.isShiftAllowanceEnabled = value;
  },
};
