import gql from "graphql-tag";
// ===============
// Queries
// ===============
export const RETRIEVE_ROUND_OFF_SETTINGS = gql`
  query {
    viewPayrollSettings {
      errorCode
      message
      payrollSettings
    }
  }
`;

// ===============
// Mutation
// ===============
export const UPDATE_ROUND_OFF_SETTINGS = gql`
  mutation updatePayrollSettings($settings: [updatePayrollSettingsInput!]!) {
    updatePayrollSettings(settings: $settings) {
      errorCode
      message
      validationError
    }
  }
`;
