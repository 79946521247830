import gql from "graphql-tag";
// ===============
// Queries
// ===============
export const LIST_SALARY_TEMPLATE = gql`
  query listSalaryTemplateDetails($formName: String!, $templateId: Int) {
    listSalaryTemplateDetails(formName: $formName, templateId: $templateId) {
      errorCode
      message
      currencySymbol
      templateDetails
    }
  }
`;
export const RETIRALS_COMPONENTS = gql`
  query getRetiralComponents {
    getRetiralComponents {
      errorCode
      message
      retirals {
        formId
        formName
      }
    }
  }
`;
export const LIST_SALARY_COMPONENTS = gql`
  query listSalaryComponents {
    listSalaryComponents {
      errorCode
      message
      salaryComponents
    }
  }
`;

// ===============
// Mutation
// ===============
export const DELETE_TEMPLATE = gql`
  mutation deleteSalaryTemplate($templateId: Int!) {
    deleteSalaryTemplate(templateId: $templateId) {
      errorCode
      message
    }
  }
`;

export const UPDATE_TEMPLATE_STATUS = gql`
  mutation updateTemplateStatus($templateId: Int!, $templateStatus: String!) {
    updateTemplateStatus(
      templateId: $templateId
      templateStatus: $templateStatus
    ) {
      errorCode
      message
    }
  }
`;
