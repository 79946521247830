<template>
  <v-card
    :class="classStyles"
    class="rounded-lg pa-1 common-box-shadow d-flex align-center"
    height="90"
    @click="isClickableCard ? clickCard() : {}"
  >
    <div class="d-flex align-center">
      <v-avatar v-if="iconName" class="common-box-shadow" size="40">
        <v-icon
          :style="iconStyles"
          class="font-weight-bold"
          :color="avatarColor"
        >
          {{ iconName }}
        </v-icon>
      </v-avatar>
      <v-avatar
        v-else-if="imageName"
        class="text-xs-center justify-center common-box-shadow"
        :size="windowWidth <= 1400 && windowWidth >= 1264 ? '30' : '40'"
      >
        <img
          :src="getAvatarImageUrl"
          alt="avatar"
          style="width: 30px; height: auto"
        />
      </v-avatar>
      <div class="ml-2 primary--text" style="font-size: 13px">
        <section v-if="!loading">
          <div class="font-weight-bold">{{ viewData1 }}</div>
          <v-row v-if="showButton"><slot name="actionButton"></slot></v-row>
        </section>
        <v-skeleton-loader
          v-else
          min-width="80%"
          min-height="10"
          type="text"
        ></v-skeleton-loader>
        <div v-if="viewDataTxt1" class="caption">{{ viewDataTxt1 }}</div>
      </div>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "AppStatisticsCard",
  props: {
    viewData1: {
      type: [String, Number],
      default: "",
    },
    viewDataTxt1: {
      type: String,
      default: "",
    },
    avatarColor: {
      type: String,
      default: "",
    },
    iconName: {
      type: String,
      default: "",
    },
    imageName: {
      type: String,
      default: "",
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    iconOutlineColor: {
      type: String,
      default: "transparent",
    },
    isClickableCard: {
      type: Boolean,
      default: false,
    },
    uniqueId: {
      type: Array,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    // current window size
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    iconStyles() {
      return {
        "box-shadow": `${this.iconOutlineColor}` + " 0px 1px 6px",
        "border-radius": "50px",
        padding: "10px",
      };
    },
    classStyles() {
      if (!this.isClickableCard) {
        return "statistics-pointer-none";
      } else {
        if (this.viewData1 === 0) {
          return "cursor-blocked";
        }
        return "";
      }
    },
    //to know whether browser support webp or not
    isBrowserSupportWebp() {
      return this.$store.state.isWebpSupport;
    },
    getAvatarImageUrl() {
      if (this.isBrowserSupportWebp)
        return require("@/assets/images/" + this.imageName + ".webp");
      else return require("@/assets/images/" + this.imageName + ".png");
    },
  },
  methods: {
    // if any unique items to pass to parent component, when card is clicked
    clickCard() {
      this.$emit("click-card", this.uniqueId);
    },
  },
};
</script>
<style lang="scss" scoped>
.statistics-pointer-none {
  cursor: auto !important;
}
.cursor-blocked {
  cursor: not-allowed !important;
}
</style>
