import state from "./moduleEmployeesState.js";
import mutations from "./moduleEmployeesMutations.js";
import actions from "./moduleEmployeesActions.js";
import getters from "./moduleEmployeesGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};
