export default {
  UPDATE_VALUE_OF_ONE_SHARE(state, value) {
    state.valueOfOneShare = value ? parseFloat(value) : 0;
  },
  UPDATE_CURRENCY_SYMBOL(state, value) {
    state.currencySymbol = value ? value : "";
  },
  UPDATE_ESOP_FORM_RIGHTS(state, value) {
    state.esopAccessRights = value ? value : {};
  },
};
