export default {
  memberCountStatistics: {
    // member and subscribed count statistics for auto billing
    activeMembers: 0,
    inactiveMembers: 0,
    invitedMembers: 0,
    purchasedMembers: 0,
    assignedMembers: 0,
    availableMembers: 0,
  },
  subscribedPlanDetails: {}, // details of subscription details
};
