import { createProvider } from "../../apolloConfiguration";
import {
  GET_MEMBERS_AND_SUBSCRIBED_COUNT,
  GET_SUBSCRIPTION_DETAILS,
  CHANGE_SUBSCRIPTION_HOSTED_PAGE_DETAILS,
} from "@/graphql/billing/billingQueries";
// import helper function
import {
  getErrorCodes,
  getErrorCodesWithValidation,
  handleNetworkErrors,
} from "@/helper";

export default {
  getMembersAndSubscribedCount({ commit }, payload) {
    const {
      clients: { apolloClientG },
    } = createProvider();
    return new Promise((resolve, reject) => {
      apolloClientG
        .query({
          query: GET_MEMBERS_AND_SUBSCRIBED_COUNT,
          variables: {
            source: payload.source,
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          const {
            errorCode,
            activeMembers,
            inactiveMembers,
            invitedMembers,
            subscribedQuantity,
          } = response.data.getMembersAndSubscribedCount;
          if (!errorCode) {
            // assigned members count will be calculated based on (active + invited(in pending status))members
            let assignedMembersCount =
                parseInt(activeMembers, 10) + parseInt(invitedMembers, 10),
              // available count will be calculated based on total subscribed quantity - assigned members
              availableMembersCount =
                parseInt(subscribedQuantity, 10) - assignedMembersCount;
            let membersAndSubscribedCount = {
              activeMembers: activeMembers,
              inactiveMembers: inactiveMembers,
              invitedMembers: invitedMembers,
              purchasedMembers: subscribedQuantity,
              assignedMembers: assignedMembersCount,
              availableMembers: availableMembersCount,
            };
            commit("UPDATE_AVAILABLE_MEMBER_COUNTS", membersAndSubscribedCount);
            resolve();
          } else {
            reject(
              "Something went wrong while fetching the members and subscribed count. Please try after some time."
            );
          }
        })
        .catch((fetchError) => {
          let errorMsg =
            "Something went wrong while fetching the members and subscribed count. Please try after some time.";
          if (fetchError && fetchError.graphQLErrors) {
            let errorCode = getErrorCodes(fetchError);
            if (errorCode) {
              switch (errorCode) {
                case "_DB0000": // Technical error
                  reject(
                    "It’s us! There seems to be some technical difficulties while fetching the members and subscribed count. Please try after some time."
                  );
                  break;
                case "_DB0109": // This employee does not have admin access.//Billing form/team member access error
                  reject(
                    "Sorry, you don't have access to view the members and subscribed count. Please contact your administrator."
                  );
                  break;
                case "BB0109": // Auto billing is not applicable.
                  reject(
                    "Sorry, you don't have access to view the members and subscribed count. Please contact your administrator."
                  );
                  this.$store.commit("UPDATE_AUTO_BILLING_FLAG", false);
                  break;
                case "BB0102": // Unable to retrieve the details or add or update the details in the Chargebee. Please try after some time.
                  reject(
                    "Something went wrong while fetching the members and subscribed count. Please try after some time."
                  );
                  break;
                case "_EC0001": // no record found
                  reject(
                    "Unable to fetch the member details and subscribed count."
                  );
                  break;
                case "_DB0001": // Error while retrieving the employee access rights
                case "_DB0002": // Error while checking the employee access rights
                case "_DB0104": // While check access rights form not found
                case "_UH0001": // Something went wrong! Please contact the system admin.
                case "BB0002": // Billing rate details not exist.
                case "BB0010": // Mandatory field(s) not exist in the billing rate details.
                case "BB0001": //  Chargebee credentials not exist.
                case "BB0103": // Unable to retrieve the details or add or update the details in the Chargebee due to the invalid setup.
                case "BB0116": //  Subscription details do not exist.//when the subscription JSON does not exist in the chargebee subscription response
                case "BB0117": //Error while processing the request to retrieve the subscription and the members count.
                default:
                  reject(errorMsg);
                  break;
              }
            } else {
              reject(errorMsg);
            }
          } else if (fetchError && fetchError.networkError) {
            reject(handleNetworkErrors(fetchError));
          } else {
            reject(errorMsg);
          }
        });
    });
  },

  retrieveSubscriptionDetails({ commit }) {
    const {
      clients: { apolloClientG },
    } = createProvider();
    return new Promise((resolve, reject) => {
      apolloClientG
        .query({
          query: GET_SUBSCRIPTION_DETAILS,
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          const {
            retrieveSubscriptionDetails: { errorCode, subscriptionDetails },
          } = response.data;
          if (!errorCode) {
            let subsDetails = subscriptionDetails
              ? JSON.parse(subscriptionDetails)
              : null;
            commit("UPDATE_SUBSCRIBED_PLAN_DETAILS", subsDetails);
            resolve(subscriptionDetails);
          } else {
            reject(
              "Something went wrong while fetching the subscription details. If you continue to see this problem please talk to your platform administrator."
            );
          }
        })
        .catch((fetchError) => {
          let errorMsg =
            "Something went wrong while fetching the subscription details. If you continue to see this problem please talk to your platform administrator.";
          if (fetchError && fetchError.graphQLErrors) {
            let errorCode = getErrorCodes(fetchError);
            if (errorCode) {
              switch (errorCode) {
                case "_DB0000": // db error
                  reject(
                    "It’s us! There seems to be some technical difficulties while fetching the subscription details. Please try after some time."
                  );
                  break;
                case "_DB0109": // This employee does not have admin access.
                  reject("accessDenied");
                  break;
                case "BB0109": // Auto billing is available only for the employee monitoring dashboard.
                  reject("accessDenied");
                  this.$store.commit("UPDATE_AUTO_BILLING_FLAG", false);
                  break;
                case "BB0102": // Unable to retrieve the details or add or update the details in the Chargebee. Please try after some time.
                  reject(
                    "Something went wrong while fetching the subscription details. Please try after some time."
                  );
                  break;
                case "_EC0001": // no record found
                  reject("Unable to fetch the subscription details.");
                  break;
                case "_UH0001": // unhandled error
                case "_DB0104": // While check access rights form not found
                case "_DB0001": // Error while retrieving the employee access rights
                case "_DB0002": // Error while checking the employee access rights
                case "BB0002": // Billing rate details not exist.
                case "BB0001": // Chargebee credentials not exist.
                case "BB0103": // Unable to retrieve the details or add or update the details in the Chargebee due to the invalid setup.
                case "BB0112": // Error while processing the request to retrieve the Chargebee subscription details.
                default:
                  reject(errorMsg);
                  break;
              }
            } else {
              reject(errorMsg);
            }
          } else if (fetchError && fetchError.networkError) {
            reject(handleNetworkErrors(fetchError));
          } else {
            reject(errorMsg);
          }
        });
    });
  },

  changeSubscription(state, payload) {
    const {
      clients: { apolloClientG },
    } = createProvider();
    return new Promise((resolve, reject) => {
      apolloClientG
        .query({
          query: CHANGE_SUBSCRIPTION_HOSTED_PAGE_DETAILS,
          variables: payload,
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          const {
            getChangeSubscriptionHostedPageDetails: {
              errorCode,
              hostedPageDetails,
            },
          } = response.data;
          if (!errorCode && hostedPageDetails) {
            resolve(JSON.parse(hostedPageDetails));
          } else {
            reject(
              "Something went wrong. If you continue to see this problem please talk to your platform administrator."
            );
          }
        })
        .catch((fetchError) => {
          let errorMsg =
            "Something went wrong. If you continue to see this problem please talk to your platform administrator.";
          if (fetchError && fetchError.graphQLErrors) {
            let errorCode = getErrorCodesWithValidation(fetchError);
            if (errorCode) {
              switch (errorCode[0]) {
                case "_DB0000": // db error
                  reject(
                    "It’s us! There seems to be some technical difficulties. Please try after some time."
                  );
                  break;
                case "_DB0109": // This employee does not have admin access.
                  reject("accessDenied");
                  break;
                case "BAD_USER_INPUT":
                case "IVE0000": // Invalid input request
                  var validationErrors = errorCode[1]; // validation error json
                  // array of validation error codes
                  var validationErrorArray = validationErrors
                    ? Object.keys(validationErrors)
                    : [];
                  // add all the backend validation error messages as single sentence to present it to the users
                  var validationMessages = "";
                  // check validation json exist with json keys length
                  if (validationErrorArray.length > 0) {
                    for (var i in validationErrorArray) {
                      // IVE0124:  Invalid plan employee count/Plan employee count should be a minimum of 10
                      if (validationErrorArray[i] === "IVE0124") {
                        validationMessages =
                          validationMessages +
                          " " +
                          validationErrors[validationErrorArray[i]];
                      }
                    }
                    // if validation messages are available, we have to present that error messages
                    if (validationMessages) {
                      reject(validationMessages);
                    } else {
                      // other error codes, but no need to present to users
                      // IVE0123 - Invalid plan id
                      // IVE0153 - Invalid subscription id.
                      // IVE0120 - Please provide valid input source.
                      reject(
                        "Something went wrong. If you continue to see this problem please talk to your platform administrator."
                      );
                    }
                  } else {
                    reject(
                      "Something went wrong. If you continue to see this problem please talk to your platform administrator."
                    );
                  }
                  break;
                case "BB0105": // Empty response is returned from Chargebee.
                case "BB0102": // Unable to retrieve the details or add or update the details in the Chargebee. Please try after some time.
                  reject("Something went wrong. Please try after some time.");
                  break;
                case "BB0107": // Unable to change the subscription as the number of users is lesser than the active, pending, and invited member(s).
                  reject(
                    "Unable to change the subscription as the number of licenses is lesser than the active, pending, and invited member(s)."
                  );
                  break;
                case "_UH0001": // unhandled error
                case "_DB0001": // Error while retrieving the employee access rights
                case "_DB0002": // Error while checking the employee access rights
                case "_DB0104": // While check access rights form not found
                case "BB0001": // chargebee credentials not exist
                case "BB0103": // Unable to retrieve the details or add or update the details in the Chargebee due to the invalid setup.
                case "BB0114": // Error while processing the request to get the change subscription-hosted page details.
                default:
                  reject(errorMsg);
                  break;
              }
            } else {
              reject(errorMsg);
            }
          } else if (fetchError && fetchError.networkError) {
            reject(handleNetworkErrors(fetchError));
          } else {
            reject(errorMsg);
          }
        });
    });
  },
};
