import state from "./modulePayrollState.js";
import mutations from "./modulePayrollMutations.js";
import actions from "./modulePayrollActions.js";
import getters from "./modulePayrollGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};
